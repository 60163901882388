<template>
  <div class="grid-container fluid eco-panel-container" data-equalizer data-equalizer-mq="large-up">

    <div class="grid-x ">
      <div class="medium-5">
        <select v-model="selectedOption" :disabled="!chartCalculated" @change="changeHandler">
          <!--option disabled value="">{{ $t('chart.option') }}</option-->
          <!--option v-for="(chart, index) in supportedCharts"
                  :key="index" v-bind:value="index"> {{ $t(chart) }}
          </option-->
           <optgroup class="eco-select-optgroup" :label="$t('chart.option-national-economy')">
             <option  v-bind:value="1"> {{ $t('chart.gdp-growth') }}</option>
             <option  v-bind:value="2"> {{ $t('chart.gdp-use') }}</option>
             <option  v-bind:value="3">{{ $t('chart.inflation') }}</option>
             <option  v-bind:value="4">{{ $t('chart.foreign-trade') }}</option>
           </optgroup>
           <optgroup class="eco-select-optgroup" :label="$t('chart.option-population-labour-market')">
             <option  v-bind:value="5">{{ $t('chart.unemployment') }}</option>
             <option  v-bind:value="6">{{ $t('chart.working-environment') }}</option>
             <option  v-bind:value="7">{{ $t('chart.employment-rate') }}</option>
             <option  v-bind:value="8">{{ $t('chart.population') }}</option>
           </optgroup>
           <optgroup class="eco-select-optgroup" :label=" $t('chart.option-income')">
             <option  v-bind:value="9">{{ $t('chart.wages') }}</option>
             <option  v-bind:value="10">{{ $t('chart.income-development') }}</option>
             <option  v-bind:value="11">{{ $t('chart.income-poverty') }}</option>
           </optgroup>
           <optgroup class="eco-select-optgroup" :label="$t('chart.option-households')">
             <option  v-bind:value="12">{{ $t('chart.finance-situation-household') }}</option>
           </optgroup>
           <optgroup class="eco-select-optgroup" :label="$t('chart.option-economy')">
             <option  v-bind:value="13">{{ $t('chart.corporate-success') }}</option>
             <option  v-bind:value="14">{{ $t('chart.stock') }}</option>
             <option  v-bind:value="15">{{ $t('chart.finance-situation-economy') }}</option>
           </optgroup>
           <optgroup class="eco-select-optgroup" :label="$t('chart.option-government')">
             <option  v-bind:value="16">{{ $t('chart.budget') }}</option>
             <option  v-bind:value="17">{{ $t('chart.government-account') }}</option>
             <option  v-bind:value="18">{{ $t('chart.government-tax-rates') }}</option>
             <option  v-bind:value="19">{{ $t('chart.government-spending-rate') }}</option>
             <option  v-bind:value="20">{{ $t('chart.finance-situation-government') }}</option>
           </optgroup>
           <optgroup class="eco-select-optgroup" :label="$t('chart.option-prosperity')">
            <option  v-bind:value="21">{{ $t('chart.prosperity') }}</option>
           </optgroup>
     </select>
      </div>

      <div class="medium-12 columns eco-chart-panel" data-equalizer-watch>
        <div cell class="medium-12">
          <div class="eco-grid-container">
            <div ref="chart" class="eco-chart"></div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Plotly from "plotly.js-dist"

export default {
  name: "GameChart",
  data() {
    return {
      selectedOption: 0,
      supportedCharts:
          [
            'chart.no-option',
            'chart.gdp-growth',
            'chart.gdp-use',
            'chart.inflation',
            'chart.foreign-trade',
            'chart.unemployment',
            'chart.working-environment',
            'chart.employment-rate',
            'chart.population',
            'chart.wages',
            'chart.income-development',
            'chart.income-poverty',
            'chart.finance-situation-household',
            'chart.corporate-success',
            'chart.stock',
            'chart.finance-situation-economy',
            'chart.budget',
            'chart.government-account',
            'chart.government-tax-rates',
            'chart.government-spending-rate',
            'chart.finance-situation-government',
            'chart.prosperity'
          ],
      itemChart: {},
      chartCalculated: false,
      legend: {
        orientation: 'h',
        xanchor: 'right',
        x: 1,
        y: 1.1,
      },
      margin: {l: 80, r: 80, t: 20, b: 60},
      font: {size: 16},
    };
  },
  methods: {
    fillAxis() {
      var xAxis = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10'];
      if( this.round > 10) {
        xAxis = [];
        var i = this.round - 9, length = this.round+1;
        for (i; i < length; i++) {
          xAxis.push('P' + i);
        }
      }
      return xAxis;
    },
    fillData(trace) {
      var list = [...trace];
      if (this.round > 10) {
        list.splice(0, this.round - 10);
      }
      return list;
    },
    calculateRange(trace1, trace2, value) {
      var min = Math.min(Math.min(...trace1.filter(Boolean)), Math.min(...trace2.filter(Boolean)));
      var max = Math.max(Math.max(...trace1.filter(Boolean)), Math.max(...trace2.filter(Boolean)));
      min = Math.floor(min / value) * value;
      var max_ceil = Math.ceil(max / value) * value;
      if (max == max_ceil) {
        max_ceil += 5;
      }
      return [min, max_ceil];
    },
    chartGDPGrowth() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace1);

      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.gdp-real'),
        text: yAxisBar1.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };


      var yAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.gdp-nominal'),
        text: yAxisBar2.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var yAxisLine = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine,
        yaxis: 'y2',
        name: this.$t('chart-text.gdp-growth-real'),
        texttemplate: '%{y}',
        text: yAxisLine.map(String),
        textposition: 'auto',
        line: {
          color: '#ffce54',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var data = [trace1, trace2, trace3];
      var layout = {
        //title: this.$t(this.supportedCharts[this.selectedOption]),
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          title: this.$t('chart-text.gdp-thousand'),
          range: this.calculateRange(this.itemChart.trace1, this.itemChart.trace2, 1000),
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis2: {
          title: this.$t('chart-text.gdp-growth-real'),
          range: [Math.floor(Math.min(...this.itemChart.trace3)) - 5,
            Math.max(...this.itemChart.trace3) > 0 ? Math.ceil(Math.max(...this.itemChart.trace3) + 5) : 0],
          ticksuffix: ' %',
          side: 'right',
          nticks: 20,
          overlaying: 'y',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartGDPUse() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace4);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.private-consumption'),
        text: yAxisBar1.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace3);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.total-investment'),
        text: yAxisBar2.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var yAxisBar3 = this.fillData(this.itemChart.trace2);
      var trace3 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar3,
        name: this.$t('chart-text.public-consumption'),
        text: yAxisBar3.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#ed5565'
        },
      };

      var yAxisBar4 = this.fillData(this.itemChart.trace1);
      var trace4 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar4,
        name: this.$t('chart-text.trade-balance'),
        text: yAxisBar4.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#ffce54'
        },
      };

      var max1 = Math.max(...this.itemChart.trace1);
      var max2 = Math.max(...this.itemChart.trace2);
      var max3 = Math.max(...this.itemChart.trace3);
      var max4 = Math.max(...this.itemChart.trace4);

      var data = [trace1, trace2, trace3, trace4];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          title: this.$t('chart-text.gdp-thousand'),
          range: [0, Math.ceil((max1 + max2 + max3 + max4) / 100) * 100],
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        barmode: 'relative',
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartUnemployment() {
      var xAxis = this.fillAxis();
      var yAxisBar = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar,
        name: this.$t('chart-text.unemployment-people'),
        text: yAxisBar.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: ['#5d9cec', '#9bbbf3', '#7bbf92', '#116a08', '#ccd1d9',
            '#c4c40b', '#5d9cec', '#48cfad', '#d4c0c2', '#fc6e51',
            '#4fc1e9', '#ec87c0', '#ac92ec', '#a0d468', '#ed5565'],
        }
      };

      var yAxisLine = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine,
        name: this.$t('chart-text.unemployment-rate'),
        texttemplate: '%{y}',
        text: yAxisLine.map(String),
        textposition: 'auto',
        yaxis: 'y2',
        line: {
          color: '#ffce54',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var data = [trace1, trace2];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          title: this.$t('chart-text.unemployment-people'),
          range: [(Math.floor(Math.min(...this.itemChart.trace1) / 10) * 10),
            (Math.ceil(Math.max(...this.itemChart.trace1) / 10) * 10)],
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis2: {
          title: this.$t('chart-text.unemployment-rate'),
          range: [Math.floor(Math.min(...this.itemChart.trace2) - 2),
            Math.ceil(Math.max(...this.itemChart.trace2)) + 1],
          ticksuffix: ' %',
          overlaying: 'y',
          side: 'right',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartBudget() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.total-budget-result'),
        texttemplate: '%{y}',
        text: yAxisBar1.map(String),
        hoverinfo: 'none',
        marker: {
         color: ['#5d9cec', '#9bbbf3', '#7bbf92', '#116a08', '#ccd1d9',
            '#c4c40b', '#5d9cec', '#48cfad', '#d4c0c2', '#fc6e51',
            '#4fc1e9', '#ec87c0', '#ac92ec', '#a0d468', '#ed5565'],
        }
      };

      var yAxisLine = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine,
        yaxis: 'y2',
        name: this.$t('chart-text.budget-result'),
        texttemplate: '%{y}',
        text: yAxisLine.map(String),
        textposition: 'top right',
        line: {
          color: '#ffce54',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var yAxisLine2 = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine2,
        yaxis: 'y2',
        name: this.$t('chart-text.budget-finance'),
        texttemplate: '%{y}',
        text: yAxisLine2.map(String),
        textposition: 'top right',
        line: {
          color: '#ed5565',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var data = [trace1, trace2, trace3];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          side: 'top',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          range: [Math.floor(Math.min(...this.itemChart.trace1.filter(Boolean)) / 100)* 100,
            Math.max(0, Math.ceil(Math.max(...this.itemChart.trace1.filter(Boolean)) / 100) * 100)],
          ticksuffix: ' %',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis2: {
          ticksuffix: ' %',
          side: 'right',
          overlaying: 'y',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartInflation() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.consumer-prices'),
        text: yAxisBar1.map(String),
        hoverinfo: 'none',
        marker: {
          color: ['#5d9cec', '#9bbbf3', '#7bbf92', '#116a08', '#ccd1d9',
            '#c4c40b', '#5d9cec', '#48cfad', '#d4c0c2', '#fc6e51',
            '#4fc1e9', '#ec87c0', '#ac92ec', '#a0d468', '#ed5565'],
        }
      };

      var yAxisLine1 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine1,
        yaxis: 'y2',
        name: this.$t('chart-text.inflation'),
        texttemplate: '%{y}',
        text: yAxisLine1.map(String),
        textposition: 'top right',
        line: {
          color: '#ffce54',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var yAxisLine2 = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine2,
        yaxis: 'y2',
        name: this.$t('chart-text.average-income-index'),
        texttemplate: '%{y}',
        text: yAxisLine2.map(String),
        textposition: 'top right',
        line: {
          color: '#ed5565',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var data = [trace1, trace2, trace3];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          range: [Math.floor(Math.min(...this.itemChart.trace1) / 100) * 100,
            Math.ceil(Math.max(...this.itemChart.trace1) / 100) * 100],
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis2: {
          range: this.calculateRange(this.itemChart.trace2, this.itemChart.trace3, 10),
          ticksuffix: ' %',
          side: 'right',
          overlaying: 'y',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartPopulation() {
      var xAxis = this.fillAxis();

      var yAxisBar1 = this.fillData(this.itemChart.trace4);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.employable-adults'),
        text: yAxisBar1.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace3);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.working-children'),
        text: yAxisBar2.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var yAxisBar3 = this.fillData(this.itemChart.trace2);
      var trace3 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar3,
        name: this.$t('chart-text.employable-pensioners'),
        text: yAxisBar3.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#ed5565'
        },
      };

      var yAxisBar4 = this.fillData(this.itemChart.trace1);
      var trace4 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar4,
        name: this.$t('chart-text.persons-unable-to-work'),
        text: yAxisBar4.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#ffce54'
        },
      };

      var data = [trace1, trace2, trace3, trace4];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        barmode: 'stack',
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartEmploymentRate() {
      var xAxis = this.fillAxis();
      var yAxisBar = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var trace0 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar,
        showlegend: false,
      };

      var yAxisLine1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine1,
        name: this.$t('chart-text.employment-rate-i'),
        texttemplate: '%{y}',
        text: yAxisLine1.map(String),
        textposition: 'top right',
        line: {
          color: '#ffce54',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };


      var yAxisLine2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine2,
        name: this.$t('chart-text.employment-rate-ii'),
        texttemplate: '%{y}',
        text: yAxisLine2.map(String),
        textposition: 'top right',
        line: {
          color: '#ed5565',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var data = [trace0, trace1, trace2];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          range: this.calculateRange(this.itemChart.trace1, this.itemChart.trace2, 10),
          ticksuffix: ' %',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartWorkingEnvironment() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace3);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.employees'),
        text: yAxisBar1.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.officials'),
        text: yAxisBar2.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var yAxisBar3 = this.fillData(this.itemChart.trace1);
      var trace3 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar3,
        name: this.$t('chart-text.unemployment-people'),
        text: yAxisBar3.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#ffce54'
        },
      };

      var data = [trace1, trace2, trace3];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          //range: [0, 2500],
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        barmode: 'relative',
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartWages() {
      var xAxis = this.fillAxis();
      var yAxisBar = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar,
        name: this.$t('chart-text.wage-level'),
        text: yAxisBar.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: ['#5d9cec', '#9bbbf3', '#7bbf92', '#116a08', '#ccd1d9',
            '#c4c40b', '#5d9cec', '#48cfad', '#d4c0c2', '#fc6e51',
            '#4fc1e9', '#ec87c0', '#ac92ec', '#a0d468', '#ed5565'],
        }
      };

      var yAxisLine = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine,
        name: this.$t('chart-text.wage-index'),
        texttemplate: '%{y}',
        text: yAxisLine.map(String),
        textposition: 'top right',
        yaxis: 'y2',
        line: {
          color: '#ffce54',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var data = [trace1, trace2];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          range: [Math.floor((Math.min(...this.itemChart.trace1.filter(Boolean)) / 1000) - 0.5) * 1000,
            Math.ceil((Math.max(...this.itemChart.trace1.filter(Boolean)) / 1000) + 0.5) * 1000],
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis2: {
          range: [Math.floor(Math.min(...this.itemChart.trace2)),
            Math.max(0, Math.ceil(Math.max(...this.itemChart.trace2))) + 2],
          ticksuffix: ' %',
          showline: true,
          overlaying: 'y',
          side: 'right'
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartIncomeDevelopment() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace4);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.unemployment-benefit'),
        text: yAxisBar1.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace3);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.officials-payments'),
        text: yAxisBar2.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var yAxisBar3 = this.fillData(this.itemChart.trace2);
      var trace3 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar3,
        name: this.$t('chart-text.pension-payments'),
        text: yAxisBar3.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#ffce54'
        },
      };

      var yAxisBar4 = this.fillData(this.itemChart.trace1);
      var trace4 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar4,
        name: this.$t('chart-text.gross-earnings'),
        text: yAxisBar4.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#ed5565'
        },
      };

      var data = [trace1, trace2, trace3, trace4];

      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          title: this.$t('chart-text.income'),
          range: [0, 1500],
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartIncomePoverty() {
      var xAxis = this.fillAxis();
      var yAxisLine1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine1,
        name: this.$t('chart-text.household-income'),
        texttemplate: '%{y}',
        text: yAxisLine1.map(String),
        textposition: 'top right',
        line: {
          color: '#ffce54',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var yAxisLine2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine2,
        name: this.$t('chart-text.equivalence-income'),
        texttemplate: '%{y}',
        text: yAxisLine2.map(String),
        textposition: 'top right',
        line: {
          color: '#48cfad',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var yAxisLine3 = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine3,
        name: this.$t('chart-text.poverty-risk-threshold'),
        texttemplate: '%{y}',
        text: yAxisLine3.map(String),
        textposition: 'top right',
        line: {
          color: '#ed5565',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var yAxisBar1 = this.fillData(this.itemChart.trace7);
      var trace4 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.unemployment-benefit'),
        text: yAxisBar1.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace6);
      var trace5 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.officials-payments'),
        text: yAxisBar2.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#afc9cf'
        },
      };

      var yAxisBar3 = this.fillData(this.itemChart.trace5);
      var trace6 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar3,
        name: this.$t('chart-text.pension-payments'),
        text: yAxisBar3.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#adc168'
        },
      };

      var yAxisBar4 = this.fillData(this.itemChart.trace4);
      var trace7 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar4,
        name: this.$t('chart-text.gross-earnings'),
        text: yAxisBar4.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#f3964c'
        },
      };


      var data = [trace1, trace2, trace3, trace4, trace5, trace6, trace7];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartStock() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.stock-clothes'),
        text: yAxisBar1.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.stock-communication'),
        text: yAxisBar2.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var yAxisBar3 = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar3,
        name: this.$t('chart-text.stock-automobile'),
        text: yAxisBar3.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#ed5565'
        },
      };

      var yAxisBar4 = this.fillData(this.itemChart.trace4);
      var trace4 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar4,
        name: this.$t('chart-text.stock-living'),
        text: yAxisBar4.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#ffce54'
        },
      };

      var max = Math.max(Math.max(...this.itemChart.trace1.filter(Boolean)), Math.max(...this.itemChart.trace2.filter(Boolean)));
      max = Math.max(Math.max(...this.itemChart.trace3.filter(Boolean)), max);
      max = Math.max(Math.max(...this.itemChart.trace4.filter(Boolean)), max);

      var data = [trace1, trace2, trace3, trace4];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          range: [0, max],
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartCorporateSuccess() {
      var xAxis = this.fillAxis();
      var yAxisBar = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar,
        name: this.$t('chart-text.equity-capital'),
        text: yAxisBar.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: ['#5d9cec', '#9bbbf3', '#7bbf92', '#116a08', '#ccd1d9',
            '#c4c40b', '#5d9cec', '#48cfad', '#d4c0c2', '#fc6e51',
            '#4fc1e9', '#ec87c0', '#ac92ec', '#a0d468', '#ed5565'],
        }
      };

      var yAxisLine1 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine1,
        name: this.$t('chart-text.profit'),
        texttemplate: '%{y}',
        text: yAxisLine1.map(String),
        textposition: 'top right',
        yaxis: 'y2',
        ine: {
          color: '#ffce54',
          width: 2.5
        },
        marker: {
          color: ['#656d78'],
          line: {
            width: 2.5,
          }
        },
      };

      var yAxisLine2 = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine2,
        name: this.$t('chart-text.cash-flow'),
        texttemplate: '%{y}',
        text: yAxisLine2.map(String),
        textposition: 'top right',
        yaxis: 'y2',
        line: {
          color: '#ed5565',
          width: 2.5
        },
        marker: {
          color: '#656d78',
          line: {
            width: 2.5,
          }
        },
      };

      var data = [trace1, trace2, trace3];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          title: this.$t('chart-text.thousand'),
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis2: {
          title: this.$t('chart-text.thousand'),
          overlaying: 'y',
          side: 'right',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartForeignTrade() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.export-rate'),
        text: yAxisBar1.map(String),
        texttemplate: '%{y}',
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.import-rate'),
        text: yAxisBar2.map(String),
        texttemplate: '%{y}',
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var yAxisLine = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine,
        name: this.$t('chart-text.power-balance'),
        texttemplate: '%{y}',
        text: yAxisLine.map(String),
        textposition: 'top right',
        yaxis: 'y2',
        line: {
          color: '#ffce54',
          width: 2.5
        },
        marker: {
          color: ['#656d78'],
          line: {
            width: 2.5,
          }
        },
      };

      var data = [trace1, trace2, trace3];
      var min = Math.floor(Math.min(...this.itemChart.trace3));
      min = min > 0 ? 0 : min;

      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          ticksuffix: ' %',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis2: {
          range: [min, Math.ceil(Math.max(...this.itemChart.trace3)) + 2],
          ticksuffix: ' %',
          overlaying: 'y',
          side: 'right',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartGovernmentAccount() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.government-revenue'),
        text: yAxisBar1.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.government-spending'),
        text: yAxisBar2.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var yAxisBar3 = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar3,
        name: this.$t('chart-text.budget'),
        text: yAxisBar3.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#ed5565'
        },
      };

      var data = [trace1, trace2, trace3];

      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          title: this.$t('chart-text.thousand'),
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartGovernmentTaxRate() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.tax-ratio-household'),
        text: yAxisBar1.map(String),
        texttemplate: '%{y}',
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.tax-ratio-economy'),
        text: yAxisBar2.map(String),
        texttemplate: '%{y}',
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var data = [trace1, trace2];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          ticksuffix: ' %',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        barmode: 'stack',
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartGovernmentSpendingRate() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.spending-rate-household'),
        text: yAxisBar1.map(String),
        texttemplate: '%{y}',
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.spending-rate-economy'),
        text: yAxisBar2.map(String),
        texttemplate: '%{y}',
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var data = [trace1, trace2];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          ticksuffix: ' %',
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        barmode: 'stack',
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartFinanceSituationHousehold() {
      var yAxis = this.fillAxis();
      var xAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxisBar1,
        y: yAxis,
        orientation: 'h',
        name: this.$t('chart-text.credit-volume'),
        text: xAxisBar1.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var xAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxisBar2,
        y: yAxis,
        orientation: 'h',
        name: this.$t('chart-text.savings-volume'),
        text: xAxisBar2.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var xAxisBar3 = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'bar',
        x: xAxisBar3,
        y: yAxis,
        orientation: 'h',
        name: this.$t('chart-text.disposable-income'),
        text: xAxisBar3.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#ed5565'
        },
      };

      var data = [trace1, trace2, trace3];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartFinanceSituationEconomy() {
      var yAxis = this.fillAxis();
      var xAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxisBar1,
        y: yAxis,
        orientation: 'h',
        name: this.$t('chart-text.credit-volume'),
        text: xAxisBar1.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var xAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxisBar2,
        y: yAxis,
        orientation: 'h',
        name: this.$t('chart-text.time-deposits-volume'),
        text: xAxisBar2.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var xAxisBar3 = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'bar',
        x: xAxisBar3,
        y: yAxis,
        orientation: 'h',
        name: this.$t('chart-text.profit'),
        text: xAxisBar3.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#ed5565'
        },
      };

      var data = [trace1, trace2, trace3];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartFinanceSituationGovernment() {
      var yAxis = this.fillAxis();
      var xAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxisBar1,
        y: yAxis,
        orientation: 'h',
        name: this.$t('chart-text.total-dept'),
        text: xAxisBar1.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#5d9cec'
        },
      };

      var xAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxisBar2,
        y: yAxis,
        orientation: 'h',
        name: this.$t('chart-text.budget-result'),
        text: xAxisBar2.map(String),
        hoverinfo: 'none',
        marker: {
          color: '#48cfad'
        },
      };

      var data = [trace1, trace2];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    },
    chartProsperity() {
      var xAxis = this.fillAxis();
      var yAxisBar1 = this.fillData(this.itemChart.trace1);
      var trace1 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar1,
        name: this.$t('chart-text.prosperity-economy'),
        text: yAxisBar1.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#eb4c79'
        },
      };

      var yAxisBar2 = this.fillData(this.itemChart.trace2);
      var trace2 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar2,
        name: this.$t('chart-text.prosperity-government'),
        text: yAxisBar2.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#721c7a'
        },
      };

      var yAxisBar3 = this.fillData(this.itemChart.trace3);
      var trace3 = {
        type: 'bar',
        x: xAxis,
        y: yAxisBar3,
        name: this.$t('chart-text.prosperity-household'),
        text: yAxisBar3.map(String),
        textposition: 'inside',
        insidetextanchor: 'middle',
        insidetextfont: {size: 16},
        hoverinfo: 'none',
        marker: {
          color: '#00ac2c',
        },
      };

      var yAxisLine2 = this.fillData(this.itemChart.trace4);
      var trace4 = {
        type: 'scatter',
        mode: 'lines+markers+text',
        x: xAxis,
        y: yAxisLine2,
        name: this.$t('chart-text.prosperity-ecomania'),
        text: yAxisLine2.map(String),
        textposition: 'top right',
        size: 16,
        line: {
          color: '#245375',
          width: 2.5
        },
        marker: {
          color: '#245375',
          line: {
            width: 2.5
          }
        },
      };

      var min = Math.min(Math.min(...this.itemChart.trace1.filter(Boolean)), Math.min(...this.itemChart.trace2.filter(Boolean))) - 10;
      var max = Math.max(Math.max(...this.itemChart.trace1.filter(Boolean)), Math.max(...this.itemChart.trace2.filter(Boolean)));
      max = Math.max(Math.max(...this.itemChart.trace3.filter(Boolean)), max);
      max = Math.max(Math.max(...this.itemChart.trace4.filter(Boolean)), max);

      min = Math.floor(min / 10) * 10;
      max = Math.ceil(max / 10) * 10 + 10;

      var data = [trace1, trace2, trace3, trace4];
      var layout = {
        margin: this.margin,
        font: this.font,
        legend: this.legend,
        xaxis: {
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
        yaxis: {
          range: [min, max],
          nticks: 20,
          mirror: true,
          ticks: 'outside',
          showline: true,
        },
      };

      var config = {responsive: true, displayModeBar: false}
      Plotly.newPlot(this.$refs.chart, data, layout, config);
    }
  },
  watch: {
    selectedOption: {
      immediate: true,
      handler: function (newValue) {
        switch (newValue) {
          case 0:
            break;
          case 1:
            this.itemChart = Object.assign({}, this.$store.state.statistics.gdpGrowth);
            this.chartGDPGrowth();
            break;
          case 2:
            this.itemChart = Object.assign({}, this.$store.state.statistics.gdpUse);
            this.chartGDPUse();
            break;
          case 3:
            this.itemChart = Object.assign({}, this.$store.state.statistics.inflation);
            this.chartInflation();
            break;
          case 4:
            this.itemChart = Object.assign({}, this.$store.state.statistics.foreignTrade);
            this.chartForeignTrade();
            break;
          case 5:
            this.itemChart = Object.assign({}, this.$store.state.statistics.unemployment);
            this.chartUnemployment();
            break;
          case 6:
            this.itemChart = Object.assign({}, this.$store.state.statistics.workingEnvironment);
            this.chartWorkingEnvironment();
            break;
          case 7:
            this.itemChart = Object.assign({}, this.$store.state.statistics.employmentRate);
            this.chartEmploymentRate();
            break;
          case 8:
            this.itemChart = Object.assign({}, this.$store.state.statistics.population);
            this.chartPopulation();
            break;
          case 9:
            this.itemChart = Object.assign({}, this.$store.state.statistics.wages);
            this.chartWages();
            break;
          case 10:
            this.itemChart = Object.assign({}, this.$store.state.statistics.incomeDevelopment);
            this.chartIncomeDevelopment();
            break;
          case 11:
            this.itemChart = Object.assign({}, this.$store.state.statistics.incomePoverty);
            this.chartIncomePoverty();
            break;
          case 12:
            this.itemChart = Object.assign({}, this.$store.state.statistics.financeSituationHousehold);
            this.chartFinanceSituationHousehold();
            break;
          case 13:
            this.itemChart = Object.assign({}, this.$store.state.statistics.corporateSuccess);
            this.chartCorporateSuccess();
            break;
          case 14:
            this.itemChart = Object.assign({}, this.$store.state.statistics.stock);
            this.chartStock();
            break;
          case 15:
            this.itemChart = Object.assign({}, this.$store.state.statistics.financeSituationEconomy);
            this.chartFinanceSituationEconomy();
            break;
          case 16:
            this.itemChart = Object.assign({}, this.$store.state.statistics.budget);
            this.chartBudget();
            break;
          case 17:
            this.itemChart = Object.assign({}, this.$store.state.statistics.governmentAccount);
            this.chartGovernmentAccount();
            break;
          case 18:
            this.itemChart = Object.assign({}, this.$store.state.statistics.governmentTaxRate);
            this.chartGovernmentTaxRate();
            break;
          case 19:
            this.itemChart = Object.assign({}, this.$store.state.statistics.governmentSpendingRate);
            this.chartGovernmentSpendingRate();
            break;
          case 20:
            this.itemChart = Object.assign({}, this.$store.state.statistics.financeSituationGovernment);
            this.chartFinanceSituationGovernment();
            break;
          case 21:
            this.itemChart = Object.assign({}, this.$store.state.statistics.prosperity);
            this.chartProsperity();
            break;
        }
      }
    }
  },
  computed: {
    round() {
      return this.$store.state.playground.gameRound;
    },
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.statistics.gameChartCalculated;
        },
        () => {
          this.chartCalculated = this.$store.state.statistics.gameChartCalculated;
        }
    );
  },
  unmounted() {
    this.unwatch();
  },

}
</script>

<style scoped>

</style>