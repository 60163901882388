<template>
  <router-view/>
</template>

<script>

import axios from "axios";
import {setLocale} from '@vee-validate/i18n';

export default {
  name: 'App',
  beforeMount() {
    axios.post('/api', {'action': 'get/language'})
        .then(response => {
          if (response.data.language) {
            this.$i18n.locale = response.data.language;
            setLocale(response.data.language);
          }
        })
        .catch((error) => alert(error.response.data.message));

    axios.post('/api', {'action': 'get/theme'})
        .then(response => {
          if (response.data.theme) {
            let body = document.getElementsByTagName('body')[0];
            body.classList.add(response.data.theme);
            this.$store.commit("theme/setThemeClass", response.data.theme)
          }
        })
        .catch((error) => alert(error.response.data.message));
  },

}

</script>

<style lang="scss">
@import 'styles/settings';
@import '~foundation-sites/scss/foundation';
@import '~motion-ui/src/motion-ui';

@include foundation-everything;
@include motion-ui-transitions;
@include motion-ui-animations;

@import 'styles/sidenav';
@import 'styles/default';
@import 'styles/flip';


.error {
  color: red;
}

.grid-margin-x > .cell {
  margin-right: 1px;
}

.divider {
  position: relative;
  margin-top: 30px;
  margin-bottom: 10px;
  height: 1px;
  background-image: linear-gradient(to right, transparent, white, transparent);
}

</style>
