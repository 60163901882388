<template>
  <div id="sidenav" class="sidenav" v-bind:class="{ open: isSidenavOpen }">
    <button class="sidenav-close-button clear secondary" type="button" @click="closeSidenav"><i
        area-hidden="true" class="far fa-window-close fa-2x"></i></button>
    <ul class="vertical menu accordion-menu" data-accordion-menu>
      <slot name="nav"></slot>
      <div class="divider"></div>
      <li>
        <a class="button clear secondary" @click="goToAccountView">{{ $t('menu.account') }}</a>
      </li>
      <li>
        <a class="button clear secondary" @click="logout">{{ $t('menu.logout') }}</a>
      </li>
    </ul>
  </div>
  <div id="main" v-bind:class="{ 'main-with-open-nav': isSidenavOpen }">
    <div class="header">
      <div class="clearfix">
        <button id="sidenav-open-button" class="button warning float-right" type="button"
                v-bind:class="{ disappear: isSidenavOpen }" @click="openSidenav"><i class="fas fa-bars fa-2x"></i>
        </button>
      </div>
      <div class="header-bg" v-bind:class="themeBackground"></div>

      <div class="header-title">

        <div class="header-title-container">
          <div v-if="!$store.getters['theme/isDefaultTheme']">
            <div class="eco-logo-bubble-bg" v-bind:class="themeBackground"/>
          </div>

          <div v-if="$store.getters['theme/isDefaultTheme']">
            <span class="eco-logotext-first">Eco</span>
            <span class="eco-logotext-second">Mania</span>
            <span class="eco-title"> {{ $t("header.title") }} </span>
          </div>

          <div class="eco-pagetitle-color" v-bind:class="defaultColor">
            <slot name="page-title"></slot>
          </div>
        </div>

      </div>

    </div>
    <hr class="eco-header-line">
    <router-view></router-view>
  </div>
</template>

<script>
/*global url*/
import axios from 'axios';

export default {
  name: "BoardLayout",

  methods: {
    goToAccountView() {
      this.$router.push(url + '/account');
      this.$store.commit('sidenav/closeSidenav');
    },
    openSidenav() {
      this.$store.commit('sidenav/openSidenav');
    },
    closeSidenav() {
      this.$store.commit('sidenav/closeSidenav');
    },
    logout() {
      axios.post(url, {})
          .then(() => this.$router.go(0));
    }
  },
  mounted() {
    this.$store.dispatch('account/reload');
  },
  computed: {
    isSidenavOpen() {
      return this.$store.state.sidenav.isSidenavOpen;
    },
    themeBackground() {
      return this.$store.state.theme.themeBackgroundClass;
    },
    themeColor() {
      return this.$store.state.theme.themeColorClass;
    },
    defaultColor() {
      return "color-default";
    },
  }
}
</script>

<style scoped>

</style>