<template>
  <board-layout>
    <template v-slot:nav>
      <li>
        <a class="button clear secondary" @click="goToAdminView">{{ $t('menu.admins') }}</a>
      </li>
      <li>
        <a class="button clear secondary" @click="goToTrainerView">{{ $t('menu.trainers') }}</a>
      </li>
      <li>
        <a class="button clear secondary" @click="goToParameterSetView">{{ $t('menu.parameter-set') }}</a>
      </li>
      <li>
        <a class="button clear secondary" @click="goToGameView">{{ $t('menu.games') }}</a>
      </li>
      <span v-show= "this.$store.state.adminBoard.websitePermission"><li>
        <a class="button clear secondary" @click="goToWebsiteView">{{ $t('menu.website') }}</a>
      </li></span>
      <span v-show= "this.$store.state.adminBoard.websiteFilePermission"><li>
        <a class="button clear secondary" @click="goToExplorerView">{{ $t('menu.explorer') }}</a>
      </li></span>
    </template>
    <template v-slot:page-title>
      {{ $t("admin-board.page-title") }}
    </template>
  </board-layout>
</template>

<script>
/*global url*/
import BoardLayout from '../components/BoardLayout';

export default {
  name: 'AdminBoard',
  components: {BoardLayout},
  methods: {
    goToAdminView() {
      this.$router.push(url + '/admins');
      this.$store.commit('sidenav/closeSidenav');
    },
    goToParameterSetView() {
      this.$router.push(url + '/parameter-set');
      this.$store.commit('sidenav/closeSidenav');
    },
    goToGameView() {
      this.$router.push(url + '/games');
      this.$store.commit('sidenav/closeSidenav');
    },
    goToTrainerView() {
      this.$router.push(url + '/trainers');
      this.$store.commit('sidenav/closeSidenav');
    },
    goToWebsiteView() {
      this.$router.push(url + '/website');
      this.$store.commit('sidenav/closeSidenav');
    },
    goToExplorerView() {
      this.$router.push(url + '/explorer');
      this.$store.commit('sidenav/closeSidenav');
    },
  },
  mounted() {
    this.$store.commit('theme/setThemeBackgroundClass', 'bg-admin');
    this.$store.commit('theme/setThemeColorClass', 'color-admin');
    this.$store.dispatch('adminBoard/readWebSitePermission');
  }
};
</script>

<style scoped>

</style>