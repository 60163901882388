<template>
  <div class="button-group">
    <a class="button eco-group-button eco-navbutton-first" @click="showEconomyView">{{ $t('group-title.economy') }}</a>
    <a class="button eco-group-button " @click="showGovernmentView">{{ $t('group-title.government') }}</a>
    <a class="button eco-group-button eco-navbutton-last" @click="showCommonView">{{ $t('group-title.general') }}</a>
    <div v-show="showLockButton">
      <div v-if="isLocked">
        <a class="button primary float-right eco-button eco-group-button" type="button" @click="toggleLock" ><i
                  class="fas fa-lock"></i></a>
      </div>
      <div v-else>
        <a class="button primary float-right eco-button eco-group-button" type="button" @click="toggleLock"><i
                  class="fas fa-lock-open"></i></a>
      </div>
    </div>

  </div>

  <component ref="form" :is="currentView" :disabled="isLocked" :saveButton="false"></component>
</template>

<script>
import CommonForm from "../CommonForm";
import EconomyForm from '../economy/EconomyForm';
import GovernmentForm from "../government/GovernmentForm";

export default {
  name: "WhatToKnowHouseholdNavigation",
  data() {
    return {
      currentView: null,
      ref: null,
      item: {},
      isLocked: true,
      showLockButton: true,
    }
  },
  methods: {
    showCommonView() {
      this.currentView = CommonForm;
      this.showLockButton = false;
    },
    showGovernmentView() {
      this.currentView = GovernmentForm;
      this.showLockButton = !this.$store.getters['playground/inputHouseholdClosed'];
    },
    showEconomyView() {
      this.currentView = EconomyForm;
      this.showLockButton = !this.$store.getters['playground/inputHouseholdClosed'];
    },
    toggleLock() {
      if(this.$store.getters['playground/inputHouseholdClosed']) {
        this.showLockButton = false;
        return;
      }
      this.isLocked = !this.isLocked;
      if(this.isLocked) {
        this.$refs.form.disableInput();
        this.$refs.form.saveNoBudgetAccount();
      }
      else {
        this.$refs.form.enableInput();
      }
    },
  },
  created() {
    this.currentView = EconomyForm;
  },

}
</script>

<style scoped>

.save-buttons {
  margin-left: 30px;
}

.space {
  margin-left: 15px;
}

</style>