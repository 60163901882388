<template>
  <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">

      <TopFormBar :save-button="true" :title="$t('account.form-title')" class="eco-form-bar"/>

      <div class="grid-container eco-form-container">

        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('account.user-name') }}
              <Field v-model="item.username" as="input" name="username" placeholder="e-mail" type="text" v-lowercase></Field>
              <ErrorMessage as="small" class="error" name="username"></ErrorMessage>
            </label>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('account.first-name') }}
              <Field v-model="item.first_name" as="input" name="firstName" type="text"></Field>
              <ErrorMessage as="small" class="error" name="firstName"></ErrorMessage>
            </label>
          </div>
          <div class="medium-6 cell">
            <label>{{ $t('account.last-name') }}
              <Field v-model="item.last_name" as="input" name="lastName" type="text"></Field>
              <ErrorMessage as="small" class="error" name="lastName"></ErrorMessage>
            </label>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('account.password') }}
              <Field v-model="item.password" as="input" name="password" placeholder="password" type="password"></Field>
              <Field as="input" name="passwordConfirmation" placeholder="confirmation" type="password"></Field>
              <ErrorMessage as="small" class="error" name="passwordConfirmation"></ErrorMessage>
            </label>
          </div>
        </div>

      </div>

    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from 'axios';
import TopFormBar from "../../TopFormBar";

export default {
  name: 'AccountForm',
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
    TopFormBar,
  },
  data() {
    const schema = {
      username: 'required|email',
      firstName: 'required',
      lastName: 'required',
      passwordConfirmation: 'confirmed:@password'
    };
    return {
      schema,
      item: Object.assign({}, this.$store.state.account.account)
    };
  },
  methods: {
    save() {
      axios.post(url, {'action': 'save-account', 'account': this.item})
          .then((response) => {
            this.$store.commit('account/replace', response.data.account);
            if (this.item.password) {
              this.$router.go(0);
            } else {
              this.$router.push(url);
            }
          })
          .catch((error) => alert(error.response.data.message));
    },
  },
};
</script>

<style scoped>

input[type=text], input[type=datetime-local],
input[type=date-local], input[type=date], input[type=password],
select, optgroup, textarea {
  border-radius: var(--border-radius);
  height: 2rem;
  font-size: 100%;
}

</style>