<template>
  <div class="eco-table">
    <div class="grid-x grid-margin-x">
      <div class="button-group cell shrink">
        <button class="button primary float-left eco-button eco-group-button" type="button"
                @click="refresh"><i class="fas fa-undo-alt"></i>
        </button>
        <button class="button primary float-left eco-button eco-group-button" type="button"
                @click="add"><i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div>
      <table>
        <thead class="eco-row-header">
        <tr>
          <th>{{ $t('license-board.license-key') }}</th>
          <th>{{ $t('license-board.license-holder') }}</th>
          <th>{{ $t('license-board.admin-account') }}</th>
          <th>{{ $t('license-board.valid-from') }}</th>
          <th>{{ $t('license-board.valid-to') }}</th>
          <th>{{ $t('license-board.enabled') }}</th>
          <th class="eco-row-header-action"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in rows" v-bind:key="row" class="eco-table-border">
          <td>{{ row.license_key }}</td>
          <td>{{ row.license_holder }}</td>
          <td>{{ row.admin_account }}</td>
          <td>{{ row.valid_from }}</td>
          <td>{{ row.valid_to }}</td>
          <td style="text-align: center;">
            <icon v-bind="getIcon(row.enabled)"></icon>
          </td>
          <td>
            <div class="grid-x grid-margin-x">
              <div class="button-group cell shrink">
                <button class="button primary eco-button eco-group-button" type="button"
                        @click="edit(row)"><i class="fas fa-edit"></i>
                </button>
                <button class="button primary eco-button eco-group-button" type="button"
                        @click="remove(row)"><i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/*global url*/
import axios from 'axios';

export default {
  name: 'LicenseTable',
  computed: {
    rows() {
      return this.$store.state.licenseBoard.licenses;
    }
  },
  methods: {
    edit(item) {
      this.$store.commit('licenseBoard/setSelectedLicense', item);
      this.$router.push(url + '/edit');
    },
    remove(item) {
      axios.post(url, {'action': 'remove', 'item': item})
          .then(() => this.refresh(null))
          .catch((error) => alert(error.response.data.message));
    },
    add() {
      this.$store.commit('licenseBoard/clearSelectedLicense');
      this.$router.push(url + '/add');
    },
    refresh() {
      this.$store.dispatch('licenseBoard/reloadLicenses');
    },
    getIcon(state) {
      const properties = {
        icon: 'x',
        style: 'color: red',
        class: 'fa fa-times'
      };
      if (state) {
        properties.icon = 'fa-check';
        properties.style = 'color: green';
        properties.class = 'fa fa-check';
      }
      return properties
    }
  },
  mounted() {
    this.refresh(null);
  },
};
</script>

<style scoped>

</style>