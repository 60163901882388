<template>
  <ValidationForm v-if="item" v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,saveParameter)">

      <TopFormBar :info-text="message" :info-type="messageType"
                  :save-button="!isDisabled"
                  :title="$t('parameter.government-parameter')" class="eco-form-bar-second" style="width: 70%;"/>

      <div class="grid-container eco-container" style="width: 70%;">

        <div cell class="medium-12">
          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-6 cell">
                <h4>{{ $t('parameter.taxes-household') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.wage-tax') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_parameter.household_income_tax" :disabled="isDisabled" as="input"
                       name="householdIncomeTax" type="number"></Field>
                <ErrorMessage as="small" class="error" name="householdIncomeTax"></ErrorMessage>
              </div>
            </div>
          </div>

          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-6 cell">
                <h4>{{ $t('parameter.taxes-company') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.income-tax') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_parameter.economy_income_tax" :disabled="isDisabled" as="input"
                       name="economyIncomeTax" type="number"></Field>
                <ErrorMessage as="small" class="error" name="economyIncomeTax"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.sales-tax') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_parameter.economy_sales_tax" :disabled="isDisabled" as="input"
                       name="economySalesTax" type="number"></Field>
                <ErrorMessage as="small" class="error" name="economySalesTax"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.corporate-tax') }} (%) </label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_parameter.corporate_tax" :disabled="isDisabled" as="input"
                       name="corporateTax" type="number"></Field>
                <ErrorMessage as="small" class="error" name="corporateTax"></ErrorMessage>
              </div>
            </div>
            <hr class="eco-horizontal-split-line"/>
          </div>

          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-4 cell">
                <h4>{{ $t('parameter.finance') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.public-dept-begin') }} </label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_parameter.public_dept_begin" name="publicDeptBegin">
                  <NumberField v-model="item.government_parameter.public_dept_begin"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="publicDeptBegin"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.bond-issue') }}</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_parameter.bond_issue" name="bondIssue">
                  <NumberField v-model="item.government_parameter.bond_issue"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="bondIssue"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.bond-repayment') }}</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_parameter.bond_repayment" name="bondRepayment">
                  <NumberField v-model="item.government_parameter.bond_repayment"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="bondRepayment"></ErrorMessage>
              </div>
            </div>
            <hr class="eco-horizontal-split-line"/>
          </div>

        </div>
        <div cell class="medium-6">
          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-4 cell">
                <h4>{{ $t('parameter.public-spending') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.pension-payments') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_parameter.pension_payments" name="pensionPayments">
                  <NumberField v-model="item.government_parameter.pension_payments"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="pensionPayments"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.unemployment-benefit') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_parameter.unemployment_benefit" name="unemploymentBenefit">
                  <NumberField v-model="item.government_parameter.unemployment_benefit"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="unemploymentBenefit"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.officials-payments') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_parameter.official_payments" name="officialPayments">
                  <NumberField v-model="item.government_parameter.official_payments"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="officialPayments"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.officials-cnt') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_parameter.official_count" name="officialCount">
                  <NumberField v-model="item.government_parameter.official_count" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="officialCount"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.household-support') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_parameter.household_support" name="householdSupport">
                  <NumberField v-model="item.government_parameter.household_support"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="householdSupport"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.business-support') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_parameter.business_support" name="businessSupport">
                  <NumberField v-model="item.government_parameter.business_support"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="businessSupport"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-4 cell">
                <label class="text-left middle">{{ $t('parameter.public-consumption') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_parameter.public_consumption" name="publicConsumption">
                  <NumberField v-model="item.government_parameter.public_consumption"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="publicCconsumption"></ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from "vee-validate";
import axios from "axios";
import TopFormBar from "../../TopFormBar";
import NumberField from "../../NumberField";

export default {
  name: "GovernmentParameterForm",
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
    TopFormBar,
    NumberField
  },
  props: {
    disabled: {
      default: true,
      type: Boolean
    },
  },
  data() {
    const schema = {

      householdIncomeTax: 'required',
      pensionPayments: 'required',
      unemploymentBenefit: 'required',
      officialPayments: 'required',
      officialCount: 'required',
      householdSupport: 'required',

      economyIncomeTax: 'required',
      economySalesTax: 'required',
      corporateTax: 'required',
      publicConsumption: 'required',
      businessSupport: 'required',

      publicDeptBegin: 'required',
      bondIssue: 'required',
      bondRepayment: 'required',

    };
    return {
      schema,
      item: undefined,
      message: "",
      messageType: undefined,
    };
  },
  computed: {
    isDisabled() {
      return this.disabled ? true : false;
    }
  },
  methods: {
    saveParameter() {
      this.$store.commit('governmentParameter/replaceGovernmentParameter', this.item);
      axios.post(url, {
        'action': 'save-government-parameter',
        'government-parameter': this.$store.state.governmentParameter.governmentParameter,
      })
          .then(() => {
            if (url.includes("admin-board")) {
              this.$router.push(url + '/parameter-set/edit');
            } else {
              this.$router.push(url + '/game/edit');
            }
            this.showMessage(this.$t('messages.save-successful'), this.$MSG_TYPE_SUCCESS);
          })
          .catch((error) => alert(error.response.data.message));
    },
    showMessage(msg, type = 0) {
      this.message = msg;
      this.messageType = type;
      setTimeout(() => {
        this.message = "";
      }, 2000);
    }
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.governmentParameter.governmentParameter;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.governmentParameter.governmentParameter);
        }
    );
    if (this.$store.state.governmentParameter.governmentParameter) {
      this.item = Object.assign({}, this.$store.state.governmentParameter.governmentParameter);
    }
  },
  unmounted() {
    this.unwatch();
  }
}
</script>

<style scoped>


</style>