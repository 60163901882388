<template>
  <section id="downloads" class="section-main">
            <h2>Downloads</h2>
            <div class="medium-6 cell">
              <div class="grid-x" style="display: inline-flex">
                <div v-for="file in downloads()" v-bind:key="file" class="cell small-3">
                    <div class="card box eco-box">
                        <a :href="'/static/storage/' + file" target="_blank">
                            <div class="doc-box"></div>
                        </a>
                        <div class="card-section">
                            <p>{{ file }}</p>
                        </div>
                    </div>
                </div>
              </div>
            </div>
  </section>
</template>

<script>
export default {
  name: "DownloadForm",

  computed: {
    downloads() {
            return () => {
                console.log("Test: " + JSON.stringify(this.$store.state.websiteBoard.downloadsPrivate))
                return this.$store.state.websiteBoard.downloadsPrivate
            }
        }
  }
}
</script>

<style scoped>

</style>