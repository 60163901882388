<template>
  <board-layout>
    <template v-slot:nav>
      <li>
        <a class="button clear secondary" @click="goToGameView">{{ $t('menu.games') }}</a>
      </li>
      <li>
        <a class="button clear secondary" @click="goToPlayerView">{{ $t('menu.players') }}</a>
      </li>
      <li>
        <a class="button clear secondary" @click="goToDownloadView">{{ $t('menu.downloads') }}</a>
      </li>
    </template>

    <template v-slot:page-title>
      {{ $t("trainer-board.page-title") }}
    </template>
  </board-layout>
</template>

<script>
/*global url*/
import BoardLayout from '../components/BoardLayout';

export default {
  name: 'TrainerBoard',
  components: {BoardLayout},
  methods: {
    goToGameView() {
      this.$router.push(url + '/games');
      this.$store.commit('sidenav/closeSidenav');
    },
    goToPlayerView() {
      this.$router.push(url + '/players');
      this.$store.commit('sidenav/closeSidenav');
    },
    goToDownloadView() {
      this.$router.push(url + '/downloads');
      this.$store.commit('sidenav/closeSidenav');
    },
  },
  mounted() {
    this.$store.commit('theme/setThemeBackgroundClass', 'bg-trainer');
    this.$store.commit('theme/setThemeColorClass', 'color-trainer');

    this.$i18n.locale = 'de'
    this.$store.dispatch('websiteBoard/loadDownloadFiles');
  }
};
</script>

<style scoped>

</style>