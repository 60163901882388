<template>
  <div class="eco-loading-progress eco-loading-progress-color" v-bind:class="themeColor">
    <div class="eco-loading-message">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "LoadingProgress",

  props: {
    text: {
      type: String,
      required: true
    },
  },
  computed: {
    themeColor() {
      return this.$store.state.theme.themeColorClass;
    },
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.loadingProgress.isLoading;
        },
        () => {
          if (!this.$store.state.loadingProgress.isLoading) {
            setTimeout(() => {
              this.$store.commit('loadingProgress/loading', false)
            }, 2000);
          }
        }
    );
  },
  unmounted() {
    this.unwatch();
  },
}
</script>

<style scoped>

</style>