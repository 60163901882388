<template>
  <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">

      <TopFormBar :save-button="true" :title="$t('trainer-board.form-title-players')" class="eco-form-bar"/>

      <div class="grid-container eco-form-container">
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('trainer-board.government') }}
              <Field v-model="item.player_government_representatives" as="input" name="playerGovernmentRepresentatives"
                     placeholder="username" type="text"></Field>
              <ErrorMessage as="small" class="error" name="playerGovernmentRepresentatives"></ErrorMessage>
              <Field v-model="item.player_government_representatives_password" as="input" name="playerGovernmentRepresentativesPassword"
                     placeholder="password" type="password"></Field>
            </label>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('trainer-board.economy') }}
              <Field v-model="item.player_economy_representatives" as="input" name="playerEconomyRepresentatives"
                     placeholder="username" type="text"></Field>
              <ErrorMessage as="small" class="error" name="playerEconomyRepresentatives"></ErrorMessage>
              <Field v-model="item.player_economy_representatives_password" as="input" name="playerEconomyRepresentativesPassword"
                     placeholder="password" type="password"></Field>
            </label>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('trainer-board.household') }}
              <Field v-model="item.player_household_representatives" as="input" name="playerHouseholdRepresentatives"
                     placeholder="username" type="text"></Field>
              <ErrorMessage as="small" class="error" name="playerHouseholdRepresentatives"></ErrorMessage>
              <Field v-model="item.player_household_representatives_password" as="input" name="playerHouseholdRepresentativesPassword"
                     placeholder="password" type="password"></Field>
            </label>
          </div>
        </div>

      </div>

    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from 'axios';
import TopFormBar from "../../TopFormBar";

export default {
  name: 'PlayerForm',
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
    TopFormBar,
  },
  data() {
    const schema = {
      playerGovernmentRepresentatives: 'required',
      playerEconomyRepresentatives: 'required',
      playerHouseholdRepresentatives: 'required',
    };
    return {
      schema,
      item: Object.assign({}, this.$store.state.trainerBoard.selectedPlayer),
    };
  },
  computed: {},
  methods: {
    save() {
      axios.post(url, {'action': 'save-player', 'item': this.item})
          .then(() => this.$router.push(url))
          .catch((error) => alert(error.response.data.message));
    },
  },
};
</script>

<style scoped>

input[type=text], input[type=datetime-local],
input[type=date-local], input[type=date], input[type=password],
select, optgroup, textarea {
  border-radius: var(--border-radius);
  height: 2rem;
  font-size: 100%;
}

</style>