<template>
  <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">

      <div class="grid-container fluid eco-panel-container" data-equalizer data-equalizer-mq="large-up">

        <div class="grid-x ">
          <div class="grid-x small-up-2 medium-up-3 large-up-3 eco-budget-panel" data-equalize-by-row="true"
               data-equalizer>

            <div cell class="medium-12">
              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <h4>{{ $t('economy.revenue-side') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.product-consumption') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.product_consumption"
                                 disabled name="productConsumption"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.public-consumption') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.public_consumption"
                                 disabled name="publicConsumption"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.minus-sales-tax') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.sales_tax_economy"
                                 disabled name="minusSalesTax"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.exports') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.export_income"
                                 disabled name="exports"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.business-support') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.state_support_economy"
                                 disabled name="businessSupport"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.interest-income') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.interest_income"
                                 disabled name="interestIncome"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('economy.sum-total-revenue') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.total_revenue"
                                 disabled name="sumTotalRevenue"></NumberField>
                  </div>
                </div>
              </div>

              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <h4>{{ $t('economy.costs') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.staff-costs') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.staff_costs"
                                 disabled name="staffCosts"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.staff-costs-additional') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.staff_costs_additional"
                                 disabled name="staffCostsAdditional"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.material-costs') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.material_costs"
                                 disabled name="materialCosts"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.amortizations') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.amortization"
                                 disabled name="amortizations"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.interest-costs') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.interest_costs"
                                 disabled name="interestCosts"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('economy.sum-total-costs') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.total_costs"
                                 disabled name="sumTotalCosts"></NumberField>
                  </div>
                </div>
              </div>

              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.profit-before-tax') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.profit_before_tax"
                                 disabled name="profitBeforeTax"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.minus-corporate-tax') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.corporate_tax"
                                 disabled name="minusCorporateTax"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle  eco-style-bold">{{ $t('economy.profit-after-tax') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.profit_after_tax"
                                 disabled name="profitAfterTax"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.plus-amortizations') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.amortization"
                                 disabled name="plusAmortizations"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.minus-investments') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.investment_costs"
                                 disabled name="minusInvestments"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle  eco-style-bold">{{ $t('economy.cashflow') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.cashflow"
                                 disabled name="cashflow"></NumberField>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="medium-4 columns eco-budget-panel" data-equalizer-watch>
            <div cell class="medium-12">
              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-12 cell">
                    <h4>{{ $t('economy.savings-volume') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.time-deposits-begin') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.time_deposits_begin"
                                 disabled name="timeDepositsBegin"></NumberField>
                  </div>
                </div>

                <!--div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.time-deposits-interest') }}</label>
                  </div>
                  <div class="medium-5 cell">
                      <NumberField name="timeDepositsInterest"
                             v-model="item.time_deposits_interest" disabled></NumberField>
                  </div>
                </div-->

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.time-deposits-volume') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.time_deposits_volume"
                                 disabled name="timeDepositsVolume"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('economy.time-deposits-end') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.time_deposits_end"
                                 disabled name="timeDepositsEnd"></NumberField>
                  </div>
                </div>
              </div>

              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-12 cell">
                    <h4>{{ $t('economy.credits-volume') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.obligo-volume-begin') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.liability_volume_begin"
                                 disabled name="obligoVolumeBegin"></NumberField>
                  </div>
                </div>

                <!--div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.credit-interest') }}</label>
                  </div>
                  <div class="medium-5 cell">
                      <NumberField name="creditInterest" as="input" type="number"
                             v-model="item.credit_interest" disabled></NumberField>
                  </div>
                </div-->

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.credit-payments') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.loan_repayment_economy"
                                 disabled name="borrowing"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('economy.obligo-volume-end') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.liability_volume_end"
                                 disabled name="obligoVolumeEnd"></NumberField>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="medium-4 columns eco-budget-panel" data-equalizer-watch>
            <div cell class="medium-12">
              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <h4>{{ $t('economy.investments') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.investments-begin') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.investment_begin"
                                 disabled name="investmentsBegin"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.net-investments') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.net_investment"
                                 disabled name="investments_new"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.investments-alternative') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.investment_alternative"
                                 disabled name="sumAmortizations"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('economy.investments-end') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.investment_end"
                                 disabled name="investmentsEnd"></NumberField>
                  </div>
                </div>
              </div>

              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.plus-time-deposits-end') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.time_deposits_end"
                                 disabled name="timeDepositsEnd"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.minus-obligo-volume-end') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.liability_volume_end"
                                 disabled name="obligoVolumenEnd"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('economy.equity-capital') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.equity_capital"
                                 disabled name="equityCapital"></NumberField>
                  </div>
                </div>
              </div>

              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <h4>{{ $t('economy.job-market') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('economy.employment-demand') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.employment_demand"
                                 disabled name="employmentDemand"></NumberField>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </ValidationForm>
</template>

<script>
import {Form as ValidationForm} from 'vee-validate';
import NumberField from "../../NumberField";

export default {
  name: "economydBudgetAccount",
  components: {
    NumberField,
    ValidationForm,
  },
  data() {
    const schema = {};
    return {
      schema,
      item: {},
    };
  },
  methods: {},
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.economyBudgetAccount.economyBudgetAccount;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.economyBudgetAccount.economyBudgetAccount);
        }
    );
    if (this.$store.state.economy) {
      this.item = Object.assign({}, this.$store.state.economyBudgetAccount.economyBudgetAccount);
    }
  },
  unmounted() {
    this.unwatch();
  },
}

</script>

<style scoped>


</style>