<template>
  <ValidationForm v-if="item" v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,'save')">

      <div class="grid-container eco-container">

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell">
              <h4>{{ $t('game.score-sheet') }}</h4>
            </div>
          </div>

          <div class="grid-x">
            <div class="medium-12 cell">
              <div class="grid-container eco-grid-container">
                <div class="grid-x">
                  <div class="medium-4 cell">
                    <ToolTipLabel
                      :label="$t('game.trainer')"
                      :text="$t('tooltip.trainer')">
                    </ToolTipLabel>
                  </div>
                  <div class="medium-5 cell">
                    <Field v-model="item.trainer_name" :disabled="true" as="input"
                           name="trainer" type="text"></Field>
                    <ErrorMessage as="small" class="error" name="trainer"></ErrorMessage>
                  </div>
                </div>
                <div class="grid-x">
                  <div class="medium-4 cell">
                    <ToolTipLabel
                      :label="$t('game.institution')"
                      :text="$t('tooltip.institution')">
                    </ToolTipLabel>
                  </div>
                  <div class="medium-5 cell">
                    <Field v-model="item.institution_id" :disabled="isDisabled" as="select" name="institution">
                      <option disabled value="">{{ $t('institution.option') }}</option>
                      <option v-for="option in institutions" v-bind:key="option.value" v-bind:value="option.value">
                         {{ option.text }}
                      </option>
                    </Field>
                    <ErrorMessage as="small" class="error" name="institution"></ErrorMessage>
                  </div>
                </div>
                <div class="grid-x">
                  <div class="medium-4 cell">
                    <ToolTipLabel
                      :label="$t('game.organization')"
                      :text="$t('tooltip.organization')">
                    </ToolTipLabel>
                  </div>
                  <div class="medium-5 cell">
                    <Field v-model="item.organization_id" :disabled="isDisabled" as="select" name="organization">
                      <option disabled value="">{{ $t('institution.option') }}</option>
                      <option v-for="option in readOrganizations" v-bind:key="option" v-bind:value="option.organization_id">
                         {{ option.name }}
                      </option>
                    </Field>
                    <ErrorMessage as="small" class="error" name="organization"></ErrorMessage>
                  </div>
                </div>
                <div class="grid-x">
                  <div class="medium-4 cell">
                    <ToolTipLabel
                      :label="$t('game.location')"
                      :text="$t('tooltip.location')">
                    </ToolTipLabel>
                  </div>
                  <div class="medium-5 cell">
                    <Field v-model="item.location_id" :disabled="isDisabled" as="select" name="location">
                      <option disabled value="">{{ $t('location.option') }}</option>
                      <option v-for="option in locations" v-bind:key="option.value" v-bind:value="option.value">
                         {{ option.text }}
                      </option>
                    </Field>
                    <ErrorMessage as="small" class="error" name="location"></ErrorMessage>
                  </div>
                </div>
                <div class="grid-x">
                  <div class="medium-4 cell">
                    <ToolTipLabel
                      :label="$t('game.number-of-participants')"
                      :text="$t('tooltip.number-of-participants')">
                    </ToolTipLabel>
                  </div>
                  <div class="medium-5 cell">
                    <Field v-model="item.number_participants" name="numberParticipants">
                      <NumberField v-model="item.number_participants" :disabled="isDisabled"></NumberField>
                    </Field>
                    <ErrorMessage as="small" class="error" name="numberParticipants"></ErrorMessage>
                  </div>
                </div>
                <div class="grid-x">
                  <div class="medium-4 cell">
                    <ToolTipLabel
                      :label="$t('game.start-date')"
                      :text="$t('tooltip.start-date')">
                    </ToolTipLabel>
                  </div>
                  <div class="medium-5 cell">
                    <Field v-model="item.start_date" :disabled="isDisabled" as="input"
                           name="startDate" type="date"></Field>
                    <ErrorMessage as="small" class="error" name="startDate"></ErrorMessage>
                  </div>
                </div>
                <div class="grid-x">
                  <div class="medium-4 cell">
                    <ToolTipLabel
                      :label="$t('game.remarks')"
                      :text="$t('tooltip.game-remarks')">
                    </ToolTipLabel>
                  </div>
                  <div class="medium-10 cell">
                    <Field v-model="item.remark" :disabled="isDisabled" as="textarea" name="remarks"
                           rows="4" type="text"></Field>
                    <ErrorMessage as="small" class="error" name="remarks"></ErrorMessage>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>

    </form>
  </ValidationForm>

</template>

<script>

import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import NumberField from "../NumberField";
import ToolTipLabel from "../ToolTipLabel";

export default {
  name: "ScoresheetForm",
  components: {
    ValidationForm,
    Field,
    NumberField,
    ErrorMessage,
    ToolTipLabel
  },
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    license: {
      default: "",
      type: String
    },
  },
  data() {
    const schema = {
      institution: 'required',
      organization: 'required',
      location: 'required',
      numberParticipants: 'required',
      startDate: 'required'
    };
    return {
      schema,
      item: Object.assign({}, this.$store.state.games.scoresheet),
      license_key: this.license,
      institutions: [
        {text: this.$t('institution.school'), value: 1},
        {text: this.$t('institution.credit-institute'), value: 2},
        {text: this.$t('institution.university'), value: 3},
        {text: this.$t('institution.external-company'), value: 4},
        {text: this.$t('institution.other'), value: 5},
      ],
      locations: [
        {text: this.$t('location.credit-institute'), value: 1},
        {text: this.$t('location.school'), value: 2},
        {text: this.$t('location.university'), value: 3},
        {text: this.$t('location.external-company'), value: 4},
        {text: this.$t('location.online'), value: 5},
        {text: this.$t('location.other'), value: 6},
      ],
    };
  },
  methods: {
    save() {
      this.$store.commit('games/replaceScoresheet', this.item);
    },
    setLicense(license_key) {
      this.license_key = license_key;
    }
  },
  computed: {
    isDisabled() {
      return this.disabled ? true : false;
    },
    readOrganizations() {
      return this.$store.getters['games/licenseOrganizations'](this.license_key);
    }
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.games.scoresheet;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.games.scoresheet);
          if(this.item.trainer_name == undefined) {
            this.item.trainer_name = this.$store.state.account.account.first_name + ' ' + this.$store.state.account.account.last_name;
          }
        }
    );
    if (this.$store.state.games.scoresheet) {
      this.item = Object.assign({}, this.$store.state.games.scoresheet);
    }
  },
  unmounted() {
    this.unwatch();
  },
}

</script>

<style scoped>

</style>