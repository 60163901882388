<template>
  <div class="number-input">
    <input v-model="newValue" disabled type="number"/>
    <button :disabled="disabled" class="button spin-button" type="button" @click="clickDown"></button>
    <button :disabled="disabled" class="button plus spin-button" type="button" @click="clickUp"></button>
  </div>

</template>

<script>
import {useModelWrapper} from "../model-wrapper";

export default {
  name: "SpinFieldPlus",
  components: {},
  data() {
    return {
      minInputAllowed: undefined,
      maxInputAllowed: undefined,
    }
  },
  props: {
    modelValue: {
      default: 0,
      type: Number
    },
    min: {
      default: undefined,
      type: Number,
    },
    max: {
      default: undefined,
      type: Number
    },
    step: {
      default: 1,
      type: Number,
    },
    maxDistance: {
      default: undefined,
      type: Number
    },
    disabled: {
      default: false,
      type: Boolean
    },
  },
  setup(props, {emit}) {
    return {
      newValue: useModelWrapper(props, emit, 'modelValue'),
    }
  },
  methods: {
    clickDown() {
      if (this.newValue > this.min && this.newValue > this.minInputAllowed) {
        this.newValue -= this.step;
      }
    },
    clickUp() {
      if (this.max == undefined) {
        this.newValue += this.step;
      } else {
        if (this.newValue < this.max && this.newValue < this.maxInputAllowed) {
          this.newValue += this.step;
        }
      }
    }
  },
  mounted() {
    if (this.maxInputAllowed === undefined) {
      this.maxInputAllowed = this.max;

      if (this.maxDistance) {
        this.minInputAllowed = this.modelValue - this.maxDistance;
        this.maxInputAllowed = this.modelValue + this.maxDistance;
      }
    }
    if (this.minInputAllowed === undefined) {
      this.minInputAllowed = this.min;

      if (this.maxDistance) {
        this.minInputAllowed = this.modelValue - this.maxDistance;
        this.maxInputAllowed = this.modelValue + this.maxDistance;
      }
    }
  },
}

</script>

<style scoped>

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
  background-color: #a7a8a9;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input input[type=number] {
  font-family: OpenSans-Regular;
  min-width: 3rem;
  max-width: 3rem;
  /*border-width: 0 2px;*/
  font-size: 1rem;
  height: 2rem;
  font-weight: normal;
  text-align: center;
  border-radius: 0.3rem 0px 0px 0.3rem;
}

</style>