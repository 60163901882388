<template>
  <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">

      <TopFormBar :save-button="true" :title="$t('trainer-board.form-title')" class="eco-form-bar"/>

      <div class="grid-container eco-form-container">
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('account.user-name') }}
              <Field v-model="item.username" as="input" name="username" placeholder="e-mail" type="text" v-lowercase></Field>
              <ErrorMessage as="small" class="error" name="username"></ErrorMessage>
            </label>
          </div>

        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('account.first-name') }}
              <Field v-model="item.first_name" as="input" name="firstName" type="text"></Field>
              <ErrorMessage as="small" class="error" name="firstName"></ErrorMessage>
            </label>
          </div>
          <div class="medium-6 cell">
            <label>{{ $t('account.last-name') }}
              <Field v-model="item.last_name" as="input" name="lastName" type="text"></Field>
              <ErrorMessage as="small" class="error" name="lastName"></ErrorMessage>
            </label>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('account.license') }}
              <Field v-model="item.license_key" as="select" class="eco-select" name="licenseKey">
                <option v-for="option in licenses" v-bind:key="option" v-bind:value="option.license_key">
                  {{ option.license_holder }}
                </option>
              </Field>
              <ErrorMessage as="small" class="error" name="licenseKey"></ErrorMessage>

            </label>
          </div>
        </div>
        <div class="grid-x">
          <div class="medium-1 cell">
            <label class="text-left middle">{{ $t('account.enabled') }} </label>
          </div>
          <div class="medium-2 cell">
            <div class="switch-large">
              <input id="enabled-on-off" v-model="item.enabled" class="switch-input" type="checkbox">
              <label class="switch-paddle eco-switch-paddle" for="enabled-on-off">
                <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="grid-x">
          <div class="medium-1 cell">
            <label class="text-left middle">{{ $t('account.certified') }} </label>
          </div>
          <div class="medium-1 cell">
            <div class="switch-large">
              <input id="certfied-on-off" v-model="item.is_certified" class="switch-input"
                     type="checkbox">
              <label class="switch-paddle eco-switch-paddle" for="certfied-on-off">
                <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
              </label>
            </div>
          </div>
          <div class="grid-x">
            <div class="medium-2 cell">
              <label class="text-left middle">{{ $t('account.certified-at') }} </label>
            </div>
            <div class="medium-10 cell">
              <Field v-model="item.certified_at" :disabled="isDisabled" as="input"
                           name="certifiedAt" type="date"></Field>
              <ErrorMessage as="small" class="error" name="certifiedAt"></ErrorMessage>
            </div>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-12 cell">
             <label class="text-left middle">{{ $t('account.remarks') }}
                <Field v-model="item.remarks" as="textarea" name="remarks"
                       rows="10" type="text">
                </Field>
                <ErrorMessage as="small" class="error" name="remarks"></ErrorMessage>
              </label>
          </div>
        </div>

      </div>

    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from 'axios';
import TopFormBar from "../../TopFormBar";

export default {
  name: 'TrainerForm',
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
    TopFormBar,
  },
  data() {
    const schema = {
      username: 'required|email',
      firstName: 'required',
      lastName: 'required',
      licenseKey: 'required'
    };
    return {
      schema,
      item: Object.assign({}, this.$store.state.adminBoard.selectedTrainer),
      disabled: false
    };
  },
  computed: {
    licenses() {
      return this.$store.state.adminBoard.licenses;
    },
    isDisabled() {
      return !this.item.is_certified;
    },
  },
  methods: {
    save() {
      axios.post(url, {'action': 'save-trainer', 'item': this.item})
          .then(() => this.$router.push(url + '/trainers'))
          .catch((error) => alert(error.response.data.message));
    },
  },
};
</script>

<style scoped>

input[type=text], input[type=datetime-local],
input[type=date-local], input[type=password],
select, optgroup, textarea {
  border-radius: var(--border-radius);
  /*height: 2rem;*/
  font-size: 100%;
}

select option {
  background-color: white;
  color: gray;
}

</style>