<template>
  <slot/>
  <label class="text-left middle eco-tooltip-link">
    <a v-if="!disabled" href="javascript:void(0);" @mouseleave="mouseLeave" @mouseover="mouseEnter">{{ label }}</a>
    <span v-if="disabled">{{ label }}</span>

    <div v-if="hover" class="tooltip-box">
      <div class="tooltip">
        <span class="tooltip-text" v-html="tooltipText"></span>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hover: false,
      tooltipText: undefined
    };
  },
  methods: {
    mouseEnter() {
      this.tooltipText = this.text;
      this.hover = true;
    },
    mouseLeave() {
      this.tooltipText = "";
      this.hover = false;
    },
  }
};
</script>

<style scoped>

</style>