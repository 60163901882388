<template>
  <ValidationForm v-if="item" v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,saveParameter)">

      <TopFormBar :info-text="message" :info-type="messageType"
                  :save-button="!isDisabled"
                  :title="$t('parameter.economy-parameter')" class="eco-form-bar-second" style="width: 70%;"/>

      <div class="grid-container eco-container" style="width: 70%;">

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell col-margin">
              <div class="medium-12">
                <h4>{{ $t('parameter.product-quantity') }}</h4>
              </div>

              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.food') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_quantity_food"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.clothes') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_quantity_clothes"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.healthy') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_quantity_healthy"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.education') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_quantity_education"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.communication') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_quantity_communication"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.leisure') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_quantity_leisure"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.automobile') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_quantity_automobile"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.living') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_quantity_living"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>

            </div>

            <div class="medium-6 cell">
              <div class="medium-12">
                <h4>{{ $t('parameter.production-in-stock') }}</h4>
              </div>

              <div class="grid-x">
                <div class="medium-2 cell">
                  <div class="switch-large eco-switch-large">
                    <input id="food-in-stock-on-off" v-model="item.economy_parameter.product_in_stock_food" :disabled="true"
                           class="switch-input" type="checkbox">
                    <label class="switch-paddle eco-game-switch-paddle" for="food-in-stock-on-off">
                      <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                      <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-2 cell">
                  <div class="switch-large eco-switch-large">
                    <input id="clothes-in-stock-on-off" v-model="item.economy_parameter.product_in_stock_clothes" :disabled="isDisabled"
                           class="switch-input" type="checkbox">
                    <label class="switch-paddle eco-game-switch-paddle" for="clothes-in-stock-on-off">
                      <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                      <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-2 cell">
                  <div class="switch-large eco-switch-large">
                    <input id="healthy-in-stock-on-off" v-model="item.economy_parameter.product_in_stock_healthy" :disabled="true"
                           class="switch-input" type="checkbox">
                    <label class="switch-paddle eco-game-switch-paddle" for="healthy-in-stock-on-off">
                      <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                      <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-2 cell">
                  <div class="switch-large eco-switch-large">
                    <input id="education-in-stock-on-off" v-model="item.economy_parameter.product_in_stock_education" :disabled="true"
                           class="switch-input" type="checkbox">
                    <label class="switch-paddle eco-game-switch-paddle" for="education-in-stock-on-off">
                      <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                      <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-2 cell">
                  <div class="switch-large eco-switch-large">
                    <input id="communication-in-stock-on-off" v-model="item.economy_parameter.product_in_stock_communication" :disabled="isDisabled"
                           class="switch-input" type="checkbox">
                    <label class="switch-paddle eco-game-switch-paddle" for="communication-in-stock-on-off">
                      <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                      <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-2 cell">
                  <div class="switch-large eco-switch-large">
                    <input id="leisure-in-stock-on-off" v-model="item.economy_parameter.product_in_stock_leisure" :disabled="true"
                           class="switch-input" type="checkbox">
                    <label class="switch-paddle eco-game-switch-paddle" for="leisure-in-stock-on-off">
                      <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                      <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-2 cell">
                  <div class="switch-large eco-switch-large">
                    <input id="automobile-in-stock-on-off" v-model="item.economy_parameter.product_in_stock_automobile" :disabled="isDisabled"
                           class="switch-input" type="checkbox">
                    <label class="switch-paddle eco-game-switch-paddle" for="automobile-in-stock-on-off">
                      <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                      <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-2 cell">
                  <div class="switch-large eco-switch-large">
                    <input id="living-in-stock-on-off" v-model="item.economy_parameter.product_in_stock_living" :disabled="isDisabled"
                           class="switch-input" type="checkbox">
                    <label class="switch-paddle eco-game-switch-paddle" for="living-in-stock-on-off">
                      <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                      <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
                    </label>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell col-margin">
              <div class="medium-12">
                <h4>{{ $t('parameter.product-price') }}</h4>
              </div>

              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.food') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_price_food"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.clothes') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_price_clothes"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.healthy') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_price_healthy"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.education') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_price_education"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.communication') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_price_communication"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.leisure') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_price_leisure"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.automobile') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_price_automobile"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.living') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_price_living"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.automobile-costs') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_price_automobile_costs"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.living-costs') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.product_price_living_costs"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
            </div>

          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell col-margin">
              <h4>{{ $t('parameter.investment-costs') }}</h4>

              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.food') }}</label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_costs_food" name="investmentCostsFood">
                    <NumberField v-model="item.economy_parameter.investment_costs_food"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="investmentCostsFood"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.clothes') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_costs_clothes" name="investmentCostsClothes">
                    <NumberField v-model="item.economy_parameter.investment_costs_clothes"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="investmentCostsClothes"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.healthy') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_costs_healthy" name="investmentCostsHealthy">
                    <NumberField v-model="item.economy_parameter.investment_costs_healthy"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="investmentCostsHealthy"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.education') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_costs_education" name="investmentCostsEducation">
                    <NumberField v-model="item.economy_parameter.investment_costs_education"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="investmentCostsEducation"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.communication') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_costs_communication"
                         name="investmentCostsCommunication">
                    <NumberField v-model="item.economy_parameter.investment_costs_communication"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="investmentCostsCommunication"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.leisure') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_costs_leisure" name="investmentCostsLeisure">
                    <NumberField v-model="item.economy_parameter.investment_costs_leisure"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="investmentCostsLeisure"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.automobile') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_costs_automobile" name="investmentCostsAutomobile">
                    <NumberField v-model="item.economy_parameter.investment_costs_automobile"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="investmentCostsAutomobile"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.living') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_costs_living" name="investmentCostsLiving">
                    <NumberField v-model="item.economy_parameter.investment_costs_living"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="investmentCostsLiving"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.automobile-costs') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_costs_automobile_costs"
                         name="investmentCostsAutomobileCosts">
                    <NumberField v-model="item.economy_parameter.investment_costs_automobile_costs"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="investmentCostsAutomobileCosts"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.living-costs') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_costs_living_costs"
                         name="investmentCostsLivingCosts">
                    <NumberField v-model="item.economy_parameter.investment_costs_living_costs"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="investmentCostsLivingCosts"></ErrorMessage>
                </div>
              </div>
            </div>

            <div class="medium-6 cell col-margin">
              <h4>{{ $t('parameter.investments') }}</h4>

              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('parameter.investment-rate') }} (%) </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.investment_rate" :disabled="isDisabled" as="input"
                         name="investmentRate" type="number"></Field>
                  <ErrorMessage as="small" class="error" name="investmentRate"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('parameter.alternative-investment') }} (%) </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.alternative_investment" :disabled="isDisabled" as="input"
                         name="alternativeInvestment" type="number"></Field>
                  <ErrorMessage as="small" class="error" name="alternativeInvestment"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('parameter.modernization') }} (%) </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.modernization" :disabled="isDisabled" as="input"
                         name="modernization" type="number"></Field>
                  <ErrorMessage as="small" class="error" name="modernization"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('parameter.amortization-rate') }} (%) </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.amortization_rate" :disabled="isDisabled" as="input"
                         name="amortizationRate" type="number"></Field>
                  <ErrorMessage as="small" class="error" name="amortizationRate"></ErrorMessage>
                </div>
              </div>

            </div>

          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell col-margin">
              <div class="medium-12">
                <h4>{{ $t('parameter.division-of-labour') }}</h4>
              </div>

              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.food') }} </label>
                </div>
                <div class="medium-2 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.division_of_labour_food"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.clothes') }} </label>
                </div>
                <div class="medium-2 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.division_of_labour_clothes"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.healthy') }} </label>
                </div>
                <div class="medium-2 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.division_of_labour_healthy"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.education') }} </label>
                </div>
                <div class="medium-2 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.division_of_labour_education"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.communication') }} </label>
                </div>
                <div class="medium-2 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.division_of_labour_communication"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.leisure') }} </label>
                </div>
                <div class="medium-2 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.division_of_labour_leisure"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.automobile') }} </label>
                </div>
                <div class="medium-2 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.division_of_labour_automobile"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.living') }} </label>
                </div>
                <div class="medium-2 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.division_of_labour_living"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.automobile-costs') }} </label>
                </div>
                <div class="medium-2 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.division_of_labour_automobile_costs"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('category.living-costs') }} </label>
                </div>
                <div class="medium-2 cell">
                  <SpinFieldPlus v-model="item.economy_parameter.division_of_labour_living_costs"
                                 :disabled="isDisabled" min="0"/>
                </div>
              </div>

            </div>

            <div class="medium-6 cell col-margin">
              <h4>{{ $t('parameter.staff') }}</h4>

              <div class="grid-x">
                <div class="medium-7 cell">
                  <label class="text-left middle">{{ $t('parameter.gross-earnings') }} </label>
                </div>
                <div class="medium-4 cell">
                  <Field v-model="item.economy_parameter.staff_gross_earnings" name="staffGrossEarnings">
                    <NumberField v-model="item.economy_parameter.staff_gross_earnings"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="staffGrossEarnings"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-7 cell">
                  <label class="text-left middle">{{ $t('parameter.overtime-premium') }} (%) </label>
                </div>
                <div class="medium-4 cell">
                  <Field v-model="item.economy_parameter.staff_overtime_premium" :disabled="isDisabled" as="input"
                         name="staffOvertimePremium" type="number"></Field>
                  <ErrorMessage as="small" class="error" name="staffOvertimePremium"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-7 cell">
                  <label class="text-left middle">{{ $t('parameter.synergy-effects') }} (%) </label>
                </div>
                <div class="medium-4 cell">
                  <Field v-model="item.economy_parameter.staff_synergy_effects" :disabled="isDisabled" as="input"
                         name="staffSynergyEffects" type="number"></Field>
                  <ErrorMessage as="small" class="error" name="staffSynergyEffects"></ErrorMessage>
                </div>
              </div>

            </div>
          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell col-margin">
              <div class="medium-12">
                <h4>{{ $t('parameter.time-deposits') }}</h4>
              </div>

              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('parameter.time-deposit-volume') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.time_deposit_volume" name="timeDepositVolume">
                    <NumberField v-model="item.economy_parameter.time_deposit_volume"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="timeDepositVolume"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('parameter.time-deposit-increase') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.time_deposit_increase" name="timeDepositIncrease">
                    <NumberField v-model="item.economy_parameter.time_deposit_increase"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="timeDepositIncrease"></ErrorMessage>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('parameter.time-deposit-decrease') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.time_deposit_decrease" name="timeDepositDecrease">
                    <NumberField v-model="item.economy_parameter.time_deposit_decrease"
                                 :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="timeDepositDecrease"></ErrorMessage>
                </div>
              </div>

            </div>

            <div class="medium-6 cell col-margin">
              <div class="medium-12">
                <h4>{{ $t('parameter.credit-liability') }}</h4>
              </div>

              <div class="grid-x">
                <div class="medium-6 cell">
                  <label class="text-left middle">{{ $t('parameter.credit-liability') }} </label>
                </div>
                <div class="medium-5 cell">
                  <Field v-model="item.economy_parameter.liability" name="liability">
                    <NumberField v-model="item.economy_parameter.liability" :disabled="isDisabled"></NumberField>
                  </Field>
                  <ErrorMessage as="small" class="error" name="liability"></ErrorMessage>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from "vee-validate";
import axios from "axios";
import TopFormBar from "../../TopFormBar";
import SpinFieldPlus from "../../SpinFieldPlus";
import NumberField from "../../NumberField";

export default {
  name: "EconomyParameterForm",
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
    TopFormBar,
    SpinFieldPlus,
    NumberField
  },
  props: {
    disabled: {
      default: true,
      type: Boolean
    },
  },
  data() {
    const schema = {
      // productQuantityFood: 'required',
      // productQuantityClothes: 'required',
      // productQuantityHealthy: 'required',
      // productQuantityEducation: 'required',
      // productQuantityCommunication: 'required',
      // productQuantityLeisure: 'required',
      // productQuantityAutomobile: 'required',
      // productQuantityLiving: 'required',

      // productPriceFood: 'required',
      // productPriceClothes: 'required',
      // productPriceHealthy: 'required',
      // productPriceEducation: 'required',
      // productPriceCommunication: 'required',
      // productPriceLeisure: 'required',
      // productPriceAutomobile: 'required',
      // productPriceLiving: 'required',
      // productPriceAutomobileCosts: 'required',
      // productPriceLivingCosts: 'required',

      // divisionOfLabourFood: 'required',
      // divisionOfLabourClothes: 'required',
      // divisionOfLabourHealthy: 'required',
      // divisionOfLabourEducation: 'required',
      // divisionOfLabourCommunication: 'required',
      // divisionOfLabourLeisure: 'required',
      // divisionOfLabourAutomobile: 'required',
      // divisionOfLabourLiving: 'required',
      // divisionOfLabourAutomobileCosts: 'required',
      // divisionOfLabourLivingCosts: 'required',

      investmentCostsFood: 'required',
      investmentCostsClothes: 'required',
      investmentCostsHealthy: 'required',
      investmentCostsEducation: 'required',
      investmentCostsCommunication: 'required',
      investmentCostsLeisure: 'required',
      investmentCostsAutomobile: 'required',
      investmentCostsLiving: 'required',
      investmentCostsAutomobileCosts: 'required',
      investmentCostsLivingCosts: 'required',

      staffGrossEarnings: 'required',
      staffOvertimePremium: 'required',
      staffSynergyEffects: 'required',

      timeDepositVolume: 'required',
      timeDepositIncrease: 'required',
      timeDepositDecrease: 'required',

      liability: 'required',

      investmentRate: 'required',
      alternativeInvestment: 'required',
      modernization: 'required',
      amortizationRate: 'required',

    };
    return {
      schema,
      item: undefined,
      message: "",
      messageType: undefined,
    };
  },
  computed: {
    isDisabled() {
      return this.disabled ? true : false;
    }
  },
  methods: {
    saveParameter() {
      this.$store.commit('economyParameter/replaceEconomyParameter', this.item);
      axios.post(url, {
        'action': 'save-economy-parameter',
        'economy-parameter': this.$store.state.economyParameter.economyParameter,
      })
          .then(() => {
            if (url.includes("admin-board")) {
              this.$router.push(url + '/parameter-set/edit');
            } else {
              this.$router.push(url + '/game/edit');
            }
            this.showMessage(this.$t('messages.save-successful'), this.$MSG_TYPE_SUCCESS);
          })
          .catch((error) => alert(error.response.data.message));
    },
    showMessage(msg, type = 0) {
      this.message = msg;
      this.messageType = type;
      setTimeout(() => {
        this.message = "";
      }, 2000);
    }
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.economyParameter.economyParameter;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.economyParameter.economyParameter);
        }
    );
    if (this.$store.state.economyParameter.economyParameter) {
      this.item = Object.assign({}, this.$store.state.economyParameter.economyParameter);
    }
  },
  unmounted() {
    this.unwatch();
  }
}
</script>

<style scoped>

.switch-large {
  height: 2.70rem;
}


</style>