<template>
  <div id="login" class="grid-x align-center eco-login">
    <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div"
          class="cell eco-login-form">
      <form @submit="handleSubmit($event,onSubmit)">

        <h4 class="text-center">
          <div>
            <span class="eco-logotext-first">ECO</span>
            <span class="eco-logotext-second">MANIA</span>
          </div>
        </h4>
        <label>{{ $t('login.language') }}
          <select v-model="$i18n.locale">
            <option disabled value="">{{ $t('login.language-option') }}</option>
            <option v-for="language in supportedLanguages" :key="language.code" :value="language.code">
              {{ language.name }}
            </option>
          </select>
        </label>
        <label>{{ $t('login.username') }}
          <Field v-model="username" as="input" name="username" placeholder="example@email.com"
                 type="text"></Field>
          <ErrorMessage as="small" class="error" name="username"></ErrorMessage>
        </label>
        <label>{{ $t('login.password') }}
          <Field v-model="password" as="input" name="password" placeholder="password"
                 type="password"></Field>
          <ErrorMessage as="small" class="error" name="password"></ErrorMessage>
        </label>
        <p>
          <button id="eco-login-button" class="button warning expanded eco-login-button" type="submit">
            {{ $t('login.signin') }}
          </button>
          <span class="error">{{ message }}</span>
        </p>

        <p class="forgot-password text-right">
          <a :href="$router.resolve({name: 'PasswordReset'}).href">{{ $t('login.reset-password') }}</a>
        </p>
      </form>
    </ValidationForm>
    <div class="footer-copyright">
      &copy; 2021-2024 IDUS-Software GmbH, alle Rechte vorbehalten.
    </div>
  </div>

</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from "axios";

var data = {
  message: null,
  username: null,
  password: null,
  supportedLanguages:
      [
        {
          code: 'de',
          name: "German"
        },
        {
          code: 'en',
          name: "English"
        },
      ],
};

export default {
  name: 'Login',
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = {
      username: 'required',
      password: 'required',
    };
    data.schema = schema;
    this.$i18n.locale = 'de';
    return data;
  },

  methods: {
    isRequired(value) {
      if (!value) {
        return 'This field is required';
      }
      return true;
    },
    onSubmit() {
      const payload = data;
      payload.language = this.$i18n.locale;

      axios.post(url, data = payload)
          .then(() => this.$router.go(0))
          .catch((error) => this.message = error.response.data.message);
    },
  }
};
</script>

<style lang="scss" scoped>

.eco-login {
  min-width: 50vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border: 2px solid #c8c8c8;
  border-radius: 0.3rem;
}

.eco-login-form {
  max-width: 50%;
}

.eco-login h4 {
  margin-bottom: 1rem;
}

.eco-login .orbit {
  background-color: #e1e1e1;
  margin: 0px;
}

.eco-login-button {
  background-color: #008cb9;
  margin: 0px;
  border-radius: 0.3rem;

  &:hover {
    color: #ffffff;
  }

  &:active {
    color: #313a45;
    transform: translateY(2px);
  }
}

.eco-logotext-first {
  color: #008cb9;
  font-weight: bold;
  font-size: 3rem;
}

.eco-logotext-second {
  color: #8a8a8a;
  font-weight: bold;
  font-size: 3rem;
}

</style>