import {createApp} from 'vue';
import i18n from './i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import './validation';


axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

const app = createApp(App).use(i18n).use(store).use(router);

app.config.globalProperties.$MSG_TYPE_SUCCESS = 0;
app.config.globalProperties.$MSG_TYPE_WARNING = 1;
app.config.globalProperties.$MSG_TYPE_ERROR = 2;
app.config.globalProperties.$MSG_TYPE_DEFAULT = 3;

app.directive('lowercase', {
    updated(el){
        let startPosition = el.selectionStart
        let endPosition = el.selectionEnd
        el.value = el.value.toLowerCase()
        el.setSelectionRange(startPosition, endPosition)
    }
})
app.mount('#app');