<template>
  <div class="grid-container">
    <div class="top-bar eco-form-top-bar">
      <div class="top-bar-left">
        <ul class="menu">
          <li class="menu-text"> {{ title }}</li>
        </ul>
      </div>
      <div v-if="showMessage" class="top-bar-title eco-top-bar-message hideSmallMedia"
           v-bind:class="infoTextClass">{{ infoText }}
      </div>

      <div class="top-bar-right">
        <ul class="menu">
          <li v-if="checkButton">
            <button class="button primary float-right eco-button eco-group-button" type="submit"><i
                class="fas fa-check"></i></button>
          </li>
          <div v-if="!hideSaveButton">
            <li v-if="saveButton">
              <button class="button primary float-right eco-button eco-group-button" type="submit"><i
                  class="fas fa-save"></i></button>
            </li>
          </div>
          <li v-if="refreshButton">
            <button class="button primary float-right eco-button eco-group-button" type="submit"><i
                class="fas fa-undo-alt"></i></button>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TopFormBar",

  props: {
    title: {
      default: undefined,
      type: String
    },
    saveButton: {
      default: false,
      type: Boolean
    },
    checkButton: {
      default: false,
      type: Boolean
    },
    refreshButton: {
      default: false,
      type: Boolean
    },
    infoText: {
      default: "",
      type: String
    },
    infoType: {
      default: undefined,
      type: Number
    },
  },
  data() {
    return {
      hideSaveButton: false
    };
  },
  computed: {
    showMessage() {
      return this.infoText.length > 0 ? true : false;
    },
    infoTextClass() {
      switch (this.infoType) {
        case this.$MSG_TYPE_SUCCESS:
          return "eco-info-color-success";
        case this.$MSG_TYPE_WARNING:
          return "eco-info-color-warning";
        case this.$MSG_TYPE_ERROR:
          return "eco-info-color-error";
      }
      return "eco-info-color-default";
    },
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.loadingProgress.isLoading;
        },
        () => {
          this.hideSaveButton = this.$store.state.loadingProgress.isLoading;
        }
    );
  }
}
</script>

<style scoped>

</style>