import {defineRule} from "vee-validate";
import {between, confirmed, email, required} from "@vee-validate/rules";


defineRule('required', required);
defineRule('email', email);
defineRule('confirmed', confirmed);
defineRule('between', between);

defineRule("integer_positive", (value) => {
    if (value >= 0) {
        return true;
    }
});