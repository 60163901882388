r<template>
  <div class="grid-container fluid eco-panel-container" data-equalizer data-equalizer-mq="large-up">

    <div class="grid-x ">
       <table>
          <thead class="eco-row-header">
            <tr>
              <th style="width: 30%;" colspan="3">{{ $t('game.round') }}</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
              <th>7</th>
              <th>8</th>
              <th>9</th>
              <th>10</th>
              <th>11</th>
              <th>12</th>
              <th>13</th>
              <th>14</th>
              <th>15</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="row in rowsHousehold" v-bind:key="row" class="eco-table-history">
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[0]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[1]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[2]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[3] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[4] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[5] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[6] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[7] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[8] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[9] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[10] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[11] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[12] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[13] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[14] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[15] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[16] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[17] }} </td>
          </tr>

          <tr v-for="row in rowsEconomy" v-bind:key="row" class="eco-table-history">
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[0]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[1]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[2]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[3] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[4] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[5] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[6] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[7] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[8] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[9] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[10] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[11] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[12] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[13] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[14] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[15] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[16] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[17] }} </td>
          </tr>
          <tr v-for="row in rowsGovernment" v-bind:key="row" class="eco-table-history">
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[0]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[1]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[2]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[3] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[4] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[5] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[6] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[7] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[8] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[9] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[10] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[11] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[12] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[13] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[14] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[15] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[16] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[17] }} </td>
          </tr>
          <tr v-for="row in rowsCommon" v-bind:key="row" class="eco-table-history">
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[0]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[1]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ $t(row[2]) }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[3] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[4] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[5] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[6] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[7] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[8] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[9] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[10] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[11] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[12] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[13] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[14] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[15] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[16] }} </td>
            <td :class="{'eco-table-history-first-row':row[0] != ''}">{{ row[17] }} </td>
          </tr>
          </tbody>
       </table>

      <div class="medium-12 columns eco-chart-panel" data-equalizer-watch>
        <div cell class="medium-12">
          <div class="eco-grid-container">

          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "HistoryForm",

  data() {
    return {
      chartCalculated: false,
    };
  },
  computed: {
    rowsHousehold() {
      return this.$store.state.history.history["history_household"];
    },
    rowsEconomy() {
      return this.$store.state.history.history["history_economy"];
    },
    rowsGovernment() {
      return this.$store.state.history.history["history_government"];
    },
    rowsCommon() {
      return this.$store.state.history.history["history_common"];
    },
  },
  methods: {
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.statistics.gameChartCalculated;
        },
        () => {
          this.chartCalculated = this.$store.state.statistics.gameChartCalculated;
        }
    );
  },
  unmounted() {
    this.unwatch();
  },
}
</script>

<style scoped>

</style>