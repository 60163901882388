<template>
  <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">

      <TopFormBar :save-button="!isSaveDisabled" :title="$t('parameter-set.form-title')" class="eco-form-bar"/>

      <div class="grid-container eco-form-container">
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('parameter-set.name') }}
              <Field v-model="item.name" :disabled="isSaveDisabled" as="input" name="name" type="text"></Field>
              <ErrorMessage as="small" class="error" name="name"></ErrorMessage>
            </label>
          </div>
        </div>

        <ParameterNavigationBar></ParameterNavigationBar>
      </div>

    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from 'axios';
import ParameterNavigationBar from './ParameterNavigationBar';
import TopFormBar from "../../TopFormBar";

export default {
  name: "ParameterSetForm",
  components: {
    ParameterNavigationBar,
    TopFormBar,
    ValidationForm,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = {
      name: 'required',
    };
    return {
      schema,
      item: Object.assign({}, this.$store.state.parameterSets.selectedParameterSet),
    };
  },
  methods: {
    save() {
      axios.post(url, {
        'action': 'save-parameter-set',
        'parameter-set': this.item,
        'common-parameter': this.$store.state.commonParameter.commonParameter,
        'government-parameter': this.$store.state.governmentParameter.governmentParameter,
        'household-parameter': this.$store.state.householdParameter.householdParameter,
        'economy-parameter': this.$store.state.economyParameter.economyParameter,
      })
          .then(() => this.$router.push(url + '/parameter-set'))
          .catch((error) => alert(error.response.data.message));
    },
  },
  computed: {
    isSaveDisabled() {
      return this.item.name.length > 0 ? true : false;
    }
  }
}
</script>

<style scoped>

input[type="text"]:focus {
  background: #d9d8d6;
}

</style>