<template>
  <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">

      <div class="grid-container fluid eco-panel-container" data-equalizer data-equalizer-mq="large-up">

        <div class="grid-x ">
          <div class="medium-4 columns eco-budget-panel" data-equalizer-watch>

            <div cell class="medium-12">
              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <h4>{{ $t('government.revenue-side') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.household-tax') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.tax_household"
                                 disabled name="householdTax"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.economy-tax') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.tax_economy"
                                 disabled name="economyTax"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.sales-tax') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.sales_tax_economy"
                                 disabled name="salesTax"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.corporate-tax') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.corporate_tax"
                                 disabled name="corporateTax"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.interest-income') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.interest_income"
                                 disabled name="interestIncome"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('government.sum-total-revenue') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.income_government"
                                 disabled name="sumTotalRevenue"></NumberField>
                  </div>
                </div>
              </div>

              <!-- empty row>
              <div-- class="grid-x">
                <div class="medium-7 cell">
                   <label class="text-left middle"></label>
                </div>
              </div-->

              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <h4>{{ $t('government.costs') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.pension-payments') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.pension_payments"
                                 disabled name="pensionPayments"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.unemployment-benefit') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.unemployment_payments"
                                 disabled name="unemploymentBenefit"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.officials-payments') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.official_payments"
                                 disabled name="officialPayments"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.household-support') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.state_support_household"
                                 disabled name="householdSupport"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.business-support') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.state_support_economy"
                                 disabled name="businessSupport"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.public-consumption') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.public_consumption"
                                 disabled name="publicConsumption"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.interest-costs') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.interest_costs"
                                 disabled name="interestCosts"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('government.sum-total-costs') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.total_costs"
                                 disabled name="sumTotalCosts"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle  eco-style-bold">{{ $t('government.budget-result') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.budget_result"
                                 disabled name="budgetResult"></NumberField>
                  </div>
                </div>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle  eco-style-bold">{{ $t('government.budget-result-finance') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.budget_result_plus_financial_service"
                                 disabled name="budgetResultFinance"></NumberField>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="medium-4 columns eco-budget-panel" data-equalizer-watch>
            <div cell class="medium-12">
              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-12 cell">
                    <h4>{{ $t('government.government-dept') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.government-dept-begin') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.public_dept_begin"
                                 disabled name="governmentdeptBegin"></NumberField>
                  </div>
                </div>

                <!--div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.interest-result') }}</label>
                  </div>
                  <div class="medium-5 cell">
                      <NumberField name="interestResult"
                             v-model="item.interest_result" disabled></NumberField>
                  </div>
                </div-->

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.budget-result') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.budget_result"
                                 disabled name="budgetResult"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.bond-payments') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.bond_payments"
                                 disabled name="bondPayments"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('government.government-dept-end') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.public_dept_end"
                                 disabled name="governmentDeptEnd"></NumberField>
                  </div>
                </div>
              </div>
              <!-- empty row >
              <div-- class="grid-x">
                <div class="medium-7 cell">
                   <label class="text-left middle"></label>
                </div>
              </div-->

              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-12 cell">
                    <h4>{{ $t('government.budget-figures') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.total-dept') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <Field v-model="item.total_budget_result_pct" as="input" disabled
                           name="totalDept" type="number"></Field>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('government.budget-result') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <Field v-model="item.budget_result_pct" as="input" disabled
                           name="budgetResult" type="number"></Field>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </ValidationForm>
</template>

<script>
import {Field, Form as ValidationForm} from 'vee-validate';
import NumberField from "../../NumberField";

export default {
  name: "GovernmentdBudgetAccount",
  components: {
    NumberField,
    ValidationForm,
    Field,
  },
  data() {
    const schema = {};
    return {
      schema,
      item: {},
    };
  },
  methods: {},
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.governmentBudgetAccount.governmentBudgetAccount;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.governmentBudgetAccount.governmentBudgetAccount);
        }
    );
    if (this.$store.state.government) {
      this.item = Object.assign({}, this.$store.state.governmentBudgetAccount.governmentBudgetAccount);
    }
  },
  unmounted() {
    this.unwatch();
  },
}
</script>

<style scoped>


</style>