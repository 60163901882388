<template>
  <board-layout>
    <template v-slot:nav>
      <li>
        <a class="button clear secondary" @click="goToLicenseView">{{ $t('menu.licenses') }}</a>
      </li>
    </template>

    <template v-slot:page-title>
      {{ $t("license-board.page-title") }}
    </template>
  </board-layout>
</template>

<script>
/*global url*/
import BoardLayout from '../components/BoardLayout';

export default {
  name: 'LicenseBoard',
  components: {BoardLayout},
  methods: {
    goToLicenseView() {
      this.$router.push(url);
      this.$store.commit('sidenav/closeSidenav');
    },
  },
  mounted() {
    this.$store.commit('theme/setThemeBackgroundClass', 'bg-license');
    this.$store.commit('theme/setThemeColorClass', 'color-license');
  }
};
</script>

<style scoped>

</style>