import {createStore} from 'vuex';
import axios from "axios";

/*global url assetsPreURL*/

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        theme: {
            namespaced: true,
            state() {
                return {
                    themeClass: sessionStorage.getItem('theme'),
                    themeBackgroundClass: null,
                    themeColorClass: null,
                    assetsPreURL: assetsPreURL,
                }
            },
            mutations: {
                setThemeClass(state, value) {
                    state.themeClass = value;
                    sessionStorage.setItem('theme', value);
                },
                setThemeBackgroundClass(state, value) {
                    state.themeBackgroundClass = value;
                },
                setThemeColorClass(state, value) {
                    state.themeColorClass = value;
                }
            },
            getters: {
                isDefaultTheme(state) {
                    return state.themeClass === "defaultTheme"
                }
            }
        },
        sidenav: {
            namespaced: true,
            state() {
                return {
                    isSidenavOpen: false,
                };
            },
            mutations: {
                openSidenav(state) {
                    state.isSidenavOpen = true;
                },
                closeSidenav(state) {
                    state.isSidenavOpen = false;
                },
            }
        },
        account: {
            namespaced: true,
            state() {
                return {
                    account: {},
                };
            },
            mutations: {
                replace(state, item) {
                    state.account = item;
                }
            },
            actions: {
                reload(context) {
                    axios.post(url, {'action': 'fetch-account'})
                        .then(response => context.commit('replace', response.data.account))
                        .catch((error) => alert(error.response.data.message));
                }

            },
        },
        licenseBoard: {
            namespaced: true,
            state() {
                return {
                    licenses: [],
                    selectedLicense: {},
                };
            },
            mutations: {
                replace(state, items) {
                    state.licenses = items;
                },
                setSelectedLicense(state, item) {
                    state.selectedLicense = item;
                },
                clearSelectedLicense(state) {
                    state.selectedLicense = {};
                }
            },
            actions: {
                reloadLicenses(context) {
                    axios.post(url, {'action': 'fetch'})
                        .then(response => context.commit('replace', response.data.licenses))
                        .catch((error) => alert(error.response.data.message));
                }
            }

        },
        explorer: {
            namespaced: true,
            state() {
                return {
                    files: [],
                };
            },
            mutations: {
                replace(state, items) {
                    state.files = items;
                },
            },
            actions: {
                reload(context) {
                    axios.post(url, {'action': 'fetch-files'})
                        .then(response => context.commit('replace', response.data.files))
                        .catch((error) => alert(error.response.data.message));
                }
            }

        },
        adminBoard: {
            namespaced: true,
            state() {
                return {
                    licenses: [],
                    admins: [],
                    trainers: [],
                    selectedAdmin: {},
                    selectedTrainer: {},
                    websitePermission: false,
                    websiteFilePermission: false,
                };
            },
            mutations: {
                replaceLicenses(state, items) {
                    state.licenses = items;
                },
                replaceAdmins(state, items) {
                    state.admins = items;
                },
                setSelectedAdmin(state, item) {
                    state.selectedAdmin = item;
                },
                clearSelectedAdmin(state) {
                    state.selectedAdmin = {};
                },
                replaceTrainers(state, items) {
                    state.trainers = items;
                },
                setSelectedTrainer(state, item) {
                    state.selectedTrainer = item;
                },
                clearSelectedTrainer(state) {
                    state.selectedTrainer = {};
                },
                replaceWebsitePermission(state, item) {
                    state.websitePermission = item["website-admin"];
                    state.websiteFilePermission = item["website-file-admin"];
                }

            },
            actions: {
                reloadAdmins(context) {
                    axios.post(url, {'action': 'fetch-admins'})
                        .then(response => context.commit('replaceAdmins', response.data.admins))
                        .catch((error) => alert(error.response.data.message));
                },
                reloadLicenses(context) {
                    axios.post(url, {'action': 'fetch-licenses'})
                        .then(response => context.commit('replaceLicenses', response.data.licenses))
                        .catch((error) => alert(error.response.data.message));
                },
                reloadTrainers(context) {
                    axios.post(url, {'action': 'fetch-trainers'})
                        .then(response => context.commit('replaceTrainers', response.data.trainers))
                        .catch((error) => alert(error.response.data.message));
                },
                readWebSitePermission(context) {
                    axios.post(url, {'action': 'fetch-website-permission'})
                        .then(response => context.commit('replaceWebsitePermission', response.data.websitePermission))
                        .catch((error) => alert(error.response.data.message));
                },
            }
        },
        trainerBoard: {
            namespaced: true,
            state() {
                return {
                    trainer: null,
                    players: [],
                    selectedPlayer: {},
                };
            },
            mutations: {
                replacePlayers(state, items) {
                    state.players = items;
                    state.trainer = items[0];
                },
                setSelectedPlayer(state, item) {
                    state.selectedPlayer = item;
                },
            },
            actions: {
                reloadPlayers(context) {
                    axios.post(url, {'action': 'fetch-players'})
                        .then(response => context.commit('replacePlayers', response.data.players))
                        .catch((error) => alert(error.response.data.message));
                },
            }
        },
        playground: {
            namespaced: true,
            state() {
                return {
                    playerRole: null,
                    activeGame: {},
                    lastGameRound: {},
                    gameRound: null,
                    gameInputStates: [],
                    inputStateHousehold: {is_input_closed: false, is_input_trainer_reset: false},
                    inputStateEconomy: {is_input_closed: false, is_input_trainer_reset: false},
                    inputStateGovernment: {is_input_closed: false, is_input_trainer_reset: false},
                    simulationMode: false,
                };
            },
            mutations: {
                setPlayerRole(state, role) {
                    state.playerRole = role;
                },
                setActiveGame(state, item) {
                    state.activeGame = item;
                },
                setLastGameRound(state, item) {
                    state.lastGameRound = item;
                },
                setGameRound(state, item) {
                    state.gameRound = item;
                },
                replaceInputStates(state, items) {
                    state.gameInputStates = items;
                    state.inputStateHousehold = items[0];
                    state.inputStateEconomy = items[1];
                    state.inputStateGovernment = items[2];
                },
                resetStateHousehold(state, item) {
                    state.inputStateHousehold.is_input_closed = item;
                    state.inputStateHousehold.is_input_trainer_reset = true;
                    state.lastGameRound.is_synchronized = true;
                },
                resetStateEconomy(state, item) {
                    state.inputStateEconomy.is_input_closed = item;
                    state.inputStateEconomy.is_input_trainer_reset = true;
                    state.lastGameRound.is_synchronized = true;
                },
                resetStateGovernment(state, item) {
                    state.inputStateGovernment.is_input_closed = item;
                    state.inputStateGovernment.is_input_trainer_reset = true;
                    state.lastGameRound.is_synchronized = true;
                },
                setSimulationMode(state, item) {
                    state.simulationMode = item;
                },

            },
            actions: {
                fetchActiveGame(context) {
                    return axios.post(url, {'action': 'fetch-active-game'})
                        .then(response => {
                            context.commit('setActiveGame', response.data.game);
                            context.commit('setLastGameRound', response.data.lastGameRound);
                            context.commit('setPlayerRole', response.data.playerRole);
                            context.commit('setGameRound', response.data.gameRound);
                        })
                        .catch((error) => alert(error.response.data.message));
                },
                fetchDataInput(context) {
                    return axios.post(url, {'action': 'fetch-data-input'})
                        .then(response => {
                            context.commit('dataInputCommon/replaceDataInputCommon', response.data.dataInputCommon, {root: true});
                            context.commit('dataInputGovernment/replaceDataInputGovernment', response.data.dataInputGovernment, {root: true});
                            context.commit('dataInputEconomy/replaceDataInputEconomy', response.data.dataInputEconomy, {root: true});
                            context.commit('dataInputHousehold/replaceDataInputHousehold', response.data.dataInputHousehold, {root: true});
                            context.commit('replaceInputStates', response.data.gameInputStates);
                        })
                        .catch((error) => alert(error.response.data.message));
                },
            },
            getters: {
                isTrainer(state) {
                    return state.playerRole === 'trainer';
                },
                isEconomy(state) {
                    return state.playerRole === 'economy';
                },
                isHousehold(state) {
                    return state.playerRole === 'household';
                },
                isGovernment(state) {
                    return state.playerRole === 'government';
                },
                inputHouseholdClosed(state) {
                    return state.inputStateHousehold.is_input_closed;
                },
                inputEconomyClosed(state) {
                    return state.inputStateEconomy.is_input_closed;
                },
                inputGovernmentClosed(state) {
                    return state.inputStateGovernment.is_input_closed;
                },
                isAdminReadGame(state) {
                    return state.activeGame.admin_read;
                },
                isGameActivated(state) {
                    return state.activeGame.activated;
                }
            }

        },
        parameterSets: {
            namespaced: true,
            state() {
                return {
                    parameterSets: null,
                    selectedParameterSet: {},
                };
            },
            mutations: {
                replaceParameterSets(state, items) {
                    state.parameterSets = items;
                },
                setSelectedParameterSet(state, item) {
                    state.selectedParameterSet = item;
                },
            },
            actions: {
                reloadParameterSets(context) {
                    axios.post(url, {'action': 'fetch-parameter-sets'})
                        .then(response => context.commit('replaceParameterSets', response.data.parameterSets))
                        .catch((error) => alert(error.response.data.message));
                },
            }
        },
        commonParameter: {
            namespaced: true,
            state() {
                return {
                    commonParameter: null
                };
            },
            mutations: {
                replaceCommonParameter(state, item) {
                    state.commonParameter = item;
                }
            },
            actions: {
                reloadCommonParameter(context) {
                    return axios.post(url, {
                        'action': 'fetch-common-parameter',
                        'parameter-set': context.rootState.parameterSets.selectedParameterSet,
                        'game': context.rootState.games.selectedGame,
                    })
                        .then(response => context.commit('replaceCommonParameter', response.data.commonParameter))
                        .catch((error) => alert(error.response.data.message));
                },
            }
        },
        governmentParameter: {
            namespaced: true,
            state() {
                return {
                    governmentParameter: null
                };
            },
            mutations: {
                replaceGovernmentParameter(state, item) {
                    state.governmentParameter = item;
                }
            },
            actions: {
                reloadGovernmentParameter(context) {
                    return axios.post(url, {
                        'action': 'fetch-government-parameter',
                        'parameter-set': context.rootState.parameterSets.selectedParameterSet,
                        'game': context.rootState.games.selectedGame,
                    })
                        .then(response => context.commit('replaceGovernmentParameter', response.data.governmentParameter))
                        .catch((error) => alert(error.response.data.message));
                },
            }
        },
        householdParameter: {
            namespaced: true,
            state() {
                return {
                    householdParameter: null
                };
            },
            mutations: {
                replaceHouseholdParameter(state, item) {
                    state.householdParameter = item;
                }
            },
            actions: {
                reloadHouseholdParameter(context) {
                    return axios.post(url, {
                        'action': 'fetch-household-parameter',
                        'parameter-set': context.rootState.parameterSets.selectedParameterSet,
                        'game': context.rootState.games.selectedGame,
                    })
                        .then(response => context.commit('replaceHouseholdParameter', response.data.householdParameter))
                        .catch((error) => alert(error.response.data.message));
                },
            }
        },
        economyParameter: {
            namespaced: true,
            state() {
                return {
                    economyParameter: null
                };
            },
            mutations: {
                replaceEconomyParameter(state, item) {
                    state.economyParameter = item;
                }
            },
            actions: {
                reloadEconomyParameter(context) {
                    return axios.post(url, {
                        'action': 'fetch-economy-parameter',
                        'parameter-set': context.rootState.parameterSets.selectedParameterSet,
                        'game': context.rootState.games.selectedGame,
                    })
                        .then(response => context.commit('replaceEconomyParameter', response.data.economyParameter))
                        .catch((error) => alert(error.response.data.message));
                },
            }
        },
        games: {
            namespaced: true,
            state() {
                return {
                    licenses: [],
                    games: [],
                    selectedGame: {},
                    scoresheet: {},
                };
            },
            mutations: {
                replaceLicenses(state, items) {
                    state.licenses = items;
                },
                replaceGames(state, items) {
                    state.games = items;
                },
                setSelectedGame(state, item) {
                    state.selectedGame = item;
                },
                clearSelectedGame(state) {
                    state.selectedGame = {};
                    state.scoresheet = {};
                },
                replaceScoresheet(state, item) {
                    state.scoresheet = item;
                }
            },
            actions: {
                reloadGames(context) {
                    axios.post(url, {'action': 'fetch-games'})
                        .then(response => context.commit('replaceGames', response.data.games))
                        .catch((error) => alert(error.response.data.message));
                },
                reloadLicenses(context) {
                    axios.post(url, {'action': 'fetch-licenses'})
                        .then(response => context.commit('replaceLicenses', response.data.licenses))
                        .catch((error) => alert(error.response.data.message));
                },
                reloadScoresheet(context) {
                    return axios.post(url, {
                        'action': 'fetch-scoresheet',
                        'game': context.rootState.games.selectedGame,
                    })
                        .then(response => context.commit('replaceScoresheet', response.data.scoresheet))
                        .catch((error) => alert(error.response.data.message));
                },
            },
            getters: {
                licenseOrganizations: (state) => (license_key) => {
                    return state.licenses.filter(licenses => licenses.license_key === license_key)[0].organizations;
                },
            },
        },
        dataInputCommon: {
            namespaced: true,
            state() {
                return {
                    dataInputCommon: null
                };
            },
            mutations: {
                replaceDataInputCommon(state, item) {
                    state.dataInputCommon = item;
                }
            },
            actions: {
                reloadDataInputCommon(context) {
                    return axios.post(url, {
                        'action': 'fetch-data-input-common',
                    })
                        .then(response => context.commit('replaceDataInputCommon', response.data.dataInputCommon))
                        .catch((error) => alert(error.response.data.message));
                },
            },
        },
        dataInputGovernment: {
            namespaced: true,
            state() {
                return {
                    dataInputGovernment: null
                };
            },
            mutations: {
                replaceDataInputGovernment(state, item) {
                    state.dataInputGovernment = item;
                }
            },
            actions: {
                reloadDataInputGovernment(context) {
                    return axios.post(url, {
                        'action': 'fetch-data-input-government',
                    })
                        .then(response => context.commit('replaceDataInputGovernment', response.data.dataInputGovernment))
                        .catch((error) => alert(error.response.data.message));
                },
            },
        },
        dataInputEconomy: {
            namespaced: true,
            state() {
                return {
                    dataInputEconomy: null
                };
            },
            mutations: {
                replaceDataInputEconomy(state, item) {
                    state.dataInputEconomy = item;
                }
            },
            actions: {
                reloadDataInputEconomy(context) {
                    return axios.post(url, {
                        'action': 'fetch-data-input-economy',
                    })
                        .then(response => context.commit('replaceDataInputEconomy', response.data.dataInputEconomy))
                        .catch((error) => alert(error.response.data.message));
                },
            },
        },
        dataInputHousehold: {
            namespaced: true,
            state() {
                return {
                    dataInputHousehold: null
                };
            },
            mutations: {
                replaceDataInputHousehold(state, item) {
                    state.dataInputHousehold = item;
                },
            },
            actions: {
                reloadDataInputHousehold(context) {
                    return axios.post(url, {
                        'action': 'fetch-data-input-household',
                    })
                        .then(response => context.commit('replaceDataInputHousehold', response.data.dataInputHousehold))
                        .catch((error) => alert(error.response.data.message));
                },
            },
        },
        householdBudgetAccount: {
            namespaced: true,
            state() {
                return {
                    householdBudgetAccount: null,
                    householdBudgetBase: null,
                    calculated: null,
                };
            },
            mutations: {
                replaceHouseholdBudgetAccount(state, item) {
                    state.householdBudgetAccount = item;
                    state.calculated = true
                },
                replaceHouseholdBudgetBase(state, item) {
                    state.householdBudgetAccount = item;
                    state.householdBudgetBase = item;
                    state.calculated = false;
                },
            },
        },
        governmentBudgetAccount: {
            namespaced: true,
            state() {
                return {
                    governmentBudgetAccount: null,
                    governmentBudgetBase: null,
                    calculated: null,
                };
            },
            mutations: {
                replaceGovernmentBudgetAccount(state, item) {
                    state.governmentBudgetAccount = item;
                    state.calculated = true
                },
                replaceGovernmentBudgetBase(state, item) {
                    state.governmentBudgetAccount = item;
                    state.governmentBudgetBase = item;
                    state.calculated = false;
                },
            },
        },
        economyBudgetAccount: {
            namespaced: true,
            state() {
                return {
                    economyBudgetAccount: null,
                    economyBudgetBase: null,
                    calculated: null,
                };
            },
            mutations: {
                replaceEconomyBudgetAccount(state, item) {
                    state.economyBudgetAccount = item;
                    state.calculated = true
                },
                replaceEconomyBudgetBase(state, item) {
                    state.economyBudgetAccount = item;
                    state.economyBudgetBase = item;
                    state.calculated = false;
                },
            },
        },
        loadingProgress: {
            namespaced: true,
            state() {
                return {
                    isLoading: false,
                };
            },
            mutations: {
                loading(state, item) {
                    state.isLoading = item;
                },
            }
        },
        history: {
            namespaced: true,
            state() {
                return {
                    history: {},
                };
            },
            mutations: {
                replaceHistory(state, item) {
                    state.history = item;
                },
            },
        },
        statistics: {
            namespaced: true,
            state() {
                return {
                    gameChartCalculated: null,
                    gdpGrowth: null,
                    gdpUse: null,
                    unemployment: null,
                    budget: null,
                    inflation: null,
                    population: null,
                    employmentRate: null,
                    workingEnvironment: null,
                    wages: null,
                    incomeDevelopment: null,
                    incomePoverty: null,
                    stock: null,
                    corporateSuccess: null,
                    foreignTrade: null,
                    governmentAccount: null,
                    governmentTaxRate: null,
                    governmentSpendingRate: null,
                    financeSituationHousehold: null,
                    financeSituationEconomy: null,
                    financeSituationGovernment: null,
                    prosperity: null,
                }
            },
            mutations: {
                // eslint-disable-next-line no-unused-vars
                clearGameChartData(state, item) {
                    state.gameChartCalculated = false;
                    state.gdpGrowth = {};
                    state.gdpUse = {};
                    state.unemployment = {};
                    state.budget = {};
                    state.inflation = {};
                    state.population = {};
                    state.employmentRate = {};
                    state.workingEnvironment = {};
                    state.wages = {};
                    state.incomeDevelopment = {};
                    state.incomePoverty = {};
                    state.stock = {};
                    state.corporateSuccess = {};
                    state.foreignTrade = {};
                    state.governmentAccount = {};
                    state.governmentTaxRate = {};
                    state.governmentSpendingRate = {};
                    state.financeSituationHousehold = {};
                    state.financeSituationEconomy = {};
                    state.financeSituationGovernment = {};
                    state.prosperity = {};
                },
                replaceGameChartData(state, item) {
                    state.gameChartCalculated = true;
                    state.gdpGrowth = item.gdpGrowth;
                    state.gdpUse = item.gdpUse;
                    state.unemployment = item.unemployment;
                    state.budget = item.budget;
                    state.inflation = item.inflation;
                    state.population = item.population;
                    state.employmentRate = item.employmentRate;
                    state.workingEnvironment = item.workingEnvironment;
                    state.wages = item.wages;
                    state.incomeDevelopment = item.incomeDevelopment;
                    state.incomePoverty = item.incomePoverty;
                    state.stock = item.stock;
                    state.corporateSuccess = item.corporateSuccess;
                    state.foreignTrade = item.foreignTrade;
                    state.governmentAccount = item.governmentAccount;
                    state.governmentTaxRate = item.governmentTaxRate;
                    state.governmentSpendingRate = item.governmentSpendingRate;
                    state.financeSituationHousehold = item.financeSituationHousehold;
                    state.financeSituationEconomy = item.financeSituationEconomy;
                    state.financeSituationGovernment = item.financeSituationGovernment;
                    state.prosperity = item.prosperity
                },
            }
        },
        websiteBoard: {
            namespaced: true,
            state() {
                return {
                    website: {},
                    headerText: {},
                    footerText: {},
                    contentText: {},
                    downloads: {},
                    downloadsPrivate: {},
                };
            },
            mutations: {
                replaceWebsite(state, item) {
                    state.website = item;
                },
                replaceContent(state, item) {
                    state.headerText = item.headerText;
                    state.footerText = item.footerText;
                    state.contentText = item.contentText;
                    state.downloads = item.downloads;
                },
                replacePrivateDownload(state,item) {
                    state.downloadsPrivate = item;
                },
                replaceHeaderText(state, {page, pos, item}) {
                    state.headerText[page][pos] = item;
                },
                replaceFooterText(state, {page, pos, item}) {
                    state.footerText[page][pos] = item;
                },
                replaceContentText(state, {page, pos, item}) {
                    state.contentText[page][pos] = item;
                }
            },
            actions: {
                loadWebsite(context) {
                    axios.post(url, {'action': 'fetch-website'})
                        .then(response => context.commit('replaceWebsite', response.data.website))
                        .catch((error) => alert(error.response.data.message));
                },
                reloadPage(context, payload) {
                    axios.post(url, {'action': 'fetch-website-content', payload})
                        .then(response => context.commit('replaceContent', response.data.content))
                        .catch((error) => alert(error.response.data.message));
                },
                loadDownloadFiles(context) {
                    axios.post(url, {'action': 'fetch-private-download'})
                        .then(response => context.commit('replacePrivateDownload', response.data.content))
                        .catch((error) => alert(error.response.data.message));
                }
            },
            getters: {
                headerTextLength: (state) => (page) => {
                    if (JSON.stringify(state.headerText).includes(page)) {
                        return state.headerText[page].length;
                    }
                    return 0;
                },
                footerTextLength: (state) => (page) => {
                    if (JSON.stringify(state.footerText).includes(page)) {
                        return state.footerText[page].length;
                    }
                    return 0;
                },
                contentTextLength: (state) => (page) => {
                    if (JSON.stringify(state.contentText).includes(page)) {
                        return state.contentText[page].length;
                    }
                    return 0;
                },
                website: (state) => {
                    return state.website;
                },
                headerText: (state, getters) => (page) => {
                    return getters.headerTextLength(page) > 0 ? state.headerText[page] : null;

                },
                footerText: (state, getters) => (page) => {
                    return getters.footerTextLength(page) > 0 ? state.footerText[page] : null;

                },
                contentText: (state, getters) => (page) => {
                    return getters.contentTextLength(page) > 0 ? state.contentText[page] : null;

                },
            }
        },
    }
});
