<template>
  <ValidationForm v-if="item" v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,saveParameter)">

      <TopFormBar :info-text="message" :info-type="messageType"
                  :save-button="!isDisabled"
                  :title="$t('parameter.household-parameter')" class="eco-form-bar-second" style="width: 70%;"/>

      <div class="grid-container eco-container" style="width: 70%;">

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell col-margin">
              <h4>{{ $t('parameter.consumption') }}</h4>

              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.food') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.consumption_food"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.clothes') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.consumption_clothes"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.healthy') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.consumption_healthy"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.education') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.consumption_education"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.communication') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.consumption_communication"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.leisure') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.consumption_leisure"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.automobile') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.consumption_automobile"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.living') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.consumption_living"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
            </div>

            <div class="medium-6 cell col-margin">
              <h4>{{ $t('parameter.prosperity-points') }}</h4>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.food') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.prosperity_points_food"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.clothes') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.prosperity_points_clothes"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.healthy') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.prosperity_points_healthy"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.education') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.prosperity_points_education"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.communication') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.prosperity_points_communication"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.leisure') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.prosperity_points_leisure"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.automobile') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.prosperity_points_automobile"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>
              <div class="grid-x">
                <div class="medium-5 cell">
                  <label class="text-left middle">{{ $t('category.living') }} </label>
                </div>
                <div class="medium-4 cell">
                  <SpinFieldPlus v-model="item.household_parameter.prosperity_points_living"
                                 :disabled="isDisabled" max="8" min="0"/>
                </div>
              </div>

            </div>

          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-5 cell">
              <label class="text-left middle">{{ $t('parameter.disinvestment-ratio') }} (%)</label>
            </div>
            <div class="medium-2 cell">
              <Field v-model="item.household_parameter.disinvestment_ratio" :disabled="isDisabled" as="input"
                     name="disinvestmentRatio" type="number"></Field>
              <ErrorMessage as="small" class="error" name="disinvestmentRatio"></ErrorMessage>
            </div>
          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell">
              <h4>{{ $t('parameter.savings') }}</h4>
            </div>
          </div>

          <div class="grid-x">
            <div class="medium-5 cell">
              <label class="text-left middle">{{ $t('parameter.savings-volume') }}</label>
            </div>
            <div class="medium-2 cell">
              <Field v-model="item.household_parameter.savings_volume" name="savingsVolume">
                <NumberField v-model="item.household_parameter.savings_volume"
                             :disabled="isDisabled"></NumberField>
              </Field>
              <ErrorMessage as="small" class="error" name="savingsVolume"></ErrorMessage>
            </div>
          </div>
          <div class="grid-x">
            <div class="medium-5 cell">
              <label class="text-left middle">{{ $t('parameter.payments') }}</label>
            </div>
            <div class="medium-2 cell">
              <Field v-model="item.household_parameter.savings_payments" name="savingsPayments">
                <NumberField v-model="item.household_parameter.savings_payments"
                             :disabled="isDisabled"></NumberField>
              </Field>
              <ErrorMessage as="small" class="error" name="savingsPayments"></ErrorMessage>
            </div>
          </div>
          <div class="grid-x">
            <div class="medium-5 cell">
              <label class="text-left middle">{{ $t('parameter.payouts') }}</label>
            </div>
            <div class="medium-2 cell">
              <Field v-model="item.household_parameter.savings_payouts" name="savingsPayouts">
                <NumberField v-model="item.household_parameter.savings_payouts"
                             :disabled="isDisabled"></NumberField>
              </Field>
              <ErrorMessage as="small" class="error" name="savingsPayouts"></ErrorMessage>
            </div>
          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell">
              <h4>{{ $t('parameter.credit-limit') }}</h4>
            </div>
          </div>

          <div class="grid-x">
            <div class="medium-5 cell">
              <label class="text-left middle">{{ $t('parameter.credit-volume') }}</label>
            </div>
            <div class="medium-2 cell">
              <Field v-model="item.household_parameter.credit_volume" name="creditVolume">
                <NumberField v-model="item.household_parameter.credit_volume"
                             :disabled="isDisabled"></NumberField>
              </Field>
              <ErrorMessage as="small" class="error" name="creditVolume"></ErrorMessage>
            </div>
          </div>
          <div class="grid-x">
            <div class="medium-5 cell">
              <label class="text-left middle">{{ $t('parameter.borrowing') }} </label>
            </div>
            <div class="medium-2 cell">
              <Field v-model="item.household_parameter.borrowing" name="borrowing">
                <NumberField v-model="item.household_parameter.borrowing"
                             :disabled="isDisabled"></NumberField>
              </Field>
              <ErrorMessage as="small" class="error" name="borrowing"></ErrorMessage>
            </div>
          </div>
          <div class="grid-x">
            <div class="medium-5 cell">
              <label class="text-left middle">{{ $t('parameter.loan-repayment') }}</label>
            </div>
            <div class="medium-2 cell">
              <Field v-model="item.household_parameter.loan_repayment" name="loanRepayment">
                <NumberField v-model="item.household_parameter.loan_repayment"
                             :disabled="isDisabled"></NumberField>
              </Field>
              <ErrorMessage as="small" class="error" name="loanRepayment"></ErrorMessage>
            </div>
          </div>
        </div>

      </div>

    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from "vee-validate";
import axios from 'axios';
import TopFormBar from "../../TopFormBar";
import SpinFieldPlus from "../../SpinFieldPlus";
import NumberField from "../../NumberField";

export default {
  name: "HouseholdParameterForm",
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
    TopFormBar,
    SpinFieldPlus,
    NumberField
  },
  props: {
    disabled: {
      default: true,
      type: Boolean
    },
  },
  data() {
    const schema = {
      // consumptionFood: 'required',
      // consumptionClothes: 'required',
      // consumptionHealthy: 'required',
      // consumptionEducation: 'required',
      // consumptionCommunication: 'required',
      // consumptionLeisure: 'required',
      // consumptionAutomobile: 'required',
      // consumptionLiving: 'required',
      disinvestmentRatio: 'required',

      savingsVolume: 'required',
      savingsPayments: 'required',
      savingsPayouts: 'required',

      // prosperityPointsFood: 'required',
      // prosperityPointsClothes: 'required',
      // prosperityPointsHealthy: 'required',
      // prosperityPointsEducation: 'required',
      // prosperityPointsCommunication: 'required',
      // prosperityPointsLeisure: 'required',
      // prosperityPointsAutomobile: 'required',
      // prosperityPointsLiving: 'required',

      creditVolume: 'required',
      borrowing: 'required',
      loanRepayment: 'required',


    };
    return {
      schema,
      item: undefined,
      message: "",
      messageType: undefined,
    };
  },
  computed: {
    isDisabled() {
      return this.disabled ? true : false;
    }
  },
  methods: {
    saveParameter() {
      this.$store.commit('householdParameter/replaceHouseholdParameter', this.item);
      axios.post(url, {
        'action': 'save-household-parameter',
        'household-parameter': this.$store.state.householdParameter.householdParameter,
      })
          .then(() => {
            if (url.includes("admin-board")) {
              this.$router.push(url + '/parameter-set/edit');
            } else {
              this.$router.push(url + '/game/edit');
            }
            this.showMessage(this.$t('messages.save-successful'), this.$MSG_TYPE_SUCCESS);
          })
          .catch((error) => alert(error.response.data.message));
    },
    showMessage(msg, type = 0) {
      this.message = msg;
      this.messageType = type;
      setTimeout(() => {
        this.message = "";
      }, 2000);
    }
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.householdParameter.householdParameter;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.householdParameter.householdParameter);
        }
    );
    if (this.$store.state.householdParameter.householdParameter) {
      this.item = Object.assign({}, this.$store.state.householdParameter.householdParameter);
    }
  },
  unmounted() {
    this.unwatch();
  }
}
</script>

<style scoped>


</style>