<template>
    <div id="explorer" class="eco-table">
        <div class="grid-x grid-margin-x">
            <div class="button-group cell shrink">
                <button class="button primary float-left eco-button eco-group-button" type="button"
                        @click="refresh"><i class="fas fa-undo-alt"></i>
                </button>
                <button class="button primary float-left eco-button eco-group-button" type="button">
                    <label>
                        Private
                        <i class="fas fa-plus"></i>
                        <input type="file" id="upload-private-file" @change="add('private')" style="display: none">
                    </label>
                </button>
                <button class="button primary float-left eco-button eco-group-alert-button" type="button">
                    <label>
                        Public
                        <i class="fas fa-plus"></i>
                        <input type="file" id="upload-public-file" @change="add('public')" style="display: none">
                    </label>
                </button>
                <div>

                </div>

            </div>
        </div>
        <div>
            <table>
                <thead class="eco-row-header">
                <tr>
                    <th>Name</th>
                    <th class="eco-row-header-action"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="file in files" v-bind:key="file" class="eco-table-border">
                    <td><a :href="'/static/storage/' + file" target="_blank"> {{ file }} </a> </td>
                    <td>
                        <div class="grid-x grid-margin-x">
                            <div class="button-group cell shrink">
                                <button class="button primary eco-button eco-group-button" type="button"
                                        @click="remove(file)"><i class="fas fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
/*global url*/
import axios from 'axios';

export default {
    name: "Explorer",
    computed: {
        files() {
            return this.$store.state.explorer.files;
        }
    },
    methods: {
        remove(file) {
          if (confirm(this.$t('messages.remove-file'))) {
            axios.post(url, {'action': 'delete-file', 'file': file})
                .then(() => this.refresh(null))
                .catch((error) => alert(error.response.data.message));
          }
        },
        add(folder) {
            const src = this.$el.querySelector('#upload-' + folder + '-file');

            if (src.files.length > 0) {
                let formData = new FormData();
                Array.from(src.files).forEach(file => {
                    formData.append('files', file);
                });

                formData.append('action', 'upload-to-' + folder);

                axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(() => this.refresh(null))
                    .catch((error) => alert(error.response.data.message));
            }

        },
        refresh() {
            this.$store.dispatch('explorer/reload');
        },
    },
    mounted() {
        this.refresh(null);
    },
}
</script>

<style scoped>

</style>