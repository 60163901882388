<template>
  <ValidationForm v-if="item" v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">
      <TopFormBar :info-text="message" :info-type="messageType" :save-button="saveButton" class="eco-game-form-bar"/>
      <div class="grid-container eco-container">

        <div cell class="medium-12">
          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-8 cell">
                <h4>{{ $t('government.taxes-household') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('government.wage-tax') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_data.household_income_tax" :disabled="isDisabled" as="input" name="householdIncomeTax"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="householdIncomeTax"></ErrorMessage>
              </div>
            </div>
          </div>

          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-8 cell">
                <h4>{{ $t('government.taxes-company') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('government.income-tax') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_data.economy_income_tax" :disabled="isDisabled" as="input" name="economyIncomeTax"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="economyIncomeTax"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('government.sales-tax') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_data.economy_sales_tax" :disabled="isDisabled" as="input" name="economySalesTax"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="economySalesTax"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('government.corporate-tax') }} (%) </label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_data.corporate_tax" :disabled="isDisabled" as="input" name="corporateTax"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="corporateTax"></ErrorMessage>
              </div>
            </div>
            <hr class="eco-horizontal-split-line"/>
          </div>

          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-8 cell">
                <h4>{{ $t('government.finance') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('government.bond-issue')"
                              :text="$t('tooltip.bond-issue')">
                </ToolTipLabel>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_data.bond_issue" name="bondIssue">
                  <NumberField v-model="item.government_data.bond_issue" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="bondIssue"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('government.bond-repayment')"
                              :text="$t('tooltip.bond-repayment')">
                </ToolTipLabel>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.government_data.bond_repayment" name="bondRepayment">
                  <NumberField v-model="item.government_data.bond_repayment" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="bondRepayment"></ErrorMessage>
              </div>
            </div>
            <hr class="eco-horizontal-split-line"/>
          </div>
        </div>

        <div cell class="medium-6">
          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-8 cell">
                <h4>{{ $t('government.public-spending') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('government.pension-payments') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_data.pension_payments" name="pensionPayments">
                  <NumberField v-model="item.government_data.pension_payments" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="pensionPayments"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('government.unemployment-benefit') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_data.unemployment_benefit" name="unemploymentBenefit">
                  <NumberField v-model="item.government_data.unemployment_benefit" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="unemploymentBenefit"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('government.officials-payments') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_data.official_payments" name="officialPayments">
                  <NumberField v-model="item.government_data.official_payments" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="officialPayments"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('government.officials-cnt')"
                              :text="$t('tooltip.officials-cnt')">
                </ToolTipLabel>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_data.official_count" name="officialsCount">
                  <NumberField v-model="item.government_data.official_count" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="officialsCount"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-8 cell">
                <h4>{{ $t('government.state-support') }}</h4>
              </div>
            </div>
            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('government.state-support-household') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_data.household_support" name="householdSupport">
                  <NumberField v-model="item.government_data.household_support" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="householdSupport"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('government.state-support-economy') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_data.business_support" name="businessSupport">
                  <NumberField v-model="item.government_data.business_support" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="businessSupport"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('government.public-consumption') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.government_data.public_consumption" name="publicConsumption">
                  <NumberField v-model="item.government_data.public_consumption" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="publicConsumption"></ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from "axios";
import TopFormBar from "../../TopFormBar";
import NumberField from "../../NumberField";
import ToolTipLabel from "../../ToolTipLabel";

export default {
  name: "GovernmentForm",
  components: {
    NumberField,
    TopFormBar,
    ToolTipLabel,
    ValidationForm,
    Field,
    ErrorMessage,
  },
  props: {
    disabled: {
      default: true,
      type: Boolean
    },
    saveButton: {
      default: true,
      type: Boolean
    }
  },
  data() {
    const schema = {
      householdIncomeTax: 'required|integer_positive',
      economyIncomeTax: 'required|integer_positive',
      economySalesTax: 'required|integer_positive',
      corporateTax: 'required|integer_positive',
      bondIssue: 'required|integer_positive',
      bondRepayment: 'required|integer_positive',
      pensionPayments: 'required|integer_positive',
      unemploymentBenefit: 'required|integer_positive',
      officialPayments: 'required|integer_positive',
      officialsCount: 'required|integer_positive',
      householdSupport: 'required',
      businessSupport: 'required',
      publicConsumption: 'required',
    };
    return {
      schema,
      item: undefined,
      message: "",
      messageType: undefined,
      householdRoundInput: {},
      economyRoundInput: {},
      isLocked: this.disabled,
    };
  },
  methods: {
    save() {
      this.$store.commit('loadingProgress/loading', true);
      axios.post(url, {
        'action': 'save-data-input-government', 'item': this.item,
        'governmentBudgetAccount': this.$store.state.governmentBudgetAccount.governmentBudgetBase,
        'householdRoundInput': this.householdRoundInput,
        'economyRoundInput': this.economyRoundInput,
        'simulationMode': this.$store.state.playground.simulationMode
      })
          .then(response => {
            this.$store.commit('governmentBudgetAccount/replaceGovernmentBudgetAccount', response.data.governmentBudgetAccount);
            this.householdRoundInput = response.data.householdRoundInput;
            this.economyRoundInput = response.data.economyRoundInput;
            this.$store.commit('loadingProgress/loading', false);
          })
          .then(() => {
            this.$router.push(url);
            this.showMessage(this.$t('messages.refresh-successful'), this.$MSG_TYPE_SUCCESS);
          })
          .catch((error) => alert(error.response.data.message));
    },
    saveNoBudgetAccount() {
      axios.post(url, {
        'action': 'save-data-government-no-budget-account', 'item': this.item
      })
          .then(() => {
            this.$store.commit('playground/setSimulationMode',true);
            this.$router.push(url);
          })
          .catch((error) => alert(error.response.data.message));
    },
    calculateBase() {
      this.$store.commit('loadingProgress/loading', true);
      this.$store.commit('playground/setSimulationMode',false);
      this.$store.commit('statistics/clearGameChartData', {});
      axios.post(url, {'action': 'calculate-base-government'})
          .then(response => {
            this.$store.commit('governmentBudgetAccount/replaceGovernmentBudgetBase', response.data.governmentBudgetAccount);
            this.householdRoundInput = response.data.householdRoundInput;
            this.economyRoundInput = response.data.economyRoundInput;
            this.$store.commit('statistics/replaceGameChartData', response.data.gameChartData);
            this.$store.commit('history/replaceHistory', response.data.historyData);
            this.$store.commit('loadingProgress/loading', false);
          })
          .then(() => {
            this.$router.push(url);
          })
          .catch((error) => alert(error.response.data.message));
    },
    showMessage(msg, type = 0) {
      this.message = msg;
      this.messageType = type;
      setTimeout(() => {
        this.message = "";
      }, 2000);
    },
    enableInput() {
      this.isLocked = false;
    },
    disableInput() {
      this.isLocked = true;
    },
  },
  computed: {
    isDisabled() {
      return this.disabled ? this.isLocked  : this.$store.getters['playground/inputGovernmentClosed'];
    }
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.dataInputGovernment.dataInputGovernment;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.dataInputGovernment.dataInputGovernment);
        }
    );
    this.unwatch_save = this.$store.watch(
        (state) => {
          return state.playground.inputStateGovernment;
        },
    );
    if (this.$store.state.dataInputGovernment.dataInputGovernment) {
      this.item = Object.assign({}, this.$store.state.dataInputGovernment.dataInputGovernment);
    }

  },
  unmounted() {
    this.unwatch();
    this.unwatch_save();
  },
  updated() {
    if (this.saveButton) {
      this.calculateBase();
    }
  }

}
</script>

<style scoped>

input[type=number] {
  text-align: right;
}

</style>