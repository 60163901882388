<template>
  <div class="eco-table">
    <div class="grid-x grid-margin-x">
      <div class="button-group cell shrink">
        <button class="button primary float-left eco-button eco-group-button" type="button"
                @click="refresh"><i class="fas fa-undo-alt"></i>
        </button>
        <button class="button primary float-left eco-button eco-group-button" type="button"
                @click="add"><i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div>
      <table>
        <thead class="eco-row-header">
        <tr>
          <th>{{ $t('account.user-name') }}</th>
          <th>{{ $t('account.first-name') }}</th>
          <th>{{ $t('account.last-name') }}</th>
          <th>{{ $t('account.enabled') }}</th>
          <th>{{ $t('account.certified') }}</th>
          <th>{{ $t('account.license-holder') }}</th>
          <th>{{ $t('account.license') }}</th>
          <th class="eco-row-header-action"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in rows" v-bind:key="row" class="eco-table-border">
          <td>{{ row.username }}</td>
          <td>{{ row.first_name }}</td>
          <td>{{ row.last_name }}</td>
          <td style="text-align: center;">
            <icon v-bind="getIcon(row.enabled)"></icon>
          </td>
          <td style="text-align: center;">
            <icon v-bind="getIcon(row.is_certified)"></icon>
          </td>
          <td>{{ row.license_holder }}</td>
          <td>{{ row.license_key }}</td>
          <td>
            <div class="grid-x grid-margin-x">
              <div class="button-group cell shrink">
                <button class="button primary eco-button eco-group-button" type="button"
                        @click="edit(row)"><i class="fas fa-edit"></i>
                </button>
                <button class="button primary eco-button eco-group-button" type="button"
                        @click="remove(row)"><i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* global url */
import axios from 'axios';

export default {
  name: 'TrainerTable',
  computed: {
    rows() {
      return this.$store.state.adminBoard.trainers;
    }
  },
  methods: {
    edit(item) {
      this.$store.commit('adminBoard/setSelectedTrainer', item);
      this.$router.push(url + '/trainer/edit');
    },
    remove(item) {
      if (confirm(this.$t('messages.remove-trainer'))) {
        axios.post(url, {'action': 'remove-trainer', 'item': item})
            .then(() => this.refresh(null))
            .catch((error) => alert(error.response.data.message));
      }
    },
    add() {
      this.$store.commit('adminBoard/clearSelectedTrainer');
      this.$router.push(url + '/trainer/add');
    },
    refresh() {
      this.$store.dispatch('adminBoard/reloadTrainers');
    },
    getIcon(state) {
      const properties = {
        icon: 'x',
        style: 'color: red',
        class: 'fa fa-times'
      };
      if (state) {
        properties.icon = 'fa-check';
        properties.style = 'color: green';
        properties.class = 'fa fa-check';
      }
      return properties
    }
  },
  mounted() {
    this.refresh(null);
  },
};
</script>

<style scoped>

</style>