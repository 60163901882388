<template>
  <div id="password-reset" class="grid-x align-center eco-password-reset">
    <div class="cell eco-password-reset-div">
      <h4 class="text-center">
        <div>
          <span class="eco-logotext-first">Eco</span>
          <span class="eco-logotext-second">mania</span>
        </div>
      </h4>
      <div>
        Your password has been set. You may go ahead and <a href="/">login here</a>.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordResetComplete",
}
</script>

<style lang="scss" scoped>

.eco-password-reset {
  min-width: 50vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border: 2px solid #c8c8c8;
  border-radius: 0.3rem;
}

.eco-password-reset-div {
  max-width: 50%;
}

.eco-password-reset h4 {
  margin-bottom: 1rem;
}

.eco-logotext-first {
  color: #008cb9;
  font-weight: bold;
  font-size: 3rem;
}

.eco-logotext-second {
  color: #8a8a8a;
  font-weight: bold;
  font-size: 3rem;
}

</style>