<template>
  <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">

      <TopFormBar :info-text="message" :info-type="messageType"
                  :save-button="showSaveButton" :title="$t('game.form-title')" class="eco-form-bar"/>

      <div class="grid-container eco-form-container">
        <div class="grid-x grid-padding-x">
          <div class="medium-2 cell">
            <ToolTipLabel
              :label="$t('game.name')"
              :text="$t('tooltip.game-name')">
            </ToolTipLabel>
          </div>
          <div class="medium-4 cell">
            <Field v-model="item.name" as="input" name="name" type="text"></Field>
            <ErrorMessage as="small" class="error" name="name"></ErrorMessage>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-2 cell">
            <ToolTipLabel
              :label="$t('account.license')"
              :text="$t('tooltip.license')">
            </ToolTipLabel>
          </div>
          <div class="medium-4 cell">
            <Field v-model="item.license_key" as="select" name="licenseKey" @update:model-value="changedLicense">
              <option v-for="option in licenses" v-bind:key="option" v-bind:value="option.license_key">
                {{ option.license_holder }}
              </option>
            </Field>
            <ErrorMessage as="small" class="error" name="licenseKey"></ErrorMessage>
          </div>
        </div>

        <div v-if="item.game_id" class="grid-x">
          <div class="medium-2 cell">
            <label class="text-left middle">{{ $t('game.parameter-edit') }} </label>
          </div>
          <div class="medium-2 cell">
            <div class="switch-large">
              <input id="parameter-on-off" v-model="showParameterNavigationBar" class="switch-input"
                     name="gameGroup" type="checkbox" @click="showParameterNavigation">
              <label class="switch-paddle eco-switch-paddle" for="parameter-on-off">
                <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="item.game_id" class="grid-x">
          <div class="medium-2 cell">
            <label class="text-left middle">{{ $t('game.view') }} </label>
          </div>
          <div class="medium-2 cell">
            <div class="switch-large">
              <input id="game-view-on-off" v-model="showGameNavigationBar" checked
                     class="switch-input" name="gameGroup" type="checkbox" @click="showGameNavigation">
              <label class="switch-paddle eco-switch-paddle" for="game-view-on-off">
                <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
              </label>
            </div>
          </div>
        </div>

        <div class="grid-x grid-padding-x">
          <div class="medium-2 cell">
            <label class="text-left middle>">{{ $t('game.state') }} </label>
          </div>
          <div class="medium-4 cell">
            <Field v-model="gameState" as="input" class="eco-highlight" disabled="true" name="state"
                   type="text"></Field>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-2 cell">
            <ToolTipLabel
              :label="$t('game.type')"
              :text="$t('tooltip.game-type')">
            </ToolTipLabel>
          </div>
          <div class="medium-4 cell">
            <Field v-model="item.type_id" as="select" class="eco-highlight" name="gametype_id" :disabled="disableOption">
              <option v-for="option in gameOption" :key="option.type_id" :value="option.type_id">
                {{ option.text }}
              </option>
            </Field>
            <ErrorMessage as="small" class="error" name="gametype_id"></ErrorMessage>
          </div>
        </div>

        <div v-if="showParameterNavigationBar">
          <div v-if="!isAdminRead">
            <span class="button eco-span-button eco-button-medium" style="margin-top: 15px; margin-bottom: 0px;"
                  v-bind:class="disableLink">
              <a @click="resetToDefaultParameters"><i aria-hidden="true" class="fas fa-reply-all"></i>
                          &nbsp;&nbsp;{{ $t('trainer-board.reset-parameters') }}</a>
            </span>
        </div>
          <ParameterNavigationBar :disabled="isDisabled"></ParameterNavigationBar>
        </div>

        <div v-if="showGameNavigationBar">
          <hr>
          <div v-if="item.game_id" class="button-group">
            <div v-if="isDisabled">
              <a class="button eco-group-button eco-navbutton-first" @click="open">{{ $t('game.open') }}</a>
            </div>
            <div v-else>
              <span class="eco-span-button" v-bind:class="disableLink">
                  <a class="button eco-group-button eco-navbutton-first" @click="play">{{ $t('game.start') }}</a>
              </span>
            </div>
            <div v-if="!isAdminRead">
              <span class="eco-span-button" v-bind:class="disableLinkBreak">
                  <a class="button eco-group-button" @click="pause">{{ $t('game.pause') }}</a>
                </span>
              <span class="eco-span-button" v-bind:class="disableLink">
                  <a class="button eco-group-button eco-navbutton-last" @click="stop">{{ $t('game.stop') }}</a>
                </span>
            </div>
          </div>
          <hr>
          <ScoresheetForm ref="form" :disabled="isAdminRead" :license="item.license_key" />
        </div>

      </div>

    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from 'axios';
import ParameterNavigationBar from './parameter/ParameterNavigationBar';
import TopFormBar from "../TopFormBar";
import ScoresheetForm from "./ScoresheetForm";
import ToolTipLabel from "../ToolTipLabel"

export default {
  name: "GameForm",
  components: {
    ScoresheetForm,
    ParameterNavigationBar,
    TopFormBar,
    ValidationForm,
    Field,
    ErrorMessage,
    ToolTipLabel,
  },
  data() {
    const schema = {
      name: 'required',
      licenseKey: 'required',
      gametype_id: 'required'
    };
    return {
      schema,
      item: Object.assign({}, this.$store.state.games.selectedGame),
      players: Object.assign({}, this.$store.state.trainerBoard.players),
      showParameterNavigationBar: false,
      showGameNavigationBar: true,
      message: "",
      messageType: undefined,
      gameOption: [],
      organizations: [],
    };
  },
  methods: {
    save() {
      if (this.$refs.form) {
        this.$refs.form.save();
      }

      axios.post(url, {
        'action': 'save-game',
        'game': this.item,
        'scoresheet': this.$store.state.games.scoresheet,
        'common-parameter': this.$store.state.commonParameter.commonParameter,
        'government-parameter': this.$store.state.governmentParameter.governmentParameter,
        'household-parameter': this.$store.state.householdParameter.householdParameter,
        'economy-parameter': this.$store.state.economyParameter.economyParameter
      })
          .then((response) => {
            this.$store.commit('games/setSelectedGame', response.data.game);
            this.item = Object.assign({}, this.$store.state.games.selectedGame);
            this.showMessage(this.$t('messages.save-successful'), this.$MSG_TYPE_SUCCESS);
          })
          .catch((error) => alert(error.response.data.message));

    },
    saveParameter() {
      axios.post(url, {
        'action': 'save-game',
        'game': this.item,
        'scoresheet': this.$store.state.games.scoresheet,
        'common-parameter': this.$store.state.commonParameter.commonParameter,
        'government-parameter': this.$store.state.governmentParameter.governmentParameter,
        'household-parameter': this.$store.state.householdParameter.householdParameter,
        'economy-parameter': this.$store.state.economyParameter.economyParameter
      })
          .then(() => {
            this.showMessage(this.$t('messages.refresh-successful'), this.$MSG_TYPE_SUCCESS);
          })
          .catch((error) => alert(error.response.data.message));
    },
    resetToDefaultParameters() {
      if (window.confirm(this.$t('messages.parameter-reset-default'))) {
        axios.post(url, {'action': 'fetch-default-parameter-set', 'item': this.item})
            .then((response) => {
              this.$store.commit('commonParameter/replaceCommonParameter', response.data.commonParameter);
              this.$store.commit('governmentParameter/replaceGovernmentParameter', response.data.governmentParameter);
              this.$store.commit('householdParameter/replaceHouseholdParameter', response.data.householdParameter);
              this.$store.commit('economyParameter/replaceEconomyParameter', response.data.economyParameter);
              this.saveParameter();
            })
            .catch((error) => alert(error.response.data.message));
      }
    },
    showParameterNavigation() {
      this.showGameNavigationBar = this.showParameterNavigationBar ? true : false;
    },
    showGameNavigation() {
      this.showParameterNavigationBar = this.showGameNavigationBar ? true : false;
    },
    play() {
      if (confirm(this.$t('messages.start-game'))) {
        axios.post(url, {'action': 'start-game', 'item': this.item})
            .then(() => {
              const {href} = this.$router.resolve({name: 'Playground'});
              window.open(href, '_self');
            })
            .catch((error) => alert(error.response.data.message));
      }
    },
    open() {
      if (confirm(this.$t('messages.open-game'))) {
        axios.post(url, {'action': 'open-game', 'item': this.item})
            .then(() => {
              const {href} = this.$router.resolve({name: 'Playground'});
              window.open(href, '_self');
            })
            .catch((error) => alert(error.response.data.message));
      }
    },
    pause() {
      if (confirm(this.$t('messages.interrupt-game-round'))) {
        axios.post(url, {'action': 'stop-game', 'item': this.item})
            .then(() => this.$store.dispatch('games/reloadGames'))
            .catch((error) => alert(error.response.data.message));
        this.item.activated = false;
      }
    },
    stop() {
      if (confirm(this.$t('messages.close-game'))) {
        axios.post(url, {'action': 'close-game', 'item': this.item})
            .then(() => {
              this.$store.dispatch('games/reloadGames')
              this.item.closed = true
            })
            .catch((error) => alert(error.response.data.message));

      }
    },
    showMessage(msg, type = 0) {
      this.message = msg;
      this.messageType = type;
      setTimeout(() => {
        this.message = "";
      }, 2000);
    },
    changedLicense() {
      if (this.$refs.form) {
        this.$refs.form.setLicense(this.item.license_key);
      }
    },

  },
  computed: {
    showSaveButton() {
      return this.isAdminRead ? false : this.showGameNavigationBar;
    },
    disableLink() {
      return this.item.closed ? "link-disabled" : "";
    },
    disableLinkBreak() {
      return !this.item.activated | this.item.closed ? "link-disabled" : "";
    },
    licenses() {
      return this.$store.state.games.licenses;
    },
    isActive() {
      return this.item.activated;
    },
    isDisabled() {
      return this.isAdminRead ? true : this.isClosed;
    },
    isClosed() {
      return this.item.closed;
    },
    isAdminRead() {
      return this.item.admin_read;
    },
    gameState() {
      return this.isClosed ? this.$t('game.state-closed') : this.isActive ? this.$t('game.state-active') : this.$t('game.state-inactive');
    },
    disableOption() {
      return this.item.game_id == undefined ? false : this.isAdminRead ? true :false;
    },
  },
  mounted() {
    this.$store.dispatch('games/reloadScoresheet');
    axios.post(url, {'action': 'fetch-players', 'players': this.players, 'game': this.item})
            .then((response) => {
              this.$store.commit('trainerBoard/replacePlayers', response.data.players);
              this.gameOption = [
                {text: this.$t('game-type.certified'), type_id: 1},
                {text: this.$t('game-type.training'), type_id: 2},
                {text: this.$t('game-type.course'), type_id: 3},
                {text: this.$t('game-type.testing'), type_id: 4},
                {text: this.$t('game-type.other'), type_id: 5}]
            })
            .catch((error) => alert(error.response.data.message));
  }
}
</script>

<style scoped>

</style>