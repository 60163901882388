<template>
  <div class="eco-gameboard-sidenav" v-bind:class="{ open: isSidenavOpen }">
    <button class="sidenav-close-button clear secondary" type="button" @click="closeSidenav"><i
        area-hidden="true" class="far fa-window-close"></i></button>
    <ul class="vertical menu accordion-menu" data-accordion-menu>
      <slot name="nav"></slot>
      <div class="divider"></div>
      <span v-bind:class="disableLinkNextRound"><li class="eco-menu-item-color"
                                                    v-bind:class="themeColor" @click="goToNextGameRound">
              <a href="#dataView">{{ $t('game.next-round') }}</a></li></span>
      <span v-bind:class="disableLinkSynchronize"><li class="eco-menu-item-color"
                                                      v-bind:class="themeColor"
                                                      @click="closeGameRound">
              <a href="#dataView">{{ $t('game.synchronize') }}</a></li></span>
      <li class="eco-menu-item-color eco-menu-button-last" v-bind:class="themeColor"
          @click="calculateCharts"><a href="#chartView">Charts</a>
      </li>
    </ul>
  </div>

  <div id="main" v-bind:class="{ 'main-with-open-nav': isSidenavOpen }">

    <div class="eco-fixed-nav">
      <nav class="top-bar eco-top-bar" data-topbar role="navigation">
        <div class="title-bar-left eco-gametitle">{{ game.name }}</div>
        <div class="title-bar-left eco-gametitle"> {{ $t('game.round') }} {{ round }}</div>
        <button class="button primary float-right eco-button eco-menu-button"
                @click="refreshPage"><i class="fas fa-undo-alt"></i></button>
        <button class="button primary float-right eco-button eco-menu-button"
                @click="showDataview"><i class="fas fa-arrow-up"></i></button>
        <button class="button primary float-right eco-button eco-menu-button"
                @click="showHistory"><i class="fas fa-history"></i></button>
        <div class="tigle-bar-right">
          <ul class="menu eco-menu">
            <span v-bind:class="disableLinkNextRound"><li class="eco-menu-item-color"
                                                          v-bind:class="themeColor" @click="goToNextGameRound">
              <a href="#dataView">{{ $t('game.next-round') }}</a></li></span>
            <span v-bind:class="disableLinkSynchronize"><li class="eco-menu-item-color"
                                                            v-bind:class="themeColor"
                                                            @click="closeGameRound">
              <a href="#dataView">{{ $t('game.synchronize') }}</a></li></span>
            <span v-bind:class="disableLinkCharts"><li class="eco-menu-item-color eco-menu-button-last"
                                                       v-bind:class="themeColor"
                                                       @click="calculateCharts">
              <a href="#chartView">Charts</a></li>
            </span>
          </ul>
        </div>
        <button class="button warning float-right eco-gameboard-sidenav-open-button eco-button" type="button"
                v-bind:class="{ disappear: isSidenavOpen }" @click="openSidenav"><i class="fas fa-bars"></i>
        </button>
      </nav>
    </div>

    <div class="grid-container fluid eco-gameview" data-equalizer data-equalizer-mq="large-up">

      <div class="sections">
        <section id="dataView" class="eco-dataview" data-magellan-target="dataView">
          <div class="grid-x grid-margin-x">
            <div class="medium-6 columns eco-panel eco-panel-left" data-equalizer-watch>
              <h2>{{ $t('header.what-to-know') }}</h2>

              <WhatToKnowTrainerNavigation/>
            </div>
            <div class="medium-6 columns eco-panel eco-panel-right" data-equalizer-watch>
              <h2>{{ $t('header.what-to-do') }}</h2>
              <div class="button-group">
                <div class="button eco-group-button eco-roundtitle-color"
                     v-bind:class="roundtitleColor">{{ $t('playground.page-title-summary') }}
                </div>
              </div>
              <TrainerSummary/>

            </div>
          </div>
        </section>
        <section id="chartView" class="eco-dataview" data-magellan-target="chartView">
          <div class="grid-container fluid" data-equalizer data-equalizer-mq="large-up">
            <div class="grid-x grid-margin-x">
              <div class="medium-12 columns eco-panel" data-equalizer-watch>
                <h2>{{ $t('game.chart-view') }}</h2>

                <GameChart/>
              </div>
            </div>
          </div>
        </section>
        <section id="historyView" class="eco-dataview" data-magellan-target="historyView">
          <div class="grid-container fluid" data-equalizer data-equalizer-mq="large-up">
            <div class="grid-x grid-margin-x">
              <div class="medium-12 columns eco-panel" data-equalizer-watch>
                <h2>Historie der Eingabe</h2>

                <HistoryForm/>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>

  </div>
  <LoadingProgress v-if="$store.state.loadingProgress.isLoading"
                   :text="$t('messages.calculation-running')"></LoadingProgress>

</template>

<script>
/*global url*/
import WhatToKnowTrainerNavigation from "./WhatToKnowTrainerNavigation";
import TrainerSummary from "./TrainerSummary";
import GameChart from "../GameChart";
import LoadingProgress from "../../LoadingProgress";
import HistoryForm from "../HistoryForm";
import axios from "axios";

export default {
  name: "TrainerGameBoard",
  components: {
    WhatToKnowTrainerNavigation,
    TrainerSummary,
    GameChart,
    LoadingProgress,
    HistoryForm
  },
  data() {
    const schema = {};
    return {
      schema,
    };
  },
  computed: {
    isSidenavOpen() {
      return this.$store.state.sidenav.isSidenavOpen;
    },
    themeColor() {
      return this.$store.state.theme.themeColorClass;
    },
    roundtitleColor() {
      return this.themeColor + "-default"
    },
    disableLinkNextRound() {
      if (this.$store.state.playground.lastGameRound.is_synchronized || this.$store.state.playground.activeGame.closed)
        return "link-disabled";
      else
        return "";
    },
    disableLinkSynchronize() {
      if (this.$store.getters['playground/inputHouseholdClosed'] &&
          this.$store.getters['playground/inputEconomyClosed'] &&
          this.$store.getters['playground/inputGovernmentClosed'] &&
          this.$store.state.playground.lastGameRound.is_synchronized)
        return "";
      else
        return "link-disabled";
    },
    disableLinkCharts() {
      return this.$store.state.loadingProgress.isLoading === true ? "link-disabled" : "";
    },
    game() {
      return this.$store.state.playground.activeGame;
    },
    round() {
      return this.$store.state.playground.gameRound;
    }
  },
  methods: {
    openSidenav() {
      this.$store.commit('sidenav/openSidenav');
    },
    closeSidenav() {
      this.$store.commit('sidenav/closeSidenav');
    },
    closeGameRound() {
      this.closeSidenav();
      if (this.disableLinkSynchronize === "link-disabled")
        return

      if (confirm(this.$t('messages.synchronize-game-round'))) {
        axios.post(url, {'action': 'close-game-round', 'game-round': this.$store.state.playground.gameRound})
            .then(() => this.$router.push(url))
            .catch((error) => {
              if (error.response) {
                alert(error.response.data.message);
              }
            });
        this.$router.go(0);
      }
    },
    goToNextGameRound() {
      this.closeSidenav();
      if (this.disableLinkNextRound === "link-disabled")
        return

      if (confirm(this.$t('messages.next-game-round'))) {
        this.$store.commit('statistics/clearGameChartData', {});
        axios.post(url, {'action': 'next-game-round', 'game-round': this.$store.state.playground.gameRound})
            .then(response => this.$store.commit('playground/replaceInputStates', response.data.gameInputStates))
            .then(() => this.$router.push(url))
            .catch((error) => {
              if (error.response) {
                alert(error.response.data.message);
              }
            });
        this.$router.go(0);
      }
    },
    calculateCharts() {
      if (this.disableLinkCharts === "link-disabled")
        return

      if(!this.$store.state.statistics.gameChartCalculated) {
        this.$store.commit('loadingProgress/loading', true);
        this.closeSidenav();
        axios.post(url, {'action': 'calculate-base-economy'})
            .then(response => {
              this.$store.commit('statistics/replaceGameChartData', response.data.gameChartData);
              this.$store.commit('history/replaceHistory', response.data.historyData);
              this.$store.commit('loadingProgress/loading', false);
            })
            .then(() => {
              this.$router.push(url);
            })
            .catch((error) => alert(error.response.data.message));
      }
    },
    refreshPage() {
      this.$store.commit('statistics/clearGameChartData', {});
      location.reload();
    },
    showDataview() {
      location.href="#dataView";
    },
    showHistory() {
      if(!this.$store.state.statistics.gameChartCalculated) {
        this.calculateCharts();
      }
      location.href="#historyView";
    }
  },
  beforeMount() {
    this.$store.dispatch('playground/fetchActiveGame');
  },
}
</script>

<style scoped>

.filler {
  margin-top: 4rem;
}

</style>