<template>
  <div id="password-reset" class="grid-x align-center eco-password-reset">
    <ValidationForm :validation-schema="schema" class="cell eco-password-reset-form" method="post">
      <input v-model="csrfToken" name="csrfmiddlewaretoken" type="hidden">
      <h4 class="text-center">
        <div>
          <span class="eco-logotext-first">Eco</span>
          <span class="eco-logotext-second">mania</span>
        </div>
      </h4>
      <label>Enter your email address below, and we will email instructions for setting a new password.
        <Field as="input" name="email" placeholder="example@email.com" type="email"></Field>
        <ErrorMessage as="small" class="error" name="email"></ErrorMessage>
      </label>
      <p>
        <button id="eco-password-reset-button" class="button warning expanded eco-password-reset-button" type="submit">
          Submit
        </button>
      </p>
    </ValidationForm>
  </div>
</template>

<script>
/*global csrfToken*/
import {ErrorMessage, Field, Form as ValidationForm} from "vee-validate";

export default {
  name: "PasswordResetRequest",
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = {
      email: 'required|email',
    };
    return {
      schema,
    };
  },
  computed: {
    csrfToken() {
      return csrfToken;
    }
  }
}
</script>

<style lang="scss" scoped>

.eco-password-reset {
  min-width: 50vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border: 2px solid #c8c8c8;
  border-radius: 0.3rem;
}

.eco-password-reset-form {
  max-width: 50%;
}

.eco-password-reset h4 {
  margin-bottom: 1rem;
}

.eco-password-reset-button {
  background-color: #008cb9;
  margin: 0px;
  border-radius: 0.3rem;

  &:hover {
    color: #ffffff;
  }

  &:active {
    color: #313a45;
    transform: translateY(2px);
  }
}

.eco-logotext-first {
  color: #008cb9;
  font-weight: bold;
  font-size: 3rem;
}

.eco-logotext-second {
  color: #8a8a8a;
  font-weight: bold;
  font-size: 3rem;
}

</style>