<template>
  <div id="main">
    <div class="fixed-nav">
      <div id="header" class="header">
        <div class="clearfix">
          <button class="warning float-right eco-sign-out-color" type="button" v-bind:class="themeColor"
                  @click="logout"><i class="fas fa-sign-out-alt fa-2x"></i>
          </button>
        </div>
        <div :class="($store.getters['playground/isTrainer'] && 'bg-playground') ||
                     ($store.getters['playground/isGovernment'] && 'bg-government') ||
                     ($store.getters['playground/isEconomy'] && 'bg-economy') ||
                     ($store.getters['playground/isHousehold'] && 'bg-household')"
             class="header-bg"></div>
        <div class="header-title">

          <div class="header-title-container">

            <div v-if="!$store.getters['theme/isDefaultTheme']">
              <div class="eco-logo-bubble-bg" v-bind:class="themeBackground"/>
            </div>

            <div v-if="isDefaultTheme">
              <span class="eco-logotext-first">Eco</span>
              <span class="eco-logotext-second">Mania</span>
              <span class="eco-title"> {{ $t("header.title") }} </span>
            </div>

            <div class="eco-pagetitle-color" v-bind:class="themeColor">
              <span v-if="$store.getters['playground/isTrainer']"> {{ $t("playground.page-title-trainer") }} </span>
              <span v-else-if="$store.getters['playground/isGovernment']"> {{
                  $t("playground.page-title-government")
                }} </span>
              <span v-else-if="$store.getters['playground/isEconomy']"> {{
                  $t("playground.page-title-economy")
                }} </span>
              <span v-else-if="$store.getters['playground/isHousehold']"> {{
                  $t("playground.page-title-household")
                }} </span>
            </div>
          </div>

          <hr class="eco-header-line">
        </div>

      </div>
      <!--hr-->
    </div>
    <component :is="roleSpecificComponent"></component>
  </div>
</template>

<script>
/*global url*/
import TrainerGameBoard from '../components/game/trainer/TrainerGameBoard';
import GovernmentGameBoard from '../components/game/government/GovernmentGameBoard';
import HouseholdGameBoard from '../components/game/household/HouseholdGameBoard';
import EconomyGameBoard from '../components/game/economy/EconomyGameBoard';
import axios from 'axios';

export default {
  name: "Playground",
  components: {
    TrainerGameBoard,
    GovernmentGameBoard,
    EconomyGameBoard,
    HouseholdGameBoard
  },
  data() {
    return {
      roleSpecificComponent: null,
      //slogan: this.$store.state.theme.assetsPreURL,
    }
  },
  methods: {
    logout() {
      if (this.$store.getters['playground/isTrainer'] &&
          this.$store.getters['playground/isAdminReadGame'] &&
          !this.$store.getters['playground/isGameActivated']) {
        const {href} = this.$router.resolve({name: 'AdminBoard'});
          window.open(href, '_self');
      }
      else {
        if (window.confirm(this.$t('messages.logout-game'))) {
          axios.post(url, {})
              .then(() => this.$router.go(0));
        }
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('playground/fetchActiveGame').then(() => {
      if (this.$store.getters['playground/isTrainer']) {
        this.roleSpecificComponent = TrainerGameBoard;
        this.$store.commit('theme/setThemeBackgroundClass', 'bg-trainer');
        this.$store.commit('theme/setThemeColorClass', 'color-trainer');
      } else if (this.$store.getters['playground/isGovernment']) {
        this.roleSpecificComponent = GovernmentGameBoard;
        this.$store.commit('theme/setThemeBackgroundClass', 'bg-government');
        this.$store.commit('theme/setThemeColorClass', 'color-government');
      } else if (this.$store.getters['playground/isEconomy']) {
        this.roleSpecificComponent = EconomyGameBoard;
        this.$store.commit('theme/setThemeBackgroundClass', 'bg-economy');
        this.$store.commit('theme/setThemeColorClass', 'color-economy');
      } else if (this.$store.getters['playground/isHousehold']) {
        this.roleSpecificComponent = HouseholdGameBoard;
        this.$store.commit('theme/setThemeBackgroundClass', 'bg-household');
        this.$store.commit('theme/setThemeColorClass', 'color-household');
      }
    });
    this.$store.dispatch('playground/fetchDataInput');
  },
  computed: {
    isDefaultTheme() {
      return this.$store.state.theme.themeClass == "defaultTheme" ? true : false;
    },
    themeBackground() {
      return this.$store.state.theme.themeBackgroundClass;
    },
    themeColor() {
      return this.$store.state.theme.themeColorClass;
    },
  }
}

</script>

<style scoped>

.fixed-nav {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
}

</style>;