<template>

  <GameboardSidenav :game-board="'government'" :text-budget-view="$t('government.budget-account')"
                    @closeRoundEvent="closeGameRound"/>

  <div id="main" v-bind:class="{ 'main-with-open-nav': isSidenavOpen }">

    <div class="eco-fixed-nav">
      <nav class="top-bar eco-top-bar" data-topbar role="navigation">
        <div class="title-bar-left eco-gametitle">{{ game.name }}</div>
        <div class="title-bar-left eco-gametitle"> {{ $t('game.round') }} {{ round }}</div>
        <button class="button primary float-right eco-button eco-menu-button"
                @click="refreshPage"><i class="fas fa-undo-alt"></i></button>
        <button class="button primary float-right eco-button eco-menu-button"
                @click="showHistory"><i class="fas fa-history"></i></button>
        <div class="eco-title-bar-right">
          <ul class="menu eco-menu">
            <li class="eco-menu-item-color eco-menu-button-first" v-bind:class="themeColor"><a
                href="#dataView">{{ $t('game.data-input') }}</a></li>
            <li class="eco-menu-item-color" v-bind:class="themeColor"><a
                href="#budgetView">{{ $t('government.budget-account') }}</a></li>
            <span v-bind:class="disableLinkCloseRound"><li class="eco-menu-item-color" v-bind:class="themeColor"
                                                           @click="closeGameRound"><a>{{
                $t('game.close-round')
              }}</a></li></span>
            <li class="eco-menu-item-color eco-menu-button-last"
                v-bind:class="themeColor"><a href="#chartView">Charts</a>
            </li>
          </ul>
        </div>
        <button class="button warning float-right eco-gameboard-sidenav-open-button eco-button" type="button"
                v-bind:class="{ disappear: isSidenavOpen }" @click="openSidenav"><i class="fas fa-bars"></i>
        </button>
      </nav>
    </div>

    <div class="grid-container fluid eco-gameview" data-equalizer data-equalizer-mq="large-up">
      <div class="sections">
        <section id="dataView" class="eco-dataview" data-magellan-target="dataView">
          <div class="grid-x grid-margin-x">
            <div class="medium-6 columns eco-panel eco-panel-left" data-equalizer-watch>
              <h2>{{ $t('header.what-to-know') }}</h2>

              <WhatToKnowGovernmentNavigation/>
            </div>
            <div class="medium-6 columns eco-panel eco-panel-right" data-equalizer-watch>
              <h2>{{ $t('header.what-to-do') }}</h2>
              <div class="button-group">
                <div class="button eco-group-button eco-roundtitle-color" v-bind:class="roundtitleColor">{{
                    title
                  }}
                </div>
              </div>
              <GovernmentForm :disabled="false"/>
            </div>
          </div>
        </section>
        <section id="budgetView" class="eco-dataview" data-magellan-target="budgetView">
          <div class="grid-container fluid" data-equalizer data-equalizer-mq="large-up">
            <div class="grid-x grid-margin-x">
              <div class="medium-12 columns eco-panel" data-equalizer-watch>
                <h2>{{ $t('government.budget-account') }}</h2>

                <GovernmentBudgetAccount/>
              </div>
            </div>
          </div>
        </section>
        <section id="chartView" class="eco-dataview" data-magellan-target="chartView">
          <div class="grid-container fluid" data-equalizer data-equalizer-mq="large-up">
            <div class="grid-x grid-margin-x">
              <div class="medium-12 columns eco-panel" data-equalizer-watch>
                <h2>{{ $t('game.chart-view') }}</h2>

                <GameChart/>
              </div>
            </div>
          </div>
        </section>
        <section id="historyView" class="eco-dataview" data-magellan-target="historyView">
          <div class="grid-container fluid" data-equalizer data-equalizer-mq="large-up">
            <div class="grid-x grid-margin-x">
              <div class="medium-12 columns eco-panel" data-equalizer-watch>
                <h2>Historie der Eingabe</h2>

                <HistoryForm/>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>

  </div>
  <LoadingProgress v-if="$store.state.loadingProgress.isLoading"
                   :text="$t('messages.calculation-running')"></LoadingProgress>

</template>

<script>

/*global url*/
import GovernmentForm from "./GovernmentForm";
import GovernmentBudgetAccount from "./GovernmentBudgetAccount";
import WhatToKnowGovernmentNavigation from "./WhatToKnowGovernmentNavigation";
import GameChart from "../GameChart";
import GameboardSidenav from "../GameboardSidenav";
import LoadingProgress from "../../LoadingProgress";
import HistoryForm from "../HistoryForm";
import axios from "axios";

export default {
  name: "GovernmentGameBoard",
  components: {
    WhatToKnowGovernmentNavigation,
    GovernmentForm,
    GovernmentBudgetAccount,
    GameChart,
    GameboardSidenav,
    LoadingProgress,
    HistoryForm,
  },
  data() {
    const schema = {};
    return {
      schema,
      title: undefined,
    };
  },
  methods: {
    openSidenav() {
      this.$store.commit('sidenav/openSidenav');
    },
    closeGameRound() {
      if (this.disableLinkCloseRound === "link-disabled")
        return

      if (confirm(this.$t('messages.close-game-round'))) {
        axios.post(url, {'action': 'close-input-state'})
            .then(response => this.$store.commit('playground/replaceInputStates', response.data.gameInputStates))
            .then(() => {
              this.$router.push(url);
            })
            .catch((error) => alert(error.response.data.message));
      }
    },
    refreshPage() {
      if( this.isCalculated && !this.$store.getters['playground/inputGovernmentClosed']) {
        if (confirm(this.$t('messages.refresh-page'))) {
          location.reload();
        }
      }
      else {
        location.reload();
      }
    },
    showHistory() {
      location.href="#historyView";
    }
  },
  computed: {
    themeColor() {
      return this.$store.state.theme.themeColorClass;
    },
    roundtitleColor() {
      return this.isCalculated ? this.themeColor : this.themeColor + "-default"
    },
    disableLinkCloseRound() {
      if (!this.isCalculated || this.$store.getters['playground/inputGovernmentClosed'])
        return "link-disabled";
      else
        return "";
    },
    game() {
      return this.$store.state.playground.activeGame;
    },
    round() {
      return this.$store.state.playground.gameRound;
    },
    isCalculated() {
      return this.$store.state.governmentBudgetAccount.calculated;
    }
  },
  mounted() {
    this.title = this.$t('messages.values-previous-round');
    this.unwatch = this.$store.watch(
        (state) => {
          return state.governmentBudgetAccount.governmentBudgetBase;
        },
        () => {
          this.title = this.$t('messages.values-previous-round');
        }
    );
    this.unwatch_save = this.$store.watch(
        (state) => {
          return state.governmentBudgetAccount.governmentBudgetAccount
        },
        () => {
          this.title = this.$t('messages.values-current-round') + " " + this.round;
        }
    );
  },
  unmounted() {
    this.unwatch();
    this.unwatch_save()
  },
}
</script>

<style scoped>

.filler {
  margin-top: 4rem;
}

</style>