import {createI18n} from "vue-i18n";
import en from './locales/en.json';
import de from './locales/de.json';
import {configure} from "vee-validate";
import {localize} from "@vee-validate/i18n";

const messages = {
    en,
    de,
};

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
});

configure({
    generateMessage: localize(
        {
            en: {messages: en['validation-rule-messages']},
            de: {messages: de['validation-rule-messages']},
        }
    )
});

export default i18n;