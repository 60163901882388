<template>
  <div class="eco-table">
    <div class="grid-x grid-margin-x">

      <div class="button-group cell shrink">
        <button class="button primary float-left eco-button eco-group-button" type="button"
                @click="refresh"><i class="fas fa-undo-alt"></i>
        </button>
        <button v-if="editable" class="button primary float-left eco-button eco-group-button"
                type="button" @click="add"><i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div>
      <table>
        <thead class="eco-row-header">
          <tr v-if="isTrainerView">
            <th>{{ $t('game.counter') }}</th>
            <th>{{ $t('game.game-id') }}</th>
            <th>{{ $t('game.opening-date') }}</th>
            <th>{{ $t('game.name') }}</th>
            <th>{{ $t('game.type') }}</th>
            <th>{{ $t('game.activated') }}</th>
            <th>{{ $t('game.closed') }}</th>
            <th>{{ $t('game.license') }}</th>
            <th>{{ $t('game.organization') }}</th>
            <th>{{ $t('game.institution') }}</th>
            <th>{{ $t('game.location') }}</th>
            <th>{{ $t('game.number-of-participants') }}</th>
            <th>{{ $t('game.remarks') }}</th>
            <th class="eco-row-header-action"></th>
          </tr>
          <tr v-else>
            <th>{{ $t('game.game-id') }}</th>
            <th>{{ $t('game.opening-date') }}</th>
            <th>{{ $t('game.name') }}</th>
            <th>{{ $t('game.type') }}</th>
            <th>{{ $t('game.license') }}</th>
            <th>{{ $t('game.organization') }}</th>
            <th>{{ $t('game.trainer') }}</th>
            <th>{{ $t('game.institution') }}</th>
            <th>{{ $t('game.location') }}</th>
            <th>{{ $t('game.number-of-participants') }}</th>
            <th>{{ $t('game.activated') }}</th>
            <th>{{ $t('game.closed') }}</th>
            <th>{{ $t('game.remarks') }}</th>
            <th class="eco-row-header-action"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-show="isTrainerView" v-for="(row,index) in rows" v-bind:key="row" class="eco-table-border">
                <td>{{ rows.length - index++ }}</td>
                <td>{{ row.game_id }}</td>
                <td><nobr>{{ row.opening_date }}</nobr></td>
                <td>{{ row.name }}</td>
                <td> {{ gametype(row.type_id) }} </td>
                <td style="text-align: center;">
                  <icon v-bind="getIcon(row.activated)"></icon>
                </td>
                <td style="text-align: center;">
                  <icon v-bind="getIcon(row.closed)"></icon>
                </td>
                <td>{{ row.license_holder }}</td>
                <td>{{ row.organization_name }}</td>
                <td>{{ institution(row.institution_id) }}</td>
                <td>{{ location(row.location_id) }}</td>
                <td>{{ row.number_participants }}</td>
                <td>{{ row.remarks }}</td>
                <td>
                  <div class="grid-x grid-margin-x">
                    <div class="button-group cell shrink">
                      <div v-if="adminRead && !row.activated">
                        <button class="button primary eco-button eco-group-button" type="button"
                                @click="edit(row)"><i class="fas fa-edit"></i>
                        </button>
                      </div>
                      <div v-if="!adminRead">
                        <button class="button primary eco-button eco-group-button" type="button"
                                @click="edit(row)"><i class="fas fa-edit"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
          </tr>
          <tr v-show="!isTrainerView" v-for="row in rows" v-bind:key="row" class="eco-table-border">
                <td>{{ row.game_id }}</td>
                <td><nobr>{{ row.opening_date }}</nobr></td>
                <td>{{ row.name }}</td>
                <td> {{ gametype(row.type_id) }} </td>
                <td>{{ row.license_holder }}</td>
                <td>{{ row.organization_name }}</td>
                <td>{{ row.trainer_name }}</td>
                <td>{{ institution(row.institution_id) }}</td>
                <td>{{ location(row.location_id) }}</td>
                <td>{{ row.number_participants }}</td>
                <td style="text-align: center;">
                  <icon v-bind="getIcon(row.activated)"></icon>
                </td>
                <td style="text-align: center;">
                  <icon v-bind="getIcon(row.closed)"></icon>
                </td>
                <td>{{ row.remarks }}</td>
                <td>
                  <div class="grid-x grid-margin-x">
                    <div class="button-group cell shrink">
                      <div v-if="adminRead && !row.activated">
                        <button class="button primary eco-button eco-group-button" type="button"
                                @click="edit(row)"><i class="fas fa-edit"></i>
                        </button>
                      </div>
                      <div v-if="!adminRead">
                        <button class="button primary eco-button eco-group-button" type="button"
                                @click="edit(row)"><i class="fas fa-edit"></i>
                        </button>
                      </div>
                      <div v-if="deleteable">
                        <button class="button primary eco-button eco-group-button" type="button"
                              @click="remove(row)"><i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/*global url*/
import axios from 'axios';

export default {
  name: "GameTable",
  props: {
    editable: {
      default: true,
      type: Boolean,
    },
    deleteable: {
      default: true,
      type: Boolean,
    },
    adminRead: {
      default: false,
      type: Boolean,
    }
  },
  computed: {
    isTrainerView() {
      return this.$store.state.games.games.length === 0 ? true : this.$store.state.games.games[0]["trainer_name"] === null ? true : false;
    },
    rows() {
      return this.$store.state.games.games;
    },
    gametype() {
      return type_id => {
        switch (type_id) {
          case 1:
            return this.$t("game-type.certified");
          case 2:
            return this.$t("game-type.training");
          case 3:
            return this.$t("game-type.course");
          case 4:
            return this.$t("game-type.testing");
          case 5:
            return this.$t("game-type.other");
        }
      }
    },
    institution() {
      return institution_id => {
        switch (institution_id) {
          case 1:
            return this.$t("institution.school");
          case 2:
            return this.$t("institution.credit-institute");
          case 3:
            return this.$t("institution.university");
          case 4:
            return this.$t("institution.external-company");
          case 5:
            return this.$t("institution.other");
        }
      }
    },
    location() {
      return location_id => {
        switch (location_id) {
          case 1:
            return this.$t("location.credit-institute");
          case 2:
            return this.$t("location.school");
          case 3:
            return this.$t("location.external-company");
          case 4:
            return this.$t("institution.external-company");
          case 5:
            return this.$t("location.online");
          case 6:
            return this.$t("location.other");
        }
      }
    }
  },
  methods: {
    edit(item) {
      item.admin_read = this.adminRead;
      this.$store.commit('games/setSelectedGame', item);
      this.$router.push(url + '/game/edit');
    },
    remove(item) {
      if (window.confirm(this.$t('messages.remove-game'))) {
        axios.post(url, {'action': 'remove-game', 'item': item})
            .then(() => this.refresh(null))
            .catch((error) => alert(error.response.data.message));
      }
    },
    add() {
      this.$store.commit('games/clearSelectedGame');
      this.$store.commit('commonParameter/replaceCommonParameter', {});
      this.$store.commit('governmentParameter/replaceGovernmentParameter', {});
      this.$store.commit('householdParameter/replaceHouseholdParameter', {});
      this.$store.commit('economyParameter/replaceEconomyParameter', {});
      this.$router.push(url + '/game/add');
    },
    refresh() {
      this.$store.dispatch('games/reloadGames');
      this.$store.dispatch('games/reloadLicenses');
    },
    getIcon(state) {
      const properties = {
        icon: 'x',
        style: 'color: red',
        class: 'fa fa-times'
      };
      if (state) {
        properties.icon = 'fa-check';
        properties.style = 'color: green';
        properties.class = 'fa fa-check';
      }
      return properties
    },
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>