<template>
  <div class="eco-table">
    <div class="grid-x grid-margin-x">
      <div class="button-group cell shrink">
        <button class="button primary float-left eco-button eco-group-button" type="button"
                @click="refresh"><i class="fas fa-undo-alt"></i>
        </button>
      </div>
    </div>
    <div>
      <table>
        <thead class="eco-row-header">
        <tr>
          <th>{{ $t('trainer-board.license') }}</th>
          <th>{{ $t('trainer-board.government') }}</th>
          <th>{{ $t('trainer-board.economy') }}</th>
          <th>{{ $t('trainer-board.household') }}</th>
          <th class="eco-row-header-action"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in rows" v-bind:key="row" class="eco-table-border">
          <td> {{ row.license_key }}</td>
          <td> {{ row.player_government_representatives }}</td>
          <td> {{ row.player_economy_representatives }}</td>
          <td> {{ row.player_household_representatives }}</td>
          <td>
            <div class="grid-x grid-margin-x">
              <div class="button-group cell shrink">
                <button class="button primary eco-button eco-group-button" type="button"
                        @click="edit(row)"><i class="fas fa-edit"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/*global url*/

export default {
  name: 'PlayerTable',
  computed: {
    rows() {
      return this.$store.state.trainerBoard.players;
    }
  },
  methods: {
    edit(item) {
      this.$store.commit('trainerBoard/setSelectedPlayer', item);
      this.$router.push(url + '/player/edit');
    },
    refresh() {
      this.$store.dispatch('trainerBoard/reloadPlayers');
    },
  },
  mounted() {
    this.refresh(null);
  },
};
</script>

<style scoped>

</style>