<template>
  <div class="button-group">
    <a class="button eco-group-button eco-navbutton-first" @click="showHouseholdView">{{
        $t('group-title.household')
      }}</a>
    <a class="button eco-group-button" @click="showEconomyView">{{ $t('group-title.economy') }}</a>
    <a class="button eco-group-button" @click="showGovernmentView">{{ $t('group-title.government') }}</a>
    <a class="button eco-group-button eco-navbutton-last" @click="showCommonView">{{ $t('group-title.general') }}</a>
  </div>

  <component :is="currentView" :saveButton="false"></component>
</template>

<script>
import CommonForm from "../CommonForm";
import EconomyForm from "../economy/EconomyForm";
import GovernmentForm from "../government/GovernmentForm";
import HouseholdForm from "../household/HouseholdForm";

export default {
  name: "WhatToKnowTrainerNavigation",
  data() {
    return {
      currentView: null,
      item: {}
    }
  },
  methods: {
    showCommonView() {
      this.currentView = CommonForm;
    },
    showGovernmentView() {
      this.currentView = GovernmentForm;
    },
    showEconomyView() {
      this.currentView = EconomyForm;
    },
    showHouseholdView() {
      this.currentView = HouseholdForm;
    },
  },
  created() {
    this.currentView = HouseholdForm;
  },

}
</script>

<style scoped>

.space {
  margin-left: 15px;
}

</style>
