<template>
  <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">

      <div class="grid-container fluid eco-panel-container" data-equalizer data-equalizer-mq="large-up">

        <div class="grid-x">
          <div class="grid-x small-up-2 medium-up-3 large-up-3 eco-budget-panel" data-equalize-by-row="true"
               data-equalizer>

            <div cell class="medium-12">
              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-6 cell">
                    <h4>{{ $t('household.revenue-side') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.salaries') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.earnings"
                                 disabled name="salaries"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.officials-payments') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.official_payments"
                                 disabled name="officialsPayments"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.pension-payments') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.pension_payments"
                                 disabled name="pensionPayments"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.taxes') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.tax_household"
                                 disabled name="taxes"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.unemployment-benefit') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.unemployment_payments"
                                 disabled name="unemploymentBenefit"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.state-support') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.state_support_household"
                                 disabled name="stateSupport"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.interest-income') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.interest_income"
                                 disabled name="interestIncome"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('household.sum-total-revenue') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.household_income"
                                 disabled name="sumTotalRevenue"></NumberField>
                  </div>
                </div>
              </div>

              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <h4>{{ $t('household.costs') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.consumer-spending') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.household_consumption"
                                 disabled name="consumerSpending"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.interest-costs') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.interest_costs"
                                 disabled name="interestCosts"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('household.sum-total-costs') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.household_costs"
                                 disabled name="sumTotalCosts"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle  eco-style-bold">{{ $t('household.disposable-income') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.disposable_income"
                                 disabled name="disposableIncome"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle  eco-style-bold">{{
                        $t('household.disposable-income-household')
                      }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.disposable_income_household"
                                 disabled name="disposableIncomeHousehold"></NumberField>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="medium-4 columns eco-budget-panel" data-equalizer-watch>
            <div cell class="medium-12">
              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-12 cell">
                    <h4>{{ $t('household.savings-volume') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.savings-volume-begin') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.savings_volume_begin"
                                 disabled name="savingsVolumeBegin"></NumberField>
                  </div>
                </div>

                <!--div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.savings-interest-rate') }}</label>
                  </div>
                  <div class="medium-5 cell">
                      <Field name="payments" as="input" type="number"
                             v-model="item.interest_income" disabled></Field>
                  </div>
                </div-->

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.savings-payments') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.savings_payments"
                                 disabled name="payouts"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle eco-style-bold">{{ $t('household.savings-volume-end') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.savings_volume_end"
                                 disabled name="savingsVolumeEnd"></NumberField>
                  </div>
                </div>
              </div>

              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-12 cell">
                    <h4>{{ $t('household.credits-volume') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.credits-volume-begin') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.credit_volume_begin"
                                 disabled name="creditsVolumeBegin"></NumberField>
                  </div>
                </div>

                <!--div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.credit-interest-rate') }}</label>
                  </div>
                  <div class="medium-5 cell">
                      <Field name="borrowing" as="input" type="number"
                             v-model="item.interest_costs" disabled></Field>
                  </div>
                </div-->

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.credit-payments') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.credit_payments"
                                 disabled name="loanRepayment"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle  eco-style-bold">{{ $t('household.credits-volume-end') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.credit_volume_end"
                                 disabled name="creditsEnd"></NumberField>
                  </div>
                </div>
              </div>

              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle  eco-style-bold">{{ $t('household.manpower-supply') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.manpower_reserve"
                                 disabled name="manpowerSupply"></NumberField>
                  </div>
                </div>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle  eco-style-bold">{{ $t('household.unemployment-people') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.unemployment_people"
                                 disabled name="unemploymentPeople"></NumberField>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="medium-4 columns eco-budget-panel" data-equalizer-watch>
            <div cell class="medium-12">
              <div class="eco-grid-container">
                <div class="grid-x">
                  <div class="medium-12 cell">
                    <h4>{{ $t('household.prosperity-points') }}</h4>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('category.food') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.consumption_prosperity_points_food"
                                 disabled="true" name="prosperityPointsFood"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('category.clothes') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.consumption_prosperity_points_clothes"
                                 disabled="true" name="prosperityPointsClothes"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('category.healthy') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.consumption_prosperity_points_healthy"
                                 disabled="true" name="prosperityPointsHealthy"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('category.education') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.consumption_prosperity_points_education"
                                 disabled="true" name="prosperityPointsEducation"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('category.communication') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.consumption_prosperity_points_communication"
                                 disabled="true"
                                 name="prosperityPointsCommunication"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('category.leisure') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.consumption_prosperity_points_leisure"
                                 disabled="true" name="prosperityPointsLeisure"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('category.automobile') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.consumption_prosperity_points_automobile"
                                 disabled="true" name="prosperityPointsAutomobile"></NumberField>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('category.living') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.consumption_prosperity_points_living"
                                 disabled="true" name="prosperityPointsLiving"></NumberField>
                  </div>
                </div>

                <hr class="eco-horizontal-line"/>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('household.total') }}</label>
                  </div>
                  <div class="medium-5 cell">
                    <NumberField v-model="item.consumption_prosperity_points"
                                 disabled name="consumptionProducts"></NumberField>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </ValidationForm>
</template>

<script>
import {Form as ValidationForm} from 'vee-validate';
import NumberField from "../../NumberField";

export default {
  name: "HouseholdBudgetAccount",
  components: {
    NumberField,
    ValidationForm,
  },
  data() {
    const schema = {
      //earnings: 'decimal',
    };
    return {
      schema,
      item: {},
    };
  },
  methods: {},
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.householdBudgetAccount.householdBudgetAccount;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.householdBudgetAccount.householdBudgetAccount);
        }
    );
    if (this.$store.state.householdBudgetAccount) {
      this.item = Object.assign({}, this.$store.state.householdBudgetAccount.householdBudgetAccount);
    }
  },
  unmounted() {
    this.unwatch();
  },
}
</script>

<style scoped>


</style>