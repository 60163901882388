<template>

  <div class="eco-gameboard-sidenav" v-bind:class="{ open: isSidenavOpen }">
    <button class="sidenav-close-button clear secondary" type="button" @click="closeSidenav"><i
        area-hidden="true" class="far fa-window-close"></i></button>
    <ul class="vertical menu accordion-menu" data-accordion-menu>
      <slot name="nav"></slot>
      <div class="divider"></div>
      <li class="eco-menu-item-color eco-menu-button-first" v-bind:class="themeColor"
          @click="closeSidenav"><a href="#dataView">{{ $t('game.data-input') }}</a>
      </li>
      <li class="eco-menu-item-color" v-bind:class="themeColor"
          @click="closeSidenav"><a href="#budgetView">{{ textBudgetView }}</a>
      </li>
      <span v-bind:class="disableLinkCloseRound">
            <li class="eco-menu-item-color" v-bind:class="themeColor"
                @click="closeGameRound"><a>{{ $t('game.close-round') }}</a>
            </li>
         </span>
      <li class="eco-menu-item-color eco-menu-button-last" v-bind:class="themeColor"
          @click="closeSidenav"><a href="#chartView">Charts</a>
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  name: "GameboardSidenav.vue",

  props: {
    gameBoard: {
      type: String,
      required: true
    },
    textBudgetView: {
      type: String,
      required: true
    },
  },
  methods: {
    closeSidenav() {
      this.$store.commit('sidenav/closeSidenav');
    },
    closeGameRound() {
      this.closeSidenav();
      this.$emit('closeRoundEvent');
    }
  },
  computed: {
    themeColor() {
      return this.$store.state.theme.themeColorClass;
    },
    isSidenavOpen() {
      return this.$store.state.sidenav.isSidenavOpen;
    },
    disableLinkCloseRound() {
      if (!this.isCalculated || this.inputState)
        return "link-disabled";
      else
        return "";
    },
    inputState() {
      if (this.gameBoard === 'household') {
        return this.$store.getters['playground/inputHouseholdClosed'];
      }
      if (this.gameBoard === 'economy') {
        return this.$store.getters['playground/inputEconomyClosed'];
      }
      if (this.gameBoard === 'government') {
        return this.$store.getters['playground/inputGovernmentClosed'];
      }
      return false;
    },
    isCalculated() {
      if (this.gameBoard === 'household') {
        return this.$store.state.householdBudgetAccount.calculated;
      }
      if (this.gameBoard === 'economy') {
        return this.$store.state.economyBudgetAccount.calculated;
      }
      if (this.gameBoard === 'government') {
        return this.$store.state.governmentBudgetAccount.calculated;
      }
      return false;
    }
  },
}
</script>

<style scoped>

</style>