@<template>
    <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">

      <TopFormBar :info-text="message" :info-type="messageType" :save-button="true"
                  :title="$t('website-admin.form-title')" class="eco-form-bar"/>

      <div class="grid-container eco-form-container">
        <div class="medium-5 cell">
          <Field v-model="currentPage" as="select" name="page" @click="setPage" :disabled="noPages">
            <option disabled value="">{{ $t('website-admin.page-option') }}</option>
            <option v-for="option in this.pageObj" v-bind:value="option.text" v-bind:key="option.page_id">
              {{ option.text }}
            </option>
          </Field>
          <ErrorMessage as="small" class="error" name="page"></ErrorMessage>
        </div>
      </div>

      <div class="eco-form-container">
        <div class="gid-x grid-padding-x" data-equalizer>
          <div class="medium-12 columns eco-panel-left" data-equalizer-watch>
            <div class="medium-3 cell">
              <Field v-model="selectedHeaderId" as="select" name="selectHeader"
                     @change="setHeader" :disabled="noPages">
                <option disabled value="">{{ $t('website-admin.header-option') }}</option>
                <option v-for="(obj,i) in headerObj" v-bind:value="obj.text_id" v-bind:key="obj.text_id">
                  Header: {{ i+1 }}/{{ countHeader }} &hellip; TEXT_ID={{ obj.text_id }}
                </option>
              </Field>
              <ErrorMessage as="small" class="error" name="headerText"></ErrorMessage>
            </div>
            <div class="medium-6 cell">
              <Field v-model="header.text" as="textarea" name="headerText" type="text" rows="3"
                     @focus="headerFocus = true" @blur="headerFocus= false" :disabled="!header.text"></Field>
              <ErrorMessage as="small" class="error" name="headerText"></ErrorMessage>
            </div>

            <div class="medium-3 cell">
              <Field v-model="selectedTextId" as="select" name="selectContent"
                     @change="setText" :disabled="noPages">
                <option disabled value="">{{ $t('website-admin.text-option') }}</option>
                <option v-for="(obj,i) in textObj" v-bind:value="obj.text_id" v-bind:key="obj.text_id">
                  Text: {{ i+1 }}/{{ countText }} &hellip; TEXT_ID={{ obj.text_id}}
                </option>
              </Field>
              <ErrorMessage as="small" class="error" name="contentText"></ErrorMessage>
            </div>
            <div class="medium-6 cell">
              <Field v-model="contentText.text" as="textarea" name="contentText" type="text" rows="8"
                     @focus="textFocus = true" @blur="textFocus = false" :disabled="!contentText.text"></Field>
              <ErrorMessage as="small" class="error" name="contentText"></ErrorMessage>
            </div>

            <div class="medium-3 cell">
              <Field v-model="selectedFooterId" as="select" name="selectFooter"
                     @change="setFooter" :disabled="footerReadOnly">
                <option disabled value="">{{ $t('website-admin.footer-option') }}</option>
                <option v-for="(obj,i) in footerObj" v-bind:value="obj.text_id" v-bind:key="obj.text_id">
                  Footer: {{ i+1 }}/{{ countFooter }} &hellip; TEXT_ID={{ obj.text_id }}
                </option>
              </Field>
              <ErrorMessage as="small" class="error" name="footerText"></ErrorMessage>
            </div>
            <div class="medium-6 cell">
              <Field v-model="footer.text" as="textarea" name="footerText" type="text" rows="3"
                     @focus="footerFocus = true" @blur="footerFocus= false" :disabled="!footer.text"></Field>
              <ErrorMessage as="small" class="error" name="footerText"></ErrorMessage>
            </div>

          </div>
        </div>

        <div class="medium-12 columns eco-panel-right" data-equalizer-watch>
          <div class="medium-6 cell" v-if="headerFocus">
            <div v-html="header.text"></div>
          </div>
          <div class="medium-6 cell" v-if="textFocus">
            <div v-html="contentText.text"></div>
          </div>
          <div class="medium-6 cell" v-if="footerFocus">
            <div v-html="footer.text"></div>
          </div>
        </div>
      </div>

    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from 'axios';
import TopFormBar from "../../TopFormBar";


export default {
  name: "AdminWebsiteForm.vue",
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
    TopFormBar,
  },
  data() {
    return {
      message: "",
      messageType: undefined,
      headerFocus: false,
      footerFocus: false,
      textFocus: false,
      currentPage: null,
      selectedHeaderId: null,
      selectedFooterId: null,
      selectedTextId: null,
      header: [],
      footer: [],
      contentText: [],
      headerObj: this.$store.getters['websiteBoard/headerText'](this.currentPage),
      footerObj: this.$store.getters['websiteBoard/footerText'](this.currentPage),
      textObj: this.$store.getters['websiteBoard/contentText'](this.currentPage),
      pageObj: this.$store.getters['websiteBoard/website']
    };
  },
  methods: {
    setPage() {
      this.headerObj = this.$store.getters['websiteBoard/headerText'](this.currentPage);
      this.footerObj = this.$store.getters['websiteBoard/footerText'](this.currentPage);
      this.textObj = this.$store.getters['websiteBoard/contentText'](this.currentPage);
      this.header = [];
      this.footer = [];
      this.contentText = [];
      this.selectedHeaderId = null;
      this.selectedFooterId = null;
      this.selectedTextId = null;
    },
    setHeader() {
      const index = this.headerObj.findIndex(obj => obj.text_id==this.selectedHeaderId);
      this.header = this.headerObj[index];
    },
    setFooter() {
      const index = this.footerObj.findIndex(obj => obj.text_id==this.selectedFooterId);
      this.footer = this.footerObj[index];
    },
    setText() {
      const index = this.textObj.findIndex(obj => obj.text_id==this.selectedTextId);
      this.contentText = this.textObj[index];
    },
    save() {
      const obj = [];
      if(Object.keys(this.header).length > 0 && this.header.constructor === Object) {
        obj.push(this.header);
      }
      if(Object.keys(this.footer).length > 0 && this.footer.constructor === Object) {
        obj.push(this.footer);
      }
      if(Object.keys(this.contentText).length > 0 && this.contentText.constructor === Object) {
        obj.push(this.contentText);
      }
      const content = {'contentText': obj};

      axios.post(url, {'action': 'save-website-page',content})
          .then(() => {
            if(this.countHeader > 0) {
              const idxHeader = this.headerObj.findIndex(obj => obj.text_id == this.header.text_id);
              this.$store.commit('websiteBoard/replaceHeaderText', {
                'page': this.currentPage,
                'pos': idxHeader,
                'item': this.header
              });
            }
            if(this.countFooter > 0) {
              const idxFooter = this.footerObj.findIndex(obj => obj.text_id==this.footer.text_id);
              this.$store.commit('websiteBoard/replaceFooterText', {
                'page': this.currentPage,
                'pos': idxFooter,
                'item': this.footer
              });
            }
            if(this.countText > 0) {
              const idxText = this.textObj.findIndex(obj => obj.text_id==this.textObj.text_id);
              this.$store.commit('websiteBoard/replaceContentText', {
                'page': this.currentPage,
                'pos': idxText,
                'item': this.contentText
              });
            }
            this.showMessage(this.$t('messages.save-successful'), this.$MSG_TYPE_SUCCESS);
          })
          .catch((error) => alert(error.response.data.message));
    },
    showMessage(msg, type = 0) {
      this.message = msg;
      this.messageType = type;
      setTimeout(() => {
        this.message = "";
      }, 2000);
    },
  },
  computed: {
    noPages() {
      return this.pageObj.length===0 ? true : false;
    },
    countHeader() {
      return this.$store.getters['websiteBoard/headerTextLength'](this.currentPage);
    },
    countFooter() {
      return this.$store.getters['websiteBoard/footerTextLength'](this.currentPage);
    },
    countText() {
      return this.$store.getters['websiteBoard/contentTextLength'](this.currentPage);
    },
    footerReadOnly() {
      return this.currentPage === "main" || this.pageObj.length === 0 ? true : false;
    }
  },
  mounted() {
    const payload = {'language': this.$i18n.locale};
    this.$store.dispatch('websiteBoard/reloadPage',payload);
  }
}
</script>

<style scoped>

</style>