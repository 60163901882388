<template>
  <div class="eco-table">
    <div class="grid-x grid-margin-x">
      <div class="button-group cell shrink">
        <button class="button primary float-left eco-button eco-group-button" type="button"
                @click="refresh"><i class="fas fa-undo-alt"></i>
        </button>
      </div>
    </div>
    <div>
      <table>
        <thead class="eco-row-header">
        <tr>
          <th>{{ $t('parameter-set.parameter-set-id') }}</th>
          <th>{{ $t('parameter-set.license-key') }}</th>
          <th>{{ $t('parameter-set.name') }}</th>
          <th>{{ $t('parameter-set.created') }}</th>
          <th>{{ $t('parameter-set.modified') }}</th>
          <th class="eco-row-header-action"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in rows" v-bind:key="row" class="eco-table-border">
          <td>{{ row.parameter_set_id }}</td>
          <td>{{ row.license_key }}</td>
          <td>{{ row.name }}</td>
          <td>{{ row.created }}</td>
          <td>{{ row.modified }}</td>
          <td>
            <div class="grid-x grid-margin-x">
              <div class="button-group cell shrink">
                <button class="button primary eco-button eco-group-button" type="button"
                        @click="edit(row)"><i class="fas fa-edit"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/*global url*/
export default {
  name: "ParameterSetTable",
  computed: {
    rows() {
      return this.$store.state.parameterSets.parameterSets;
    }
  },
  methods: {
    edit(item) {
      this.$store.commit('parameterSets/setSelectedParameterSet', item);
      this.$router.push(url + '/parameter-set/edit');
    },
    refresh() {
      this.$store.commit('games/clearSelectedGame');
      this.$store.dispatch('parameterSets/reloadParameterSets');
    },
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>