<template>

  <ValidationForm v-if="item" v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">
      <TopFormBar :info-text="message" :info-type="messageType" :save-button="!isDisabled" class="eco-game-form-bar"/>

      <div class="grid-container eco-container">

        <div cell class="medium-12">
          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-6 cell">
                <h4>{{ $t('common.population') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.household-cnt') }} </label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.common_data.household_count" name="householdCount">
                  <NumberField v-model="item.common_data.household_count" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="householdCount"></ErrorMessage>
              </div>
            </div>
          </div>

          <div class=" grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-8 cell">
                <h4>{{ $t('common.employment-rate') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.employment-rate-adults') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.common_data.employment_rate_adults" :disabled="isDisabled" as="input" name="employmentRateAdults"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="employmentRateAdults"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.employment-rate-children') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.common_data.employment_rate_children" :disabled="isDisabled" as="input" name="employmentRateChildren"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="employmentRateChildren"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.employment-rate-pensioners') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.common_data.employment_rate_pensioners" :disabled="isDisabled" as="input" name="employmentRatePensioners"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="employmentRatePensioners"></ErrorMessage>
              </div>
            </div>
            <hr class="eco-horizontal-split-line"/>
          </div>

          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-8 cell">
                <h4>{{ $t('common.interest-rates') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.deposit-interest-rate') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.common_data.deposit_interest_rate" :disabled="isDisabled" as="input" name="depositInterestRate"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="depositInterestRate"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.loan-interest-rate') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.common_data.loan_interest_rate" :disabled="isDisabled" as="input" name="loanInterestRate"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="loanInterestRate"></ErrorMessage>
              </div>
            </div>
          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div cell class="medium-6">
          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-6 cell">
                <h4>{{ $t('common.foreign-trade') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.import-material-costs') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.common_data.import_material_costs" :disabled="isDisabled" as="input" name="importMaterialCosts"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="importMaterialCosts"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.export-earnings') }} (%)</label>
              </div>
              <div class="medium-3 cell">
                <Field v-model="item.common_data.export_earnings" :disabled="isDisabled" as="input" name="exportEarnings"
                       type="number"></Field>
                <ErrorMessage as="small" class="error" name="exportEarnings"></ErrorMessage>
              </div>
            </div>
            <hr class="eco-horizontal-split-line"/>
          </div>

          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-8 cell">
                <h4>{{ $t('common.special-payments') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.special-amount-household') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.common_data.special_amount_household" name="specialAmountHousehold">
                  <NumberField v-model="item.common_data.special_amount_household"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="specialAmountHousehold"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.special-amount-economy') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.common_data.special_amount_economy" name="specialAmountEconomy">
                  <NumberField v-model="item.common_data.special_amount_economy"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="specialAmountEconomy"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 cell">
                <label class="text-left middle">{{ $t('common.special-amount-government') }} </label>
              </div>
              <div class="medium-4 cell">
                <Field v-model="item.common_data.special_amount_government" name="specialAmountGovernment">
                  <NumberField v-model="item.common_data.special_amount_government"
                               :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="specialAmountGovernment"></ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from "axios";
import TopFormBar from "../TopFormBar";
import NumberField from "../NumberField";

export default {
  name: "CommonForm",
  components: {
    NumberField,
    TopFormBar,
    ValidationForm,
    Field,
    ErrorMessage,
  },
  props: {
    disabled: {
      default: true,
      type: Boolean
    },
    saveButton: {
      default: true,
      type: Boolean
    }
  },
  data() {
    const schema = {
      householdCount: 'required|integer_positive',
      employmentRateAdults: 'required|integer_positive',
      employmentRateChildren: 'required|integer_positive',
      employmentRatePensioners: 'required|integer_positive',
      depositInterestRate: 'required|integer_positive',
      loanInterestRate: 'required|integer_positive',
      importMaterialCosts: 'required|integer_positive',
      exportEarnings: 'required|integer_positive',
      specialAmountHousehold: 'required',
      specialAmountEconomy: 'required',
      specialAmountGovernment: 'required',
    };
    return {
      schema,
      item: undefined,
      message: "",
      messageType: undefined,
    };
  },
  methods: {
    save() {
      axios.post(url, {'action': 'save-data-input-common', 'item': this.item})
          .then(() => {
            this.$router.push(url);
            this.showMessage(this.$t('messages.save-successful'), this.$MSG_TYPE_SUCCESS);
          })
          .catch((error) => alert(error.response.data.message));
    },
    showMessage(msg, type = 0) {
      this.message = msg;
      this.messageType = type;
      setTimeout(() => {
        this.message = "";
      }, 2000);
    },
  },
  computed: {
    isDisabled() {
      return this.$store.state.playground.activeGame.closed ? true : this.$store.state.playground.lastGameRound.is_synchronized;
    },
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.dataInputCommon.dataInputCommon;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.dataInputCommon.dataInputCommon);
        }
    );
    if (this.$store.state.dataInputCommon.dataInputCommon) {
      this.item = Object.assign({}, this.$store.state.dataInputCommon.dataInputCommon);
    }
  },
  unmounted() {
    this.unwatch();
  }
}
</script>

<style scoped>

</style>