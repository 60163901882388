<template>

  <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,refresh)">

      <TopFormBar :refresh-button="true" class="eco-game-form-bar"/>
      <div class="grid-container eco-container">
        <div class="grid-x">
          <!--button type="submit" class="button primary float-right"><i class="fa fa-check"></i></button-->
        </div>

        <div class="medium-6 cell">
          <div class="eco-grid-container">
            <div class="grid-x grid-margin-x">
            </div>
            <div class="grid-x grid-margin-x">
              <div class="medium-12 cell">
                <h4>{{ $t('game.round-input-closed') }}</h4>
              </div>
            </div>

            <div v-if="!$store.state.playground.activeGame.closed">
              <div class="grid-x grid-margin-x">
                <div class="medium-4 cell">
                  <label class="text-left middle">{{ $t('group-title.household') }}</label>
                </div>
                <div class="medium-2 cell">
                  <div v-if="inputStateHousehold">
                    <button type="button"><i class="fa fa-check"></i></button>
                  </div>
                  <div v-else>
                    <button type="button"><i class="fa fa-exclamation"></i></button>
                  </div>
                </div>
                <div class="medium-2 cell">
                  <div v-if="roundSynchronized">
                    <button class="button eco-button eco-group-button eco-user-undo-button" type="button"
                            @click="resetStateHousehold"><i class="fas fa-user-times"></i></button>
                  </div>
                </div>
              </div>

              <div class="grid-x grid-margin-x">
                <div class="medium-4 cell">
                  <label class="text-left middle">{{ $t('group-title.economy') }} </label>
                </div>
                <div class="medium-2 cell">
                  <div v-if="inputStateEconomy">
                    <button type="button"><i class="fa fa-check"></i></button>
                  </div>
                  <div v-else>
                    <button type="button"><i class="fa fa-exclamation"></i></button>
                  </div>
                </div>
                <div class="medium-2 cell">
                  <div v-if="roundSynchronized">
                    <button class="button eco-button eco-group-button eco-user-undo-button" type="button"
                            @click="resetStateEconomy"><i class="fas fa-user-times"></i></button>
                  </div>
                </div>
              </div>

              <div class="grid-x grid-margin-x">
                <div class="medium-4 cell">
                  <label class="text-left middle">{{ $t('group-title.government') }} </label>
                </div>
                <div class="medium-2 cell">
                  <div v-if="inputStateGovernment">
                    <button type="button"><i class="fa fa-check"></i></button>
                  </div>
                  <div v-else>
                    <button type="button"><i class="fa fa-exclamation"></i></button>
                  </div>
                </div>
                <div class="medium-2 cell">
                  <div v-if="roundSynchronized">
                    <button class="button eco-button eco-group-button eco-user-undo-button" type="button"
                            @click="resetStateGovernment"><i class="fas fa-user-times"></i></button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div v-if="!$store.state.playground.activeGame.closed">
          <div cell class="medium-6">
            <div class="eco-grid-container">
              <div class="grid-x grid-margin-x">
                <div class="medium-10 cell">
                  <h5>{{ $t('game.round-input-synchronized') }}</h5>
                </div>
                <div class="medium-2 cell">
                  <div v-if="roundSynchronized">
                    <button type="button"><i class="fa fa-check"></i></button>
                  </div>
                  <div v-else>
                    <button type="button"><i class="fa fa-exclamation"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div cell class="medium-6">
          <div class="eco-grid-container">
            <div class="medium-10 cell">
              <h5>{{ $t('game.round-remarks') }}</h5>
            </div>
            <div class="grid-x grid-margin-x">
              <div class="medium-12 cell">
                <textarea v-model="item.comment" :disabled="roundSynchronized" placeholder="" rows="9"></textarea>
              </div>
            </div>
          </div>
        </div>

      </div>

    </form>
  </ValidationForm>
</template>

<script>

/*global url */
import {Form as ValidationForm} from 'vee-validate';
import TopFormBar from "../../TopFormBar";
import axios from "axios";

export default {
  name: "TrainerSummary",

  components: {
    ValidationForm,
    TopFormBar,
  },
  data() {
    const schema = {};
    return {
      schema,
      item: Object.assign({}, this.$store.state.playground.lastGameRound),
    };
  },
  methods: {
    save() {
      axios.post(url, {
        'action': 'save-game-round-comment', 'item': this.item,
      })
          .then(() => {
            this.$router.push(url);
          })
          .catch((error) => alert(error.response.data.message));
    },
    refresh() {
      this.save();
      this.$store.dispatch('playground/fetchDataInput');
    },
    resetStateHousehold() {
      axios.post(url, {
        'action': 'reset-input-state', 'role': 'household',
      })
          .then(() => this.$store.commit('playground/resetStateHousehold', false))
          .catch((error) => alert(error.response.data.message));
    },
    resetStateEconomy() {
      axios.post(url, {
        'action': 'reset-input-state', 'role': 'economy',
      })
          .then(() => this.$store.commit('playground/resetStateEconomy', false))
          .catch((error) => alert(error.response.data.message));
    },
    resetStateGovernment() {
      axios.post(url, {
        'action': 'reset-input-state', 'role': 'government',
      })
          .then(() => this.$store.commit('playground/resetStateGovernment', false))
          .catch((error) => alert(error.response.data.message));
    },
  },
  computed: {
    roundSynchronized() {
      return this.$store.state.playground.activeGame.closed ? false : !this.$store.state.playground.lastGameRound.is_synchronized;
    },
    inputStateHousehold() {
      return this.$store.getters['playground/inputHouseholdClosed'];
    },
    inputStateEconomy() {
      return this.$store.getters['playground/inputEconomyClosed'];
    },
    inputStateGovernment() {
      return this.$store.getters['playground/inputGovernmentClosed'];
    }
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.playground.lastGameRound;
        },
    );
  },
  unmounted() {
    this.unwatch();
  },

}

</script>

<style scoped>

</style>