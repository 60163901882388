import {createRouter, createWebHistory} from 'vue-router';
import Home from '../views/Home.vue';
import LandingPage from "../views/LandingPage.vue";
import LicenseBoard from '../views/LicenseBoard';
import LicenseForm from '../components/administration/license/LicenseForm';
import LicenseTable from '../components/administration/license/LicenseTable';
import AdminBoard from '../views/AdminBoard';
import AdminTable from '../components/administration/admin/AdminTable';
import AdminForm from '../components/administration/admin/AdminForm';
import TrainerTable from '../components/administration/trainer/TrainerTable';
import TrainerForm from '../components/administration/trainer/TrainerForm';
import TrainerBoard from '../views/TrainerBoard';
import AccountForm from '../components/administration/account/AccountForm';
import PlayerTable from '../components/administration/player/PlayerTable';
import PlayerForm from '../components/administration/player/PlayerForm';
import Playground from '../views/Playground';
import ParameterSetForm from '../components/game/parameter/ParameterSetForm';
import ParameterSetTable from '../components/game/parameter/ParameterSetTable';
import GameForm from '../components/game/GameForm';
import GameTable from '../components/game/GameTable';
import PasswordReset from "../views/PasswordReset";
import PasswordResetRequest from "../components/password-reset/PasswordResetRequest";
import PasswordResetConfirm from "../components/password-reset/PasswordResetConfirm";
import PasswordResetComplete from "../components/password-reset/PasswordResetComplete";
import AdminWebsiteForm from "../components/administration/website/AdminWebsiteForm";
import Explorer from "../components/Explorer";
import DownloadForm from "../components/administration/trainer/DownloadForm";

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage,
    },
    {
        path: '/login',
        name: 'Home',
        component: Home,
    },
    {
        path: '/license-board',
        name: 'LicenseBoard',
        component: LicenseBoard,
        children: [
            {
                path: '',
                component: LicenseTable,
            },
            {
                path: 'account',
                component: AccountForm,
            },
            {
                path: 'add',
                component: LicenseForm,
            },
            {
                path: 'edit',
                component: LicenseForm,
            }
        ],
    },
    {
        path: '/admin-board',
        name: 'AdminBoard',
        component: AdminBoard,
        children: [
            {
                path: '',
                redirect: '/admin-board/admins',
            },
            {
                path: 'account',
                component: AccountForm,
            },
            {
                path: 'admins',
                component: AdminTable,
            },
            {
                path: 'admin/add',
                component: AdminForm,
            },
            {
                path: 'admin/edit',
                component: AdminForm,
            },
            {
                path: 'trainers',
                component: TrainerTable,
            },
            {
                path: 'trainer/add',
                component: TrainerForm,
            },
            {
                path: 'trainer/edit',
                component: TrainerForm,
            },
            {
                path: 'parameter-set',
                component: ParameterSetTable
            },
            {
                path: 'parameter-set/edit',
                component: ParameterSetForm,
            },
            {
                path: 'games',
                component: GameTable,
                props: {editable: false, deleteable: true, adminRead: true}
            },
            {
                path: 'game/edit',
                component: GameForm,
            },
            {
                path: 'website',
                component: AdminWebsiteForm,
            },
            {
                path: 'explorer',
                component: Explorer,
            },

        ],
    },
    {
        path: '/trainer-board',
        name: 'TrainerBoard',
        component: TrainerBoard,
        children: [
            {
                path: '',
                redirect: '/trainer-board/games',
            },
            {
                path: 'account',
                component: AccountForm,
            },
            {
                path: 'players',
                component: PlayerTable,
            },
            {
                path: 'player/edit',
                component: PlayerForm,
            },
            {
                path: 'games',
                component: GameTable,
            },
            {
                path: 'game/edit',
                component: GameForm,
            },
            {
                path: 'game/add',
                component: GameForm,
            },
            {
                path: 'downloads',
                component: DownloadForm,
            },
        ],
    },
    {
        path: '/playground',
        name: 'Playground',
        component: Playground,
    },
    {
        path: '/account',
        name: 'PasswordReset',
        component: PasswordReset,
        children: [
            {
                path: '',
                component: PasswordResetRequest,
            },
            {
                path: 'password-reset-request',
                component: PasswordResetRequest,
            },
            {
                path: 'password-reset-confirm/:u/set-password/',
                component: PasswordResetConfirm,
            },
            {
                path: 'password-reset-confirm/:u/:token/',
                component: PasswordResetConfirm,
            },
            {
                path: 'password-reset-complete',
                component: PasswordResetComplete,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
