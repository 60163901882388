<template>
    <div class="">
        <input :id="name" ref="inputRef"
               v-model="formattedValue"
               :disabled="disabled"
               :name="name"
               :placeholder="placeholder"
               type="text"
        >
    </div>
</template>

<script>
import {useCurrencyInput} from "vue-currency-input";
import {watch} from "vue";

export default {
    name: "NumberField",

    props: {
        modelValue: Number,
        disabled: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        options: {
            default: () => ({
                currency: 'EUR',
                currencyDisplay: 'hidden',
            })
        },

    },

    setup(props) {
        const {
            inputRef,
            formattedValue,
            setValue,
        } = useCurrencyInput(props.options);

        watch(() => props.modelValue, (value) => {
            setValue(value);
        });

        return {
            inputRef,
            formattedValue,
        }

    },
}
</script>

<style scoped>

input[type=text] {
    text-align: right;
}

</style>