<template>

  <ValidationForm v-if="item" v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">
      <TopFormBar :info-text="message" :info-type="messageType" :save-button="saveButton" class="eco-game-form-bar"/>

      <div class="grid-container eco-container">

        <div class="medium-12 cell">
          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-5 cell">
                <h4>{{ $t('household.consumption') }}</h4>
              </div>
              <div class="medium-4 cell margin-1">
                <div class="medium-8 cell">
                  <ToolTipLabel :disabled="isDisabled"
                                :label="$t('household.product-demand')"
                                :text="$t('tooltip.product-demand')">
                  </ToolTipLabel>
                </div>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-5 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('category.food')"
                              :text="$t('tooltip.food-household')">
                </ToolTipLabel>
              </div>
              <div class="medium-4 cell">
                <SpinFieldPlus v-model="item.household_data.consumption_food" :disabled="isDisabled" :max-distance="1" max="8"
                               min="0"/>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-5 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('category.clothes')"
                              :text="$t('tooltip.clothes-household')">
                </ToolTipLabel>
              </div>
              <div class="medium-4 cell">
                <SpinFieldPlus v-model="item.household_data.consumption_clothes" :disabled="isDisabled" :max-distance="1" max="8"
                               min="0"/>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-5 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('category.healthy')"
                              :text="$t('tooltip.healthy-household')">
                </ToolTipLabel>
              </div>
              <div class="medium-4 cell">
                <SpinFieldPlus v-model="item.household_data.consumption_healthy" :disabled="isDisabled" :max-distance="1" max="8"
                               min="0"/>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-5 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('category.education')"
                              :text="$t('tooltip.education-household')">
                </ToolTipLabel>
              </div>
              <div class="medium-4 cell">
                <SpinFieldPlus v-model="item.household_data.consumption_education" :disabled="isDisabled" :max-distance="1"
                               max="8"
                               min="0"/>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-5 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('category.communication')"
                              :text="$t('tooltip.communication-household')">
                </ToolTipLabel>
              </div>
              <div class="medium-4 cell">
                <SpinFieldPlus v-model="item.household_data.consumption_communication" :disabled="isDisabled" :max-distance="1"
                               max="8" min="0"/>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-5 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('category.leisure')"
                              :text="$t('tooltip.leisure-household')">
                </ToolTipLabel>
              </div>
              <div class="medium-4 cell">
                <SpinFieldPlus v-model="item.household_data.consumption_leisure" :disabled="isDisabled" :max-distance="1" max="8"
                               min="0"/>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-5 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('category.automobile')"
                              :text="$t('tooltip.automobile-household')">
                </ToolTipLabel>
              </div>
              <div class="medium-4 cell">
                <SpinFieldPlus v-model="item.household_data.consumption_automobile" :disabled="isDisabled" :max-distance="1"
                               max="8"
                               min="0"/>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-5 cell">
                <ToolTipLabel :disabled="isDisabled"
                              :label="$t('category.living')"
                              :text="$t('tooltip.living-household')">
                </ToolTipLabel>
              </div>
              <div class="medium-4 cell">
                <SpinFieldPlus v-model="item.household_data.consumption_living" :disabled="isDisabled" :max-distance="1" max="8"
                               min="0"/>
              </div>
            </div>
            <hr class="eco-horizontal-split-line"/>
          </div>
        </div>

        <div cell class="medium-6">
          <div class="grid-container eco-grid-container">
            <div class="grid-x">
              <div class="medium-12 cell">
                <h4>{{ $t('household.bank-transactions') }}</h4>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 large-4 cell">
                <label class="text-left middle">{{ $t('household.savings') }}</label>
              </div>
              <div class="medium-5 cell">
                <Field v-model="item.household_data.savings_payments" name="savingsPayments">
                  <NumberField v-model="item.household_data.savings_payments" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="savingsPayments"></ErrorMessage>
              </div>
            </div>

            <div class="grid-x">
              <div class="medium-6 large-4 cell">
                <label class="text-left middle">{{ $t('household.payouts') }} </label>
              </div>
              <div class="medium-5 cell">
                <Field v-model="item.household_data.savings_payouts" name="savingsPayouts">
                  <NumberField v-model="item.household_data.savings_payouts" :disabled="isDisabled"></NumberField>
                </Field>
                <ErrorMessage as="small" class="error" name="savingsPayouts"></ErrorMessage>
              </div>
            </div>

          </div>

        </div>

      </div>

    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import SpinFieldPlus from "../../SpinFieldPlus";
import axios from "axios";
import TopFormBar from "../../TopFormBar";
import NumberField from "../../NumberField";
import ToolTipLabel from "../../ToolTipLabel";

export default {
  name: "HouseholdForm",

  components: {
    NumberField,
    TopFormBar,
    ValidationForm,
    Field,
    ErrorMessage,
    SpinFieldPlus,
    ToolTipLabel,
  },
  props: {
    disabled: {
      default: true,
      type: Boolean
    },
    saveButton: {
      default: true,
      type: Boolean
    }
  },
  data() {
    const schema = {
      savingsPayments: 'required',
      savingsPayouts: 'required'
    };
    return {
      schema,
      item: undefined,
      message: "",
      messageType: undefined,
      isLocked: this.disabled,
    };
  },
  methods: {
    save() {
      this.$store.commit('loadingProgress/loading', true);
      axios.post(url, {
        'action': 'save-data-input-household', 'item': this.item,
        'householdBudgetAccount': this.$store.state.householdBudgetAccount.householdBudgetBase,
        'simulationMode': this.$store.state.playground.simulationMode
      })
          .then(response => this.$store.commit('householdBudgetAccount/replaceHouseholdBudgetAccount', response.data.householdBudgetAccount))
          .then(() => {
            this.$router.push(url);
            this.showMessage(this.$t('messages.refresh-successful'), this.$MSG_TYPE_SUCCESS);
            this.$store.commit('loadingProgress/loading', false);
          })
          .catch((error) => alert(error.response.data.message));
    },
    saveNoBudgetAccount() {
      axios.post(url, {
        'action': 'save-data-household-no-budget-account', 'item': this.item
      })
          .then(() => {
            this.$store.commit('playground/setSimulationMode',true);
            this.$router.push(url);
          })
          .catch((error) => alert(error.response.data.message));
    },
    calculateBase() {
      this.$store.commit('loadingProgress/loading', true);
      this.$store.commit('playground/setSimulationMode',false);
      this.$store.commit('statistics/clearGameChartData', {});
      axios.post(url, {'action': 'calculate-base-household'})
          .then(response => {
            this.$store.commit('householdBudgetAccount/replaceHouseholdBudgetBase', response.data.householdBudgetAccount);
            this.$store.commit('statistics/replaceGameChartData', response.data.gameChartData);
            this.$store.commit('history/replaceHistory', response.data.historyData);
            this.$store.commit('loadingProgress/loading', false);
          })
          .then(() => {
            this.$router.push(url);
          })
          .catch((error) => alert(error.response.data.message));
    },
    showMessage(msg, type = 0) {
      this.message = msg;
      this.messageType = type;
      setTimeout(() => {
        this.message = "";
      }, 2000);
    },
    enableInput() {
      this.isLocked = false;
    },
    disableInput() {
      this.isLocked = true;
    },
  },
  computed: {
    isDisabled() {
      return this.disabled ? this.isLocked : this.$store.getters['playground/inputHouseholdClosed'];
    },
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.dataInputHousehold.dataInputHousehold;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.dataInputHousehold.dataInputHousehold);
        }
    );
    this.unwatch_save = this.$store.watch(
        (state) => {
          return state.playground.inputStateHousehold;
        },
    );
    if (this.$store.state.dataInputHousehold.dataInputHousehold) {
      this.item = Object.assign({}, this.$store.state.dataInputHousehold.dataInputHousehold)
    }
  },
  unmounted() {
    this.unwatch();
    this.unwatch_save();
  },
  updated() {
    if (this.saveButton) {
      this.calculateBase();
    }
  }
}

</script>

<style scoped>

input[type=number] {
  text-align: right;
}

</style>