<template>
  <ValidationForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,save)">

      <TopFormBar :save-button="true" :title="$t('license-board.form-title')" class="eco-form-bar"/>

      <div class="grid-container eco-form-container">
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('license-board.license-holder') }}
              <Field v-model="item.license_holder" as="input" name="licenseHolder" type="text"></Field>
              <ErrorMessage as="small" class="error" name="licenseHolder"></ErrorMessage>
            </label>
          </div>
          <div class="medium-6 cell">
            <label>{{ $t('license-board.admin-account') }}
              <Field v-model="item.admin_account" as="input" name="adminAccount" placeholder="e-mail"
                     type="text"></Field>
              <ErrorMessage as="small" class="error" name="adminAccount"></ErrorMessage>
            </label>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-6 cell">
            <label>{{ $t('license-board.valid-from') }}
              <input v-model="item.valid_from" placeholder="YYYY-MM-DDT00:00:00" type="text">
            </label>
          </div>
          <div class="medium-6 cell">
            <label>{{ $t('license-board.valid-to') }}
              <input v-model="item.valid_to" placeholder="YYYY-MM-DDT00:00:00" type="text">
            </label>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="medium-1 cell">
            <label class="text-left middle">{{ $t('license-board.enabled') }} </label>
          </div>
          <div class="medium-2 cell">
            <div class="switch-large">
              <input id="enabled-on-off" v-model="item.enabled" class="switch-input" type="checkbox">
              <label class="switch-paddle eco-switch-paddle" for="enabled-on-off">
                <span aria-hidden="true" class="switch-active">{{ $t('switch.on') }}</span>
                <span aria-hidden="true" class="switch-inactive">{{ $t('switch.off') }}</span>
              </label>
            </div>
          </div>
        </div>

      </div>

    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from 'axios';
import TopFormBar from "../../TopFormBar";

export default {
  name: 'LicenseForm',
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
    TopFormBar,
  },
  data() {
    const schema = {
      licenseHolder: 'required',
      adminAccount: 'required|email',
    };
    return {
      schema,
      item: Object.assign({}, this.$store.state.licenseBoard.selectedLicense),
    };
  },
  methods: {
    save() {
      axios.post(url, {'action': 'save', 'item': this.item})
          .then(() => this.$router.push(url))
          .catch((error) => alert(error.response.data.message));
    },
  },
};
</script>

<style scoped>

input[type=text], input[type=datetime-local],
input[type=date-local], input[type=date], input[type=password],
select, optgroup, textarea {
  border-radius: var(--border-radius);
  height: 2rem;
  font-size: 100%;
}

</style>