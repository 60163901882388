<template>

  <hr>
  <div class="button-group">
    <a class="button eco-group-button eco-navbutton-first"
       @click="showCommonParameterView">{{ $t('parameter.common-parameter') }}</a>
    <a class="button eco-group-button"
       @click="showGovernmentParameterView">{{ $t('parameter.government-parameter') }}</a>
    <a class="button eco-group-button"
       @click="showEconomyParameterView">{{ $t('parameter.economy-parameter') }}</a>
    <a class="button eco-group-button eco-navbutton-last"
       @click="showHouseholdParameterView">{{ $t('parameter.household-parameter') }}</a>
  </div>
  <hr>
  <component :is="currentView" :disabled="isDisabled"></component>
</template>

<script>
import CommonParameterForm from './CommonParameterForm';
import GovernmentParameterForm from './GovernmentParameterForm';
import EconomyParameterForm from './EconomyParameterForm';
import HouseholdParameterForm from './HouseholdParameterForm';

export default {
  name: "ParameterNavigationBar",
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      currentView: null,
    }
  },
  computed: {
    isDisabled() {
      return this.disabled ? true : false;
    },
  },
  methods: {
    showCommonParameterView() {
      this.currentView = CommonParameterForm;
    },
    showGovernmentParameterView() {
      this.currentView = GovernmentParameterForm;
    },
    showEconomyParameterView() {
      this.currentView = EconomyParameterForm;
    },
    showHouseholdParameterView() {
      this.currentView = HouseholdParameterForm;
    },
  },
  mounted() {
      this.$store.dispatch('commonParameter/reloadCommonParameter');
      this.$store.dispatch('governmentParameter/reloadGovernmentParameter');
      this.$store.dispatch('householdParameter/reloadHouseholdParameter');
      this.$store.dispatch('economyParameter/reloadEconomyParameter');
  }
}
</script>

<style scoped>

</style>