<template>
    <div class="landing-page">
        <nav v-bind:class="hideNavigation">
            <div class="header-nav" >
                <ul id="menu-header" class="menu align-center" data-magellan>
                    <div v-if="showSection1">
                      <li class="menu-item"><a href="#whatiseco">{{ $i18n.t("website-menu.what-is") }}</a></li>
                    </div>
                    <div v-if="showSection2">
                      <li class="menu-item"><a href="#trainer">{{ $i18n.t("website-menu.trainer") }}</a></li>
                    </div>
                    <div v-if="showSection3">
                      <li class="menu-item"><a href="#references">{{ $i18n.t("website-menu.references") }}</a></li>
                    </div>
                    <div v-if="showSection6">
                      <li class="menu-item"><a href="#faq">{{ $i18n.t("website-menu.faq") }}</a></li>
                    </div>
                    <div v-if="showSection8">
                      <li class="menu-item"><a href="#downloads">{{ $i18n.t("website-menu.downloads") }}</a></li>
                    </div>
                    <div v-if="showSection7">
                      <li class="menu-item"><a href="#contact">{{ $i18n.t("website-menu.contact") }}</a></li>
                    </div>
                    <li class="is-active"><a @click="goToLoginView">{{ $i18n.t("website-menu.login") }}</a></li>

                    <div v-show="isMultiLanguage">
                        <div id="menu-dropdown" class="menu-dropdown" data-dropdown-menu>
                            <li><a>Sprache</a>
                                <ul class="menu">
                                    <li><a @click="setLanguage('de')">German</a></li>
                                    <li><a @click="setLanguage('en')">English</a></li>
                                </ul>
                            </li>
                        </div>
                    </div>

                </ul>
            </div>
        </nav>
        <header v-bind:class="hideNavigation">
            <!--div class="heading-supersize" style="margin-top: 0.5em" v-html="headerText('main',1)"></div-->
            <div class="heading-supersize header-image" style="margin-top: 0.5em">
              <div v-if="showLogo">
                <img :src="imgHeader" alt="ECOMANIA Logo">
              </div>
            </div>
            <!--div class="welcome-text" v-html="headerText('main',2)"></div-->
            <div style="text-align: center; margin-top: 3em">
                <div class="excerpt" v-html="contentText('main',1)"></div>
            </div>
        </header>

        <div v-if="showSection1">
          <section id="whatiseco" class="section-main" v-bind:class="hideNavigation">
              <div v-html="headerText('whatiseco',1)"></div>

              <div class="grid-x">
                  <div v-html="contentText('whatiseco',1)"></div>
                  <div v-html="contentText('whatiseco',2)"></div>
              </div>
              <div class="grid-x">
                  <article>
                      <div class="callout secondary large eco-article">
                          <div v-html="headerText('whatiseco',2)"></div>
                          <div v-html="contentText('whatiseco',3)"></div>
                      </div>
                  </article>
                  <article>
                      <div class="callout secondary large eco-article">
                          <div v-html="headerText('whatiseco',3)"></div>
                          <div v-html="contentText('whatiseco',4)"></div>
                      </div>
                  </article>
                  <article>
                      <div class="callout secondary large eco-article">
                          <div v-html="headerText('whatiseco',4)"></div>
                          <div v-html="contentText('whatiseco',5)"></div>
                      </div>
                  </article>
                  <article>
                      <div class="callout secondary large eco-article">
                          <div v-html="headerText('whatiseco',5)"></div>
                          <div v-html="contentText('whatiseco',6)"></div>
                      </div>
                  </article>
              </div>
          </section>
        </div>

        <div v-if="showSection2">
            <section id="trainer" class="section-main" v-bind:class="hideNavigation">
                <div v-html="headerText('trainer',1)"></div>

                <div class="grid-x grid-margin-x">
                    <article>
                        <div class="callout secondary large eco-article">
                            <div v-html="headerText('trainer',2)"></div>
                            <div v-html="contentText('trainer',1)"></div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        <div v-if="showSection3">
          <section id="references" class="section-main" v-bind:class="hideNavigation">
              <div v-html="headerText('references',1)"></div>
              <div class="grid-x grid-margin-x grid-padding-x">
                  <article class="cell small-6">
                      <div class="callout success-references">
                          <div v-html="headerText('references',2)"></div>
                          <div v-html="contentText('references',1)"></div>
                      </div>
                  </article>
                  <article class="cell small-6">
                      <div class="callout success-references">
                          <div v-html="headerText('references',3)"></div>
                          <div v-html="contentText('references',2)"></div>
                      </div>
                  </article>
              </div>
          </section>
        </div>

        <div v-if="showSection4">
          <section class="section-grey" v-bind:class="hideNavigation">
              <div class="grid-x grid-margin-y">
                  <div class="cell medium-4">
                      <a class="thumbnail"><img :src="img1"
                                                width="250" height="250"
                                                :alt='$i18n.t("website-image.alt-gameboard")' class="alt-image-text"/></a>
                  </div>

                  <div class="cell medium-4">
                      <a class="thumbnail"><img :src="img2"
                                                width="250" height="250"
                                                :alt='$i18n.t("website-image.alt-household")'/></a>
                  </div>

                  <div class="cell medium-4">
                      <a class="thumbnail"><img :src="img3"
                                                width="250" height="250"
                                                :alt='$i18n.t("website-image.alt-gameclock")'/></a>
                  </div>

                  <div class="cell medium-4">
                      <a class="thumbnail"><img :src="img4"
                                                width="250" height="250"
                                                :alt='$i18n.t("website-image.alt-ipad")'/></a>
                  </div>

                  <div class="cell medium-4">
                      <a class="thumbnail"><img :src="img5"
                                                width="250" height="250"
                                                :alt='$i18n.t("website-image.alt-day1")'/></a>
                  </div>

                  <div class="cell medium-4">
                      <a class="thumbnail"><img :src="img6"
                                                width="250" height="250"
                                                :alt='$i18n.t("website-image.alt-day2")'/></a>
                  </div>
              </div>
          </section>
        </div>

        <div v-if="showSection5">
          <section id="videos" class="section-light" v-bind:class="hideNavigation">
              <div class="grid-x grid-margin-x">
                  <div class="cell medium-4">
                      <div class="video-container">
                          <iframe class="video" allowfullscreen="allowfullscreen"
                                  src="https://www.youtube.com/embed/w6R7PkZVpj4"></iframe>
                      </div>
                      <div v-html="headerText('videos',1)"></div>
                  </div>
                  <div class="cell medium-4">
                      <div class="video-container">
                          <iframe class="video" allowfullscreen="allowfullscreen"
                                  src="https://www.youtube.com/embed/nHLIAyan4UI"></iframe>
                      </div>
                      <div v-html="headerText('videos',2)"></div>
                  </div>
                  <div class="cell medium-4">
                      <div class="video-container">
                          <iframe class="video" allowfullscreen="allowfullscreen"
                                  src="https://www.youtube.com/embed/Zgi0uk8-Euc"></iframe>
                      </div>
                      <div v-html="headerText('videos',3)"></div>
                  </div>
              </div>
          </section>
        </div>

        <div v-if="showSection6">
          <section id="faq" class="section-main" v-bind:class="hideNavigation">
              <div v-html="headerText('faq',5)"></div>
              <div class="grid-x">
                  <div class="cell">
                      <ul id="accordion" class="accordion" data-accordion data-multi-expand="true"
                          data-allow-all-closed="true">
                          <li class="accordion-item" data-accordion-item>
                              <a href="#" class="accordion-title" v-html="headerText('faq', 1)"></a>
                              <div class="accordion-content" data-tab-content>
                                  <div v-html="contentText('faq',1)"></div>
                              </div>
                          </li>

                          <li class="accordion-item" data-accordion-item>
                              <a href="#" class="accordion-title" v-html="headerText('faq', 2)"></a>
                              <div class="accordion-content" data-tab-content>
                                  <div v-html="contentText('faq',2)"></div>
                              </div>
                          </li>

                          <li class="accordion-item" data-accordion-item>
                              <a href="#" class="accordion-title" v-html="headerText('faq', 3)"></a>
                              <div class="accordion-content" data-tab-content>
                                  <div v-html="contentText('faq',3)"></div>
                              </div>
                          </li>

                          <li class="accordion-item" data-accordion-item>
                              <a href="#" class="accordion-title" v-html="headerText('faq', 4)"></a>
                              <div class="accordion-content" data-tab-content>
                                  <div v-html="contentText('faq',4)"></div>
                              </div>
                          </li>

                      </ul>
                  </div>
              </div>
          </section>
        </div>

        <div v-if="showSection7">
          <section id="contact" class="section-main" v-bind:class="hideNavigation">
              <div v-html="headerText('contact',1)"></div>
              <div class="grid-x">
                  <div v-html="contentText('contact',1)"></div>
              </div>
          </section>
        </div>

        <div v-if="showSection8">
          <section id="downloads" class="section-main" v-bind:class="hideNavigation">
              <h2 style="color: #2870ed">Downloads</h2>
              <div class="grid-x">
                  <div v-for="file in downloads()" v-bind:key="file" class="cell small-3">
                      <div class="card box">
                          <a :href="'/static/storage/' + file" target="_blank">
                              <div class="doc-box"></div>
                          </a>
                          <div class="card-section">
                              <p>{{ file }}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>

        <footer>
            <section class="section-dark">
              <div class="grid-x footer" style="width: 100%; display: grid; overflow-x: auto; grid-auto-flow: column; grid-auto-columns: minmax(300px,1000px)">
                <div class="grid-x" >
                  <div v-html="footerText('main',1)"></div>
                </div>
                <div class="grid-x" >
                  <div v-html="footerText('main',2)"></div>
                </div>
                <div class="grid-x" >
                  <div v-html="footerText('main',3)"></div>
                </div>
              </div>
            </section>
        </footer>
    </div>
</template>

<script>
/*global url*/
import $ from 'jquery';
import Foundation from 'foundation-sites';
import axios from 'axios';


export default {
    name: "LandingPage",
    data() {
        return {
            languages: ['de', 'en'],
            isMultiLanguage: false,
            showLogo: true,
            showSection1: true, //what is
            showSection2: true, //trainer
            showSection3: true, //references
            showSection4: true, //images
            showSection5: true, //videos
            showSection6: true, //faq
            showSection7: true, //contact
            showSection8: true, //downloads
            isLoaded: false,
        };
    },
    methods: {
        goToLoginView() {
            const {href} = this.$router.resolve({name: 'Home'});
            window.open(href, '_self');
        },
        refresh() {
            const payload = {'language': this.$i18n.locale};
            axios.post(url, {'action': 'fetch-website-content', payload})
                .then(response => {
                    this.isLoaded = true;
                    this.$store.commit('websiteBoard/replaceContent', response.data.content);
                    this.isMultiLanguage = this.$store.state.websiteBoard.website[0]["multi-language"];
                    this.showLogo = this.$store.state.websiteBoard.website[0]["show_logo"];
                    this.showSection1 = this.$store.state.websiteBoard.website[0]["show_section_1"];
                    this.showSection2 = this.$store.state.websiteBoard.website[0]["show_section_2"];
                    this.showSection3 = this.$store.state.websiteBoard.website[0]["show_section_3"];
                    this.showSection4 = this.$store.state.websiteBoard.website[0]["show_section_4"];
                    this.showSection5 = this.$store.state.websiteBoard.website[0]["show_section_5"];
                    this.showSection6 = this.$store.state.websiteBoard.website[0]["show_section_6"];
                    this.showSection7 = this.$store.state.websiteBoard.website[0]["show_section_7"];
                    this.showSection8 = this.$store.state.websiteBoard.website[0]["show_section_8"];
                })
                .catch((error) => alert(error.response.data.message));
        },
        setLanguage(language) {
            this.$i18n.locale = language;
            this.refresh();
        },
    },
    computed: {
        hideNavigation() {
            return this.isLoaded ? "show-navigation" : "hide-navigation";
        },
        imgHeader() {
            return "/static/" + require("../assets/images/ecomania_blue.png")
        },
        img1() {
            return "/static/" + require("../assets/images/spielbrett.jpg")
        },
        img2() {
            return "/static/" + require("../assets/images/haushalt.jpg")
        },
        img3() {
            return "/static/" + require("../assets/images/spieluhr.jpg")
        },
        img4() {
            return "/static/" + require("../assets/images/ipad.jpg")
        },
        img5() {
            return "/static/" + require("../assets/images/poysdorf.jpg")
        },
        img6() {
            return "/static/" + require("../assets/images/wu.jpg")
        },
        headerText() {
            return (page, index) => {
                return this.isLoaded ? this.$store.getters['websiteBoard/headerText'](page)[index - 1].text : "";
            }
        },
        contentText() {
            return (page, index) => {
                return this.isLoaded ? this.$store.getters['websiteBoard/contentText'](page)[index - 1].text : "";
            }
        },
        footerText() {
            return (page, index) => {
                return this.isLoaded ? this.$store.getters['websiteBoard/footerText'](page)[index - 1].text : "";
            }
        },
        downloads() {
            return () => {
                return this.$store.state.websiteBoard.downloads
            }
        }

    },
    mounted() {
        this.accordion = new Foundation.Accordion($('#accordion'));
        this.menuHeader = new Foundation.Magellan($('#menu-header'));
        this.menuDropDown = new Foundation.DropdownMenu($('#menu-dropdown'));

        location.hash = this.$route.hash

        this.$i18n.locale = 'de'
        this.$store.dispatch('websiteBoard/loadWebsite');
        this.refresh();
    }
}
</script>

<style>
@import "../styles/landing-page.css";

.imageFullSize {
    width: 500px;
    height: 500px;
}

thumbnail {
    top: -50px;
    left: -35px;
    display: block;
    z-index: 999;
    cursor: pointer;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.4s;
    -webkit-transition-timing-function: ease;
}

.thumbnail:hover {
    transform: scale(2);
}

.alt-image-text {

}

</style>