<template>
  <ValidationForm v-if="item" v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
    <form novalidate @submit="handleSubmit($event,saveParameter)">

      <TopFormBar :info-text="message" :info-type="messageType"
                  :save-button="!isDisabled"
                  :title="$t('parameter.common-parameter')" class="eco-form-bar-second" style="width: 70%;"/>

      <div class="grid-container eco-container" style="width: 70%;">

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell">
              <h4>{{ $t('parameter.demographic-statistics') }}</h4>
            </div>
          </div>

          <div class="grid-x">
            <div class="medium-7 cell">
              <div class="grid-container eco-grid-container">
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('common.household-cnt') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.household_count" name="householdCount">
                      <NumberField v-model="item.common_parameter.household_count" :disabled="isDisabled"></NumberField>
                    </Field>
                    <ErrorMessage as="small" class="error" name="householdCount"></ErrorMessage>
                  </div>
                </div>
                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('parameter.adults-cnt') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.adult_count" name="adultCount">
                      <NumberField v-model="item.common_parameter.adult_count" :disabled="isDisabled"></NumberField>
                    </Field>
                    <ErrorMessage as="small" class="error" name="adultCount"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('parameter.children-cnt') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.children_count" name="childrenCount">
                      <NumberField v-model="item.common_parameter.children_count" :disabled="isDisabled"></NumberField>
                    </Field>
                    <ErrorMessage as="small" class="error" name="childrenCount"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('parameter.pensioner-cnt') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.pensioner_count" name="pensionerCount">
                      <NumberField v-model="item.common_parameter.pensioner_count" :disabled="isDisabled"></NumberField>
                    </Field>
                    <ErrorMessage as="small" class="error" name="pensionerCount"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('parameter.manpower-supply') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.manpower_supply" name="manpowerSupply">
                      <NumberField v-model="item.common_parameter.manpower_supply" :disabled="isDisabled"></NumberField>
                    </Field>
                    <ErrorMessage as="small" class="error" name="manpowerSupply"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-7 cell">
                    <label class="text-left middle">{{ $t('parameter.officials-minimum') }}(%)</label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.officials_minimum" :disabled="isDisabled" as="input"
                           name="officialsMinimum" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="officialsMinimum"></ErrorMessage>
                  </div>
                </div>
              </div>
            </div>

            <div class="medium-5 cell">
              <div class="grid-x">
                <div class="grid-container eco-grid-container">
                  <div class="medium-4 cell">
                    <h5>{{ $t('parameter.employment-rate') }}</h5>
                  </div>
                  <div class="grid-x">
                    <div class="medium-7 cell">
                      <label class="text-left middle">{{ $t('parameter.employment-rate-adults') }} (%) </label>
                    </div>
                    <div class="medium-3 cell">
                      <Field v-model="item.common_parameter.employment_rate_adults" :disabled="isDisabled" as="input"
                             name="employmentRateAdults" type="number"></Field>
                      <ErrorMessage as="small" class="error" name="emploemploymentRateAdults"></ErrorMessage>
                    </div>
                  </div>
                  <div class="grid-x">
                    <div class="medium-7 cell">
                      <label class="text-left middle">{{ $t('parameter.employment-rate-children') }} (%) </label>
                    </div>
                    <div class="medium-3 cell">
                      <Field v-model="item.common_parameter.employment_rate_children" :disabled="isDisabled" as="input"
                             name="employmentRateChildren" type="number"></Field>
                      <ErrorMessage as="small" class="error" name="employmentRateChildren"></ErrorMessage>
                    </div>
                  </div>
                  <div class="grid-x">
                    <div class="medium-7 cell">
                      <label class="text-left middle">{{ $t('parameter.employment-rate-pensioners') }} (%) </label>
                    </div>
                    <div class="medium-3 cell">
                      <Field v-model="item.common_parameter.employment_rate_pensioners" :disabled="isDisabled" as="input"
                             name="employmentRatePensioners" type="number"></Field>
                      <ErrorMessage as="small" class="error" name="employmentRatePensioners"></ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell">
              <h4>{{ $t('parameter.interest-rates') }}</h4>
            </div>
          </div>

          <div class="grid-x">
            <div class="medium-4 cell">
              <label class="text-left middle">{{ $t('parameter.deposit-interest-rate') }} (%) </label>
            </div>
            <div class="medium-3 cell">
              <Field v-model="item.common_parameter.deposit_interest_rate" :disabled="isDisabled" as="input"
                     name="depositInterestRate" type="number"></Field>
              <ErrorMessage as="small" class="error" name="depositInterestRate"></ErrorMessage>
            </div>
          </div>

          <div class="grid-x">
            <div class="medium-4 cell">
              <label class="text-left middle">{{ $t('parameter.loan-interest-rate') }} (%) </label>
            </div>
            <div class="medium-3 cell">
              <Field v-model="item.common_parameter.loan_interest_rate" :disabled="isDisabled" as="input"
                     name="loanInterestRate" type="number"></Field>
              <ErrorMessage as="small" class="error" name="loanInterestRate"></ErrorMessage>
            </div>
          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div class="grid-container eco-grid-container">
          <div class="grid-x">
            <div class="medium-6 cell">
              <h4>{{ $t('parameter.foreign-trade') }}</h4>
            </div>
          </div>
          <div class="grid-x">
            <div class="medium-3 cell">
              <label class="text-left middle">{{ $t('parameter.import-material-costs') }} (%) </label>
            </div>
            <div class="medium-3 cell">
              <Field v-model="item.common_parameter.import_material_costs" :disabled="isDisabled" as="input"
                     name="importMaterialCosts" type="number"></Field>
              <ErrorMessage as="small" class="error" name="importMaterialCosts"></ErrorMessage>
            </div>
          </div>

          <div class="grid-x">
            <div class="medium-3 cell">
              <label class="text-left middle">{{ $t('parameter.export-earnings') }} (%) </label>
            </div>
            <div class="medium-3 cell">
              <Field v-model="item.common_parameter.export_earnings" :disabled="isDisabled" as="input"
                     name="exportEarnings" type="number"></Field>
              <ErrorMessage as="small" class="error" name="exportEarnings"></ErrorMessage>
            </div>
          </div>

          <hr class="eco-horizontal-split-line"/>
          <div class="grid-x">
            <div class="cell medium-6">
              <div class="grid-container eco-grid-inner-container">
                <h5>{{ $t('parameter.import-indicators') }}</h5>
                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.food') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.import_indicator_food" :disabled="isDisabled" as="input"
                           name="importIndicatorFood" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="importIndicatorFood"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.clothes') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.import_indicator_clothes" :disabled="isDisabled" as="input"
                           name="importIndicatorClothes" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="importIndicatorClothes"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.healthy') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.import_indicator_healthy" :disabled="isDisabled" as="input"
                           name="importIndicatorHealthy" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="importIndicatorHealthy"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.education') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.import_indicator_education" :disabled="isDisabled" as="input"
                           name="importIndicatorEducation" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="importIndicatorEducation"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.communication') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.import_indicator_communication" :disabled="isDisabled" as="input"
                           name="importIndicatorCommunication"
                           type="number"></Field>
                    <ErrorMessage as="small" class="error" name="importIndicatorCommunication"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.leisure') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.import_indicator_leisure" :disabled="isDisabled" as="input"
                           name="importIndicatorLeisure" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="importIndicatorLeisure"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.automobile') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.import_indicator_automobile" :disabled="isDisabled" as="input"
                           name="importIndicatorAutomobile" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="importIndicatorAutomobile"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.living') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.import_indicator_living" :disabled="isDisabled" as="input"
                           name="importIndicatorLiving" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="importIndicatorLiving"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.automobile-costs') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.import_indicator_automobile_costs" :disabled="isDisabled" as="input"
                           name="importIndicatorAutomobileCosts"
                           type="number"></Field>
                    <ErrorMessage as="small" class="error" name="importIndicatorAutomobileCosts"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.living-costs') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.import_indicator_living_costs" :disabled="isDisabled" as="input"
                           name="importIndicatorLivingCosts"
                           type="number"></Field>
                    <ErrorMessage as="small" class="error" name="importIndicatorLivingCosts"></ErrorMessage>
                  </div>
                </div>

              </div>
            </div>

            <div class="cell medium-6">
              <div class="grid-container eco-grid-inner-container">
                <h5>{{ $t('parameter.export-indicators') }}</h5>
                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.food') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.export_indicator_food" :disabled="isDisabled" as="input"
                           name="exportIndicatorFood" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="exportIndicatorFood"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.clothes') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.export_indicator_clothes" :disabled="isDisabled" as="input"
                           name="exportIndicatorClothes" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="exportIndicatorClothes"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.healthy') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.export_indicator_healthy" :disabled="isDisabled" as="input"
                           name="exportIndicatorHealthy" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="exportIndicatorHealthy"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.education') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.export_indicator_education" :disabled="isDisabled" as="input"
                           name="exportIndicatorEducation" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="exportIndicatorEducation"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.communication') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.export_indicator_communication" :disabled="isDisabled" as="input"
                           name="exportIndicatorCommunication"
                           type="number"></Field>
                    <ErrorMessage as="small" class="error" name="exportIndicatorCommunication"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.leisure') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.export_indicator_leisure" :disabled="isDisabled" as="input"
                           name="exportIndicatorLeisure" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="exportIndicatorLeisure"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.automobile') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.export_indicator_automobile" :disabled="isDisabled" as="input"
                           name="exportIndicatorAutomobile" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="exportIndicatorAutomobile"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.living') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.export_indicator_living" :disabled="isDisabled" as="input"
                           name="exportIndicatorLiving" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="exportIndicatorLiving"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.automobile-costs') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.export_indicator_automobile_costs" :disabled="isDisabled" as="input"
                           name="exportIndicatorAutomobileCosts"
                           type="number"></Field>
                    <ErrorMessage as="small" class="error" name="exportIndicatorAutomobileCosts"></ErrorMessage>
                  </div>
                </div>

                <div class="grid-x">
                  <div class="medium-5 cell">
                    <label class="text-left middle">{{ $t('category.living-costs') }} </label>
                  </div>
                  <div class="medium-4 cell">
                    <Field v-model="item.common_parameter.export_indicator_living_costs" :disabled="isDisabled" as="input"
                           name="exportIndicatorLivingCosts" type="number"></Field>
                    <ErrorMessage as="small" class="error" name="exportIndicatorLivingCosts"></ErrorMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="eco-horizontal-split-line"/>
        </div>

        <div class="grid-container eco-grid-container">
          <h4>{{ $t('parameter.extraordinary-items') }}</h4>
          <div class="grid-x">
            <div class="medium-4 cell">
              <label class="text-left middle">{{ $t('parameter.special-amount-household') }} </label>
            </div>
            <div class="medium-3 cell">
              <Field v-model="item.common_parameter.special_amount_household" name="specialAmountHousehold">
                <NumberField v-model="item.common_parameter.special_amount_household"
                             :disabled="isDisabled"></NumberField>
              </Field>
              <ErrorMessage as="small" class="error" name="specialAmountHousehold"></ErrorMessage>
            </div>
          </div>
          <div class="grid-x">
            <div class="medium-4 cell">
              <label class="text-left middle">{{ $t('parameter.special-amount-economy') }} </label>
            </div>
            <div class="medium-3 cell">
              <Field v-model="item.common_parameter.special_amount_economy" name="specialAmountEconomy">
                <NumberField v-model="item.common_parameter.special_amount_economy"
                             :disabled="isDisabled"></NumberField>
              </Field>
              <ErrorMessage as="small" class="error" name="specialAmountEconomy"></ErrorMessage>
            </div>
          </div>
          <div class="grid-x">
            <div class="medium-4 cell">
              <label class="text-left middle">{{ $t('parameter.special-amount-government') }} </label>
            </div>
            <div class="medium-3 cell">
              <Field v-model="item.common_parameter.special_amount_government" name="specialAmountGovernment">
                <NumberField v-model="item.common_parameter.special_amount_government"
                             :disabled="isDisabled"></NumberField>
              </Field>
              <ErrorMessage as="small" class="error" name="specialAmountGovernment"></ErrorMessage>
            </div>
          </div>
        </div>

      </div>
    </form>
  </ValidationForm>
</template>

<script>
/*global url*/
import {ErrorMessage, Field, Form as ValidationForm} from 'vee-validate';
import axios from "axios";
import TopFormBar from "../../TopFormBar";
import NumberField from "../../NumberField";

export default {
  name: "CommonParameterForm",
  components: {
    ValidationForm,
    Field,
    ErrorMessage,
    TopFormBar,
    NumberField
  },
  props: {
    disabled: {
      default: true,
      type: Boolean
    },
  },
  data() {
    const schema = {
      householdCount: 'required',

      adultCount: 'required',
      employmentRateAdults: 'required',

      childrenCount: 'required',
      employmentRateChildren: 'required',

      pensionerCount: 'required',
      employmentRatePensioners: 'required',

      manpowerSupply: 'required',
      officialsMinimum: 'required',

      depositInterestRate: 'required',
      loanInterestRate: 'required',

      exportEarnings: 'required',
      importMaterialCosts: 'required',

      specialAmountHousehold: 'required',
      specialAmountEconomy: 'required',
      specialAmountGovernment: 'required',

      importIndicatorFood: 'required',
      exportIndicatorFood: 'required',

      importIndicatorClothes: 'required',
      exportIndicatorClothes: 'required',

      importIndicatorHealthy: 'required',
      exportIndicatorHealthy: 'required',

      importIndicatorEducation: 'required',
      exportIndicatorEducation: 'required',

      importIndicatorCommunication: 'required',
      exportIndicatorCommunication: 'required',

      importIndicatorLeisure: 'required',
      exportIndicatorLeisure: 'required',

      importIndicatorAutomobile: 'required',
      exportIndicatorAutomobile: 'required',

      importIndicatorLiving: 'required',
      exportIndicatorLiving: 'required',

      importIndicatorAutomobileCosts: 'required',
      exportIndicatorAutomobileCosts: 'required',

      importIndicatorLivingCosts: 'required',
      exportIndicatorLivingCosts: 'required',


    };
    return {
      schema,
      item: undefined,
      message: "",
      messageType: undefined,
    };
  },
  computed: {
    isDisabled() {
      return this.disabled ? true : false;
    }
  },
  methods: {
    saveParameter() {
      this.$store.commit('commonParameter/replaceCommonParameter', this.item);
      axios.post(url, {
        'action': 'save-common-parameter',
        'common-parameter': this.$store.state.commonParameter.commonParameter,
      })
          .then(() => {
            if (url.includes("admin-board")) {
              this.$router.push(url + '/parameter-set/edit');
            } else {
              this.$router.push(url + '/game/edit');
            }
            this.showMessage(this.$t('messages.save-successful'), this.$MSG_TYPE_SUCCESS);
          })
          .catch((error) => alert(error.response.data.message));
    },
    showMessage(msg, type = 0) {
      this.message = msg;
      this.messageType = type;
      setTimeout(() => {
        this.message = "";
      }, 2000);
    }
  },
  mounted() {
    this.unwatch = this.$store.watch(
        (state) => {
          return state.commonParameter.commonParameter;
        },
        () => {
          this.item = Object.assign({}, this.$store.state.commonParameter.commonParameter);
        }
    );
    if (this.$store.state.commonParameter.commonParameter) {
      this.item = Object.assign({}, this.$store.state.commonParameter.commonParameter);
    }
  },
  unmounted() {
    this.unwatch();
  }
}
</script>

<style scoped>


</style>