<template>
  <div class="home">
    <!--div id="orbit" class="orbit" role="region" aria-label="Favorite Space Pictures" data-orbit>
      <div class="orbit-wrapper">
        <div class="orbit-controls">
          <button class="orbit-previous"><span class="show-for-sr">Previous Slide</span>&#9664;&#xFE0E;</button>
          <button class="orbit-next"><span class="show-for-sr">Next Slide</span>&#9654;&#xFE0E;</button>
        </div>
        <ul class="orbit-container">
          <li class="is-active orbit-slide">
            <figure class="orbit-figure">
              <img class="orbit-image" src="https://via.placeholder.com/2000x750" alt="Pic-1">
              <figcaption class="orbit-caption">ECOMANIA - picture one.</figcaption>
            </figure>
          </li>
          <li class="orbit-slide">
            <figure class="orbit-figure">
              <img class="orbit-image" src="https://via.placeholder.com/2000x750" alt="Pic-2">
              <figcaption class="orbit-caption">ECOMANIA - picture two.</figcaption>
            </figure>
          </li>
          <li class="orbit-slide">
            <figure class="orbit-figure">
              <img class="orbit-image" src="https://via.placeholder.com/2000x750" alt="Pic-3">
              <figcaption class="orbit-caption">ECOMANIA - picture three.</figcaption>
            </figure>
          </li>
        </ul>
      </div>
      <nav class="orbit-bullets">
        <button class="is-active" data-slide="0">
          <span class="show-for-sr">First slide details.</span>
          <span class="show-for-sr" data-slide-active-label>Current Slide</span>
        </button>
        <button data-slide="1"><span class="show-for-sr">Second slide details.</span></button>
        <button data-slide="2"><span class="show-for-sr">Third slide details.</span></button>
      </nav>
    </div>
    <hr-->
    <Login/>
  </div>
</template>

<script>
import Login from '@/components/Login.vue';
//import $ from 'jquery';
//import Foundation from 'foundation-sites';

export default {
  name: 'Home',
  components: {
    Login
  },
  /*
  mounted() {
    this.orbit = new Foundation.Orbit($('#orbit'));
  },
  unmounted() {
    this.orbit.destroy();
  }*/
};
</script>
